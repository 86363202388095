import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import UndoIcon from '@material-ui/icons/Undo';
import SaveIcon from '@material-ui/icons/Save';
import * as actions from '../../../../store/actions/index';
import { empty } from '../../../../shared/utility';

const ActionButtons = (props) => {
  const [state, setState] = useState({
    touched: false,
    name: !empty(props.data.name) ? props.data.name : '',
    fromDate: typeof props.data.fromDate == 'object' ? props.data.fromDate : null,
    fromTime: null,
    toDate: typeof props.data.toDate == 'object' ? props.data.toDate : null,
    toTime: null,
    isActive: !empty(props.isActive) ? props.data.isActive : true,
  });
  const dispatch = useDispatch();
  const onSetState = (data) => dispatch(actions.setPromotionState(data));
  console.log('Action buttons rendering!');
  return (
    <Grid item xs={12}>
      <ButtonGroup size="medium" aria-label="small outlined button group" orientation="horizontal" fullWidth={false}>
        <Button variant="contained" color="secondary"  startIcon={<UndoIcon />} onClick={() => onSetState({isFormVisible: false})}>Regresar</Button>
        {/* {
          state.touched
            ? <Button variant="outlined" color="secondary" startIcon={<DeleteIcon />} onClick={() => discardChanges()}>Descartar cambios</Button>
            : null
        } */}
        <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={() => props.onSaveHandler(state)}>Guardar</Button> 
      </ButtonGroup>
    </Grid>
  )
}

export default ActionButtons;
