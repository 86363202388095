import React from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import * as actions from '../../../../store/actions/index';
import { empty } from '../../../../shared/utility';
import config from '../../../../config';

const General = (props) => {
  const ecoName = useSelector(state => state.setting.settings.ecoName);
  const ecoSubdomain = useSelector(state => state.setting.settings.ecoSubdomain);
  const ownSubdomain = useSelector(state => state.setting.settings.ownSubdomain);
  const ownDomain = useSelector(state => state.setting.settings.ownDomain);
  const isNewEco = useSelector(state => state.setting.settings.isNewEco);
  const ownSubdomainCopy = useSelector(state => state.setting.ownSubdomainCopy);
  const ownDomainCopy = useSelector(state => state.setting.ownDomainCopy);
  const dispatch = useDispatch();
  const onSetSettings = (settings) => dispatch(actions.setSettings(settings));
  const inputChangeHandler = (event) => {
    onSetSettings({ [event.target.name]: event.target.value });
  };
  console.log('General rendering!');
  return (
    <React.Fragment>
      <Typography variant="h5" className={props.materialClasses.sectionMarginBottom}>
        Generales
      </Typography>
      <Grid item container xs={12} md={12} spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Paper className={props.materialClasses.paper} style={{ paddingTop: 0 }}>
            <Grid item container spacing={2}>
              <Grid item xs={6} md={3}>
                <TextField
                  required
                  fullWidth
                  // autoFocus
                  name="ecoName"
                  label="Nombre de la tienda"
                  margin="normal"
                  value={ecoName}
                  onChange={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  required
                  disabled={isNewEco ? false : true}
                  fullWidth
                  name="ecoSubdomain"
                  label="Subdominio"
                  margin="normal"
                  helperText="Una vez guardado este nombre, no podrá modificarlo directamente."
                  value={ecoSubdomain}
                  onChange={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  disabled
                  fullWidth
                  name="url"
                  label="URL de acceso"
                  margin="normal"
                  helperText={"Si desea cambiar su subdominio, envíe una solicitud de cambio al correo " + config.ecoSupportEmail}
                  value={ecoSubdomain + '.decimaeco.com'}
                  // onChange={inputChangeHandler}
                />
              </Grid> 
              <Grid item xs={6} md={3}>
                <TextField
                  disabled={empty(ownSubdomainCopy) ? false : true}
                  fullWidth
                  name="ownSubdomain"
                  label="Subdominio propio"
                  margin="normal"
                  helperText="El subdominio de www.amazon.com es: www"
                  value={ownSubdomain}
                  onChange={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  disabled={empty(ownDomainCopy) ? false : true}
                  fullWidth
                  name="ownDomain"
                  label="Dominio propio"
                  margin="normal"
                  helperText="El dominio de www.amazon.com es: amazon.com"
                  value={ownDomain}
                  onChange={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled
                  fullWidth
                  name="secondaryUrl"
                  label="URL de acceso"
                  margin="normal"
                  helperText={"Si usted no tiene dominio propio, y desea que le gestionemos la compra de un dominio, envíe una solicitud de cotización de dominio al correo " +  config.ecoSupportEmail + ". Y si ya tiene dominio, envíe un correo de solicitud de parámetros de configuración de DNS para que su dominio pueda apuntar a nuestros servidores."}
                  value={ownSubdomain + '.' + ownDomain}
                  // onChange={inputChangeHandler}
                />
              </Grid> 
            </Grid> 
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default General;
