import React from 'react';
import { useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { empty, ccyFormat } from '../../../shared/utility';

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft:0
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    // [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    //   marginTop: theme.spacing(6),
    //   marginBottom: theme.spacing(6),
    //   padding: theme.spacing(3),
    // },
  },
  subtitles: {
    marginTop: '10px'
  },
  cellDescription: {
    borderBottom: 0
  }
}));

const DetailPanel = (props) => {
  const materialClasses = useStyles();
  const settings = useSelector(state => state.layout.settings)
  const currencySymbol = '$';
  return (
    <Container maxWidth="md" className={materialClasses.container}>
        <Paper className={materialClasses.paper}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5" className={materialClasses.subtitles}>
                Dirección de envío
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                disabled={true}
                name="firstname"
                label="Nombre"
                type="text"
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                value={ props.data.address.firstname }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                disabled={true}
                name="lastname"
                label="Apellido"
                type="text"
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                value={ props.data.address.lastname }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                disabled={true}
                name="phoneNumber"
                label="Número de teléfono"
                type="text"
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                value={ props.data.client.phone_number }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl
                disabled={true} 
                fullWidth 
              >
                <InputLabel id="type-label">Tipo</InputLabel>
                <Select
                  labelId="type-label"
                  name="type"
                  value={ props.data.address.address_type }
                  style={{color: 'rgba(0, 0, 0, 0.87)'}}
                >
                  <MenuItem value='R'>Residencia</MenuItem>
                  <MenuItem value='E'>Empresa</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={true}
                name="address"
                label="Dirección"
                type="text"
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                value={ props.data.address.address }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                disabled={true}
                name="area" 
                label="Zona" 
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                value={ props.data.address.area_name }
                fullWidth 
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                disabled={true}
                name="city"
                label="Municipio"
                type="text"
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                value={ props.data.address.city_name }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField 
                disabled={true}
                name="state" 
                label="Departamento / Provincia"
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                value={ props.data.address.state_name }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={true}
                id="reference"
                name="reference"
                label="Punto de referencia"
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                value={ props.data.address.reference_point }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" className={materialClasses.subtitles}>
                Datos de facturación
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                disabled={true}
                name="clientName"
                label="Facturar a nombre de"
                type="text"
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                value={ props.data.client.name }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                disabled={true}
                name="documentType"
                label="Tipo de documento"
                type="text"
                value={ props.data.document_type_label }
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                disabled={true}
                name="paymentForm"
                label="Forma de pago"
                type="text"
                value={ props.data.payment_form_label }
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                fullWidth
              />
            </Grid>
            
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                disabled={true}
                name="commercialTrade"
                label="Giro"
                type="text"
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                value={ props.data.client.commercial_trade }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                disabled={true}
                name="singleIdentityDocumentNumber"
                label="DUI"
                type="text"
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                value={ props.data.client.single_identity_document_number }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                disabled={true}
                name="registrationNumber"
                label="NRC"
                type="text"
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                value={ props.data.client.registration_number }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextField
                disabled={true}
                name="taxId"
                label="NIT"
                type="text"
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                value={ props.data.client.tax_id }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={true}
                name="clientAddress"
                label="Dirección"
                type="text"
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                value={ props.data.client.street1 }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled={true}
                name="clientCity"
                label="Municipio"
                type="text"
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                value={ props.data.client.city_name }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                disabled={true}
                id="clientState" 
                name="clientState" 
                label="Departamento"
                inputProps={{ style: { color: 'rgba(0, 0, 0, 0.87)' } }}
                value={ props.data.client.state_name }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component="legend" className="MuiFormLabel-root">
                Observaciones
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                rows={2}
                disabled={true}
                name="remark"
                style={{height:"100%", width:"100%", color: 'rgba(0, 0, 0, 0.87)'}}
                placeholder="Observaciones de su pedido"
                value={ !empty(props.data.remark) ? props.data.remark : '' }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" className={materialClasses.subtitles}>
                Detalle del pedido
              </Typography>
            </Grid>
            <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="spanning table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Descripción</TableCell>
                    <TableCell align="center">Cantidad</TableCell>
                    <TableCell align="right">Precio</TableCell>
                    <TableCell align="right">Ventas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.details.map((row) => (
                    <React.Fragment key={row.id + 'fragment'}>
                      <TableRow key={row.id + 'article'}>
                        <TableCell colSpan={4} classes={{root: materialClasses.cellDescription}}>
                          { !empty(row.variant) ? ( row.name + ' - ' + row.variant ) : row.name } 
                        </TableCell>
                      </TableRow>
                      <TableRow key={row.id + 'description'}>
                        <TableCell>
                          <img src={ !empty(row.image_url) ? row.image_url : row.parent_image_url  } style={{ height: 'auto', width: '100%', maxWidth: 80 }}/>
                        </TableCell>
                        <TableCell>
                          <TextField
                            disabled={true}
                            value={row.quantity}
                            type="number"
                            style={{width: '5ch'}}
                          />
                        </TableCell>
                        <TableCell align="right">{ ccyFormat(row.price) }</TableCell>
                        <TableCell align="right">{ ccyFormat(row.amount) }</TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                  <TableRow>
                    <TableCell rowSpan={3} />
                    <TableCell colSpan={2} align="right">Subtotal {currencySymbol}</TableCell>
                    <TableCell align="right">{ccyFormat(props.data.subtotal)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} align="right">Envío y manejo {currencySymbol}</TableCell>
                    <TableCell align="right">{ccyFormat(props.data.shipping_cost)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} align="right">Total {currencySymbol}</TableCell>
                    <TableCell align="right">{ccyFormat(props.data.total_amount_due)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      </Container>
  )
}

export default DetailPanel;
