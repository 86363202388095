import * as actionTypes from './actionTypes';
import axios from '../../axios-firebase';
import { empty, fireToast, fireMessage } from '../../shared/utility';
import config from '../../config';

export const releaseSettings = () => {
	return {
		type: actionTypes.RELEASE_SETTINGS
	};
};

export const setSettings = (settings) => {
	return {
		type: actionTypes.SET_SETTINGS,
		settings: settings
	};
};

export const settingStart = () => {
	return {
		type: actionTypes.SETTING_START
	};
};


export const settingFail = (error) => {
	return {
		type: actionTypes.SETTING_FAIL,
		error: error
	};
};

export const saveSettingsSuccess = () => {
	return {
		type: actionTypes.SAVE_SETTINGS_SUCCESS
	};
};

export const saveSetting = ( settings, token ) => {
	return dispatch => {
		dispatch( settingStart() );
		if(!empty(settings.ownDomain))
		{
			axios.put( '/eco/' + settings.ownDomain.replace(new RegExp('\\.', 'g'), ',') + '/settings.json?auth=' + token, settings );	
		}
		axios.put( '/eco/' + settings.ecoSubdomain + '/settings.json?auth=' + token, settings )
			.then( response => {
				fireToast('Las configuraciones se guardaron exitosamente.');
				dispatch( saveSettingsSuccess() );
			} )
			.catch( error => {
				fireMessage({
					text: 'No se pudo completar la operación, el token de seguridad ha expirado, por favor inicie sesión nuevamente'
				});
				dispatch( settingFail( error ) );
			} );
	};
};

export const fetchSettingsSuccess = (settings, ecoSubdomains) => {
	return {
		type: actionTypes.FETCH_SETTINGS_SUCCESS,
		settings: settings,
		ecoSubdomains: ecoSubdomains
	};
};

export const fetchSettings = (organizationId) => {
	return dispatch => {
		dispatch(settingStart());
		axios.get( '/eco.json' )
			.then( response => {
				// console.log(response);
				let settings = {};
				const ecoSubdomains = [];
				for ( let key in response.data )
				{
					// console.log(key);
					// console.log(response.data[key]['settings']);
					// console.log(response.data[key]['settings']['organizationId']);
											// fetchedOrders.push( {
						// 		...res.data[key],
						// 		id: key
						// } );

					ecoSubdomains.push(response.data[key]['settings']['ecoSubdomain']);
					
					if(!empty(response.data[key]['settings']['organizationId']) && parseInt(organizationId) === parseInt(response.data[key]['settings']['organizationId']))
					{
						settings = response.data[key]['settings'];
					}
				}

				dispatch( fetchSettingsSuccess( settings, ecoSubdomains ) );
			} )
			.catch( error => {
				console.log(error);
					dispatch( settingFail() );
			} );
	};
};