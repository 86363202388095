import React from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import ChipInput from 'material-ui-chip-input'
import MaterialTable from "material-table";
import * as actions from '../../../../store/actions/index';
import { empty} from '../../../../shared/utility';

const Variants = (props) => {
  const id = useSelector(state => state.article.data.id);
  const internalReference = useSelector(state => state.article.data.internalReference);
  const name = useSelector(state => state.article.data.name);
  const retailPrice = useSelector(state => state.article.data.retailPrice);
  const cost = useSelector(state => state.article.data.cost);
  const newVariants = useSelector(state => state.article.newVariants);
  const existingVariants = useSelector(state => state.article.existingVariants);
  const dispatch = useDispatch();
  const onSetNewVariants = (data) => dispatch(actions.setNewVariants(data));

  const chipChangeHandler = (chipName, chips, internalReference, name, retailPrice, cost) => {
    switch (chipName) {
      case 'sizes':
        createVariants(chips, newVariants.colors, newVariants.materials, newVariants.styles, internalReference, name, retailPrice, cost);
        break;
      case 'colors':
        createVariants(newVariants.sizes, chips, newVariants.materials, newVariants.styles, internalReference, name, retailPrice, cost);
        break;
      case 'materials':
        createVariants(newVariants.sizes, newVariants.colors, chips, newVariants.styles, internalReference, name, retailPrice, cost);
        break;
      case 'styles':
        createVariants(newVariants.sizes, newVariants.colors, newVariants.materials, chips, internalReference, name, retailPrice, cost);
        break;
      default:
        break;
    }
  };

  const createVariants = (sizes, colors, materials, styles, internalReference, name, retailPrice, cost) => {
    let variants = [];
    let levels = [];
    let count = 0;
    let options = [sizes, colors, materials, styles];

    options.forEach(option => {
      if(!empty(option))
      {
        levels.push(option);
        count++;
      }
    });

    for (count; count < 4; count++)
    {
      levels.push([]);
    }

    createVariantsAux(variants, levels[0], levels[1], levels[2], levels[3], internalReference, name, retailPrice, cost);

    //Revisar puede dar problema
    onSetNewVariants({
      sizes: sizes,
      colors: colors,
      materials: materials,
      styles: styles,
      variants: variants
    });
  };

  const createVariantsAux = (variants, level1, level2 = [], level3 = [], level4 = [], internalReference, name, retailPrice, cost) => {
    let level1Variant = '';
    let level2Variant = '';
    let level3Variant = '';
    let level4Variant = '';
    let created;
    let count = existingVariants.length;

    level1.map(size => {
      level1Variant = size;
      created = false;

      level2.map(color => {
        level2Variant = level1Variant + ' / ' + color;
        created = false;

        level3.map(material => {
          level3Variant = level2Variant + ' / ' + material;
          created = false;
          
          level4.map(style => {
            level4Variant = level3Variant + ' / ' + style;
            created = true;
            count++;
            createVariantsAux2(variants, level4Variant, count, internalReference, name, retailPrice, cost);
            return created;
          });

          if(!created)
          {
            created = true;
            count++;
            createVariantsAux2(variants, level3Variant, count, internalReference, name, retailPrice, cost);
          }

          return created;
        });

        if(!created)
        {
          created = true;
          count++;
          createVariantsAux2(variants, level2Variant, count, internalReference, name, retailPrice, cost);
        }

        return created;
      });

      if(!created)
      {
        created = true;
        count++;
        createVariantsAux2(variants, level1Variant, count, internalReference, name, retailPrice, cost);
      }

      return created;
    });
  }

  const createVariantsAux2 = (variants, variant, count, internalReference, name, retailPrice, cost) => {
    variants.push({
      variant: variant, 
      internal_reference: internalReference + 'V' + count, 
      name: name, 
      retail_price:retailPrice, 
      available_quantity: 0, 
      cost: cost, 
      is_discount_applicable: true, 
      is_visible_in_store: true
    });
  }

  console.log('Variant rendering!');

  let variants = (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography component="h5" variant="h5">
          {empty(id)?'Variantes':'Nuevas variantes'}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Paper className={props.materialClasses.paper}>
          <ChipInput
            className={[props.materialClasses.mgChip].join(' ')}
            fullWidth
            label='Tallas / Tamaños'
            placeholder='Escriba y presione enter'
            defaultValue={newVariants.sizes}
            onChange={(chips) => chipChangeHandler('sizes', chips, internalReference, name, retailPrice, cost)}
          />
          <ChipInput
            className={[props.materialClasses.mgChip].join(' ')}
            fullWidth
            label='Colores'
            placeholder='Escriba y presione enter'
            defaultValue={newVariants.colors}
            onChange={(chips) => chipChangeHandler('colors', chips, internalReference, name, retailPrice, cost)}
          />
          <ChipInput
            className={[props.materialClasses.mgChip].join(' ')}
            fullWidth
            label='Materiales'
            placeholder='Escriba y presione enter'
            defaultValue={newVariants.materials}
            onChange={(chips) => chipChangeHandler('materials', chips, internalReference, name, retailPrice, cost)}
          />
          <ChipInput
            className={[props.materialClasses.mgChip].join(' ')}
            fullWidth
            label='Estilos / Otros'
            placeholder='Escriba y presione enter'
            defaultValue={newVariants.styles}
            onChange={(chips) => chipChangeHandler('styles', chips, internalReference, name, retailPrice, cost)}
          />
        </Paper>
      </Grid>
      <Grid item xs={8}>
        <MaterialTable
          // title="Render Image Preview"
          options={{
            toolbar:false,
            paging: false,
            headerStyle: {
              fontWeight:'bold',
              textAlign:'center',
              padding:'0.5rem'
            }
          }}
          columns={[
            { title: 'Variante', field: 'variant', editable: 'never' },
            { title: 'Código', field: 'internal_reference' },
            { title: 'Nombre', field: 'name', hidden: true },
            { title: 'Precio', field: 'retail_price', type: 'numeric' },
            { title: 'Existencia inicial', field: 'available_quantity', type: 'numeric' },
            { title: 'Costo', field: 'cost', type: 'numeric' },
            { title: 'Aplica descuento', field: 'is_discount_applicable', type: 'boolean'},
            { title: 'Visible', field: 'is_visible_in_store', type: 'boolean'},
          ]}
          data={newVariants.variants}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data = [...newVariants.variants];
                const index = data.indexOf(oldData);
                data[index] = newData;
                onSetNewVariants({
                  variants: data
                });
                resolve();
              })
          }}
        />
      </Grid>
    </React.Fragment>
  );  

  // if(empty(internalReference) || empty(name) || empty(retailPrice) || empty(cost))
  if(empty(internalReference) || empty(name) || empty(retailPrice))
  {
    variants = (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography component="h5" variant="h5">
            Variantes
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <Alert severity="info">
          <AlertTitle>Complete código, nombre, precio del artículo para poder ingresar variantes.</AlertTitle>
        </Alert>
        </Grid>
      </React.Fragment>
    );
  }

  return variants;
}

export default Variants;
