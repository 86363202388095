import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './resources/index.css';
import App from './resources/App';
import * as serviceWorker from './resources/serviceWorker';
import layoutReducer from './resources/store/reducers/layout';
import authReducer from './resources/store/reducers/auth';
import articleReducer from './resources/store/reducers/crudeArticle';
import couponReducer from './resources/store/reducers/coupon';
import promotionReducer from './resources/store/reducers/promotion';
import catalogReducer from './resources/store/reducers/catalog';
import orderReducer from './resources/store/reducers/order';
import settingReducer from './resources/store/reducers/setting';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DNS,
	integrations: [
		new Integrations.BrowserTracing(),
	],
	environment: process.env.NODE_ENV,
	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
	normalizeDepth: 10,
});

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const rootReducer = combineReducers({
    layout: layoutReducer,
    auth: authReducer,
    article: articleReducer,
    coupon: couponReducer,
    promotion: promotionReducer,
    catalog: catalogReducer,
    order: orderReducer,
    setting: settingReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render( app, document.getElementById( 'root' ) );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
