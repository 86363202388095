import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ActionButtons from './Form/ActionButtons';
import ColorPicker from './Form/ColorPicker';
import DocumentTypes from './Form/DocumentTypes';
import Footer from './Form/Footer';
import General from './Form/General';
import Inventory from './Form/Inventory';
import ImageUploader from './ImageUploader/ImageUploader';
import Media from './Form/Media';
import PaymentForms from './Form/PaymentForms';
import PremiumPaymentForms from './Form/PremiumPaymentForms';
import Shipping from './Form/Shipping';
import Spinner from '../../../components/UI/Spinner/Spinner';
import useHttpErrorHandler from '../../../hooks/http-error-handler';
import axios from '../../../axios-decima';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  loader: {
    marginTop: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  standardMarginTop: {
    marginTop: 20,
  },
  standardMarginBottom: {
    marginBottom: 20,
  },
  sectionMarginBottom: {
    marginBottom: 10,
  },
}));

const Setting = (props) => {
  const materialClasses = useStyles();
  const [uploading, setUploading] = useState(false);
  const [error, clearError] = useHttpErrorHandler(axios);
  const loading = useSelector(state => state.setting.loading);
  
  const form = (
    <React.Fragment>
      <Grid item container xs={12}>
        <General materialClasses={materialClasses}/>
      </Grid>
      <Grid item container xs={12}>
        <Inventory materialClasses={materialClasses}/>
      </Grid>
      <Grid item container xs={12}>
        <ColorPicker materialClasses={materialClasses}/>
      </Grid>
      <Grid item container xs={12}>
        <Media materialClasses={materialClasses} setUploading={setUploading}/>
      </Grid>
      <Grid item container xs={12}>
        <DocumentTypes materialClasses={materialClasses}/>
      </Grid>
      <Grid item container xs={12}>
        <Shipping materialClasses={materialClasses}/>
      </Grid>
      <Grid item container xs={12}>
        <PaymentForms materialClasses={materialClasses}/>
      </Grid>
      <Grid item container xs={12}>
        <PremiumPaymentForms materialClasses={materialClasses}/>
      </Grid>
      <Grid item container xs={12}>
        <Footer materialClasses={materialClasses}/>
      </Grid>
    </React.Fragment>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ActionButtons />
      </Grid>
      {
        (!loading  && !uploading)
          ? form
          : null
      }
      {
        (uploading && !loading)
          ? <ImageUploader setUploading={setUploading} />
          : null
      }
      {
        (loading)
          ? <Grid item xs={12}>
              <div className={materialClasses.loader}>
                <Spinner/>
              </div>
            </Grid>
          : null
      }
    </Grid>
  )
}

export default Setting;
