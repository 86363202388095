import * as actionTypes from './actionTypes';
import { releaseGroups, releaseBrands } from './crudeArticle';
import { releaseSettings } from './setting';
import axios from '../../axios-decima';
import faxios from 'axios';
import { empty, fireToast, fireMessage } from '../../shared/utility';
import config from '../../config';

export const authStart = () => {
	return {
		type: actionTypes.AUTH_START
	};
};

export const authSuccess = (token, organizationId, name, email, currencySymbol, costPricePrecision) => {
	return {
		type: actionTypes.AUTH_SUCCESS,
		idToken: token,
		organizationId: organizationId,
		// userId: userId,
		name: name,
		email: email,
		currencySymbol: currencySymbol,
		costPricePrecision: costPricePrecision
	};
};

export const authFail = (error) => {
	return {
		type: actionTypes.AUTH_FAIL,
		error: error
	};
};

export const logout = () => {
	localStorage.removeItem('token');
	localStorage.removeItem('firebaseToken');
	localStorage.removeItem('organizationId');
	localStorage.removeItem('expirationDate');
	// localStorage.removeItem('userId');
	localStorage.removeItem('name');
	localStorage.removeItem('email');
	localStorage.removeItem('currencySymbol');
	localStorage.removeItem('costPricePrecision');
	return {
			type: actionTypes.AUTH_LOGOUT
	};
};

export const checkAuthTimeout = (expirationTime) => {
	return dispatch => {
			setTimeout(() => {
					dispatch(logout());
					dispatch(releaseGroups());
					dispatch(releaseBrands());
					dispatch(releaseSettings());
			}, expirationTime * 1000);
	};
};

export const auth = (email, password, isSignup) => {
	return dispatch => {
		dispatch(authStart());
		const authData = {
			email: email,
			password: password,
			key:config.apiKey
		};
		// 	window.axios.defaults.headers.common = {
		// 		'X-Requested-With': 'XMLHttpRequest',
		// 		'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content');
		// };
		let url = '/api/core/signUpNewUser';
		if (!isSignup)
		{
			url = '/api/core/signInUser';
		}
		axios.post(url, authData)
			.then(response => {
				if(empty(response.data.message))
				{
					fireToast('Signed in successfully');
					const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
					localStorage.setItem('token', response.data.idToken);
					localStorage.setItem('organizationId', response.data.organizationId);
					localStorage.setItem('expirationDate', expirationDate);
					// localStorage.setItem('userId', response.data.localId);
					localStorage.setItem('name', response.data.name);
					localStorage.setItem('email', email);
					localStorage.setItem('currencySymbol', response.data.currencySymbol);
					localStorage.setItem('costPricePrecision', response.data.costPricePrecision);
					dispatch(releaseGroups());
					dispatch(releaseBrands());
					dispatch(releaseSettings());
					dispatch(firebaseAuth());
					dispatch(authSuccess(response.data.idToken, response.data.organizationId, response.data.name, email, response.data.currencySymbol, response.data.costPricePrecision));
					dispatch(checkAuthTimeout(response.data.expiresIn));
				}
				else
				{
					fireMessage({
						title: response.data.messageTitle,
						text: response.data.messageText
					});

					dispatch(authFail(response.data.messageTitle));
				}
			})
			.catch(err => {
				// console.log(err);
				dispatch(authFail(err));
			});
	};
};

export const authFirebaseSuccess = (token) => {
	return {
		type: actionTypes.AUTH_FIREBASE_SUCCESS,
		firebaseToken: token,
	};
};

export const firebaseAuth = () => {
	return dispatch => {
			dispatch(authStart());
			const authData = {
					email: process.env.REACT_APP_FIREBASE_EMAIL,
					password: process.env.REACT_APP_FIREBASE_PASSWORD,
					returnSecureToken: true
			};
			let url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + process.env.REACT_APP_FIREBASE_KEY;
			
			faxios.post(url, authData)
					.then(response => {
							// const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
							localStorage.setItem('firebaseToken', response.data.idToken);
							// localStorage.setItem('expirationDate', expirationDate);
							// localStorage.setItem('userId', response.data.localId);
							dispatch(authFirebaseSuccess(response.data.idToken));
							// dispatch(authSuccess(response.data.idToken, response.data.localId));
							// dispatch(checkAuthTimeout(response.data.expiresIn));
					})
					.catch(err => {
							// dispatch(authFail(err.response.data.error));
							// dispatch(authFail(err.response.data.error));
					});
	};
};

export const subscribeSuccess = () => {
	return {
		type: actionTypes.SUBSCRIBE_SUCCESS,
	};
};

export const subscribe = (data) => {
	return dispatch => {
		dispatch(authStart());
		axios.post('/api/custom/subscribe?key=' + config.apiKey, data)
			.then(response => {
				console.log(response);
				if(!empty(response.data))
				{
					if(!empty(response.data.success)) dispatch(subscribeSuccess());
					else
					{
						fireMessage({
							text: response.data.error
						});
						dispatch(authFail(response.data.error));
					}
				}
			})
			.catch(err => {
				// console.log(err);
				dispatch(authFail('error'));
			});
	};
};

export const setAuthRedirectPath = (path) => {
	return {
		type: actionTypes.SET_AUTH_REDIRECT_PATH,
		path: path
	};
};

export const authCheckState = () => {
	return dispatch => {
		const token = localStorage.getItem('token');
		if (!token)
		{
			dispatch(logout());
		} 
		else
		{
			const expirationDate = new Date(localStorage.getItem('expirationDate'));
			if (expirationDate <= new Date())
			{
				dispatch(logout());
			} 
			else
			{
				// const userId = localStorage.getItem('userId');
				const organizationId = localStorage.getItem('organizationId');
				const name = localStorage.getItem('name');
				const email = localStorage.getItem('email');
				const currencySymbol = localStorage.getItem('currencySymbol');
				const costPricePrecision = localStorage.getItem('costPricePrecision');
				const firebaseToken = localStorage.getItem('firebaseToken');
				dispatch(authSuccess(token, organizationId, name, email, currencySymbol, costPricePrecision));
				dispatch(authFirebaseSuccess(firebaseToken));
				dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
			}   
		}
	};
};