import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ShippingTable from './ShippingTable';
import Money from '../../../../components/UI/Input/Money';
import { useForm } from 'react-hook-form';
import * as actions from '../../../../store/actions/index';
import { updateObject, makeid } from '../../../../shared/utility';

const PremiumPaymentForms = (props) => {
  const shippingCostType = useSelector(state => state.setting.settings.shippingCostType);
  const shippingCostAssignedBy = useSelector(state => state.setting.settings.shippingCostAssignedBy);
  const fixedShippingCost = useSelector(state => state.setting.settings.fixedShippingCost);
  const areas = useSelector(state => state.setting.settings.areas);
  const cities = useSelector(state => state.setting.settings.cities);
  const states = useSelector(state => state.setting.settings.states);
  const dispatch = useDispatch();
  const onSetSettings = (settings) => dispatch(actions.setSettings(settings));
  const { register, errors, triggerValidation, setValue, getValues, control, handleSubmit } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  });
  const radioChangeHandler = (event) => {
    onSetSettings({ [event.target.name]: event.target.value });
  };
  const inputChangeHandler = (event) => {
    onSetSettings({ [event.target.name]: event.target.value });
  };
  const editDataHandler = (id, data) => {
    let updatedField, updatedData;

    if(shippingCostAssignedBy === 'Z')
    {
      updatedField = 'areas';
      updatedData = updateObject(areas, { [id] : data});
    }
    else if(shippingCostAssignedBy === 'M')
    {
      updatedField = 'cities';
      updatedData = updateObject(cities, { [id] : data});
    }
    else {
      updatedField = 'states';
      updatedData = updateObject(states, { [id] : data});
    }
    
    onSetSettings( { [updatedField]: updatedData });
  };
  const deleteDataHandler = (id) => {
    let updatedField, updatedData;

    if(shippingCostAssignedBy === 'Z')
    {
      updatedField = 'areas';
      updatedData = updateObject(areas, {});
    }
    else if(shippingCostAssignedBy === 'M')
    {
      updatedField = 'cities';
      updatedData = updateObject(cities, {});
    }
    else {
      updatedField = 'states';
      updatedData = updateObject(states, {});
    }
    
    delete updatedData[id];
    onSetSettings( { [updatedField]: updatedData });
  };
  //ojocurrency
  const onSubmit = data => {
    // console.log(data);
    let updatedField, updatedData;
    const id = makeid(10);
    const cost = parseFloat(data.cost.replace(new RegExp('\\$|,', 'g'), ''));

    if(shippingCostAssignedBy === 'Z')
    {
      updatedField = 'areas';
      updatedData = updateObject(areas, { [id] : {
        id: id,
        name: data.name,
        cost: cost
      }});
    }
    else if(shippingCostAssignedBy === 'M')
    {
      updatedField = 'cities';
      updatedData = updateObject(cities, { [id] : {
        id: id,
        name: data.name,
        cost: cost
      }});
    }
    else {
      updatedField = 'states';
      updatedData = updateObject(states, { [id] : {
        id: id,
        name: data.name,
        cost: cost
      }});
    }
    
    onSetSettings( { [updatedField]: updatedData });
    setValue([
      { name : '' },
      { cost : 0 },
    ] );
  }
  console.log('Shipping rendering!');
  return (
    <React.Fragment>
      <Typography variant="h5" className={props.materialClasses.sectionMarginBottom}>
        Costo por envío
      </Typography>
      <Grid item container xs={12} md={12} spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Paper className={props.materialClasses.paper}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Tipo</FormLabel>
                  <RadioGroup row aria-label="shippingCostType" name="shippingCostType" value={ shippingCostType } onChange={radioChangeHandler}>
                    <FormControlLabel value="F" control={<Radio color="primary" size="small"/>} label="Fijo" />
                    <FormControlLabel value="V" control={<Radio color="primary" size="small"/>} label="Variable" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {
                shippingCostType === 'F'
                  ? <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        required
                        label="Costo por envío"
                        autoComplete="off"
                        value={ fixedShippingCost }
                        onChange={inputChangeHandler}
                        name="fixedShippingCost"
                        id="fixedShippingCost"
                        margin="normal"
                        style={{marginTop: 0}}
                        // className={props.materialClasses.textField}
                        InputProps={{
                          inputComponent: Money,
                        }}
                      />
                    </Grid>
                  : <Grid item xs={12} sm={6} md={9}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Asignar costo de envío por</FormLabel>
                        <RadioGroup row aria-label="shippingCostAssignedBy" name="shippingCostAssignedBy" value={ shippingCostAssignedBy } onChange={radioChangeHandler}>
                          <FormControlLabel value="Z" control={<Radio color="primary" size="small"/>} label="Zona" />
                          <FormControlLabel value="M" control={<Radio color="primary" size="small"/>} label="Municipio" />
                          <FormControlLabel value="D" control={<Radio color="primary" size="small"/>} label="Departamento / Provincia" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
              }
              {
                shippingCostType === 'V'
                  ? <Grid item container xs={12} spacing={2} alignItems="center">
                      <Grid item xs={6} md={4} lg={3}>
                        <TextField
                          required
                          fullWidth
                          margin="normal"
                          label="Nombre"
                          name="name"
                          autoComplete="name"
                          type="text"
                          error={!!errors.name}
                          inputRef={ register({ required: true }) }
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={3}>
                        <TextField
                          required
                          fullWidth
                          margin="normal"
                          label="Costo"
                          autoComplete="off"
                          name="cost"
                          // style={{marginTop: 0}}
                          // className={props.materialClasses.textField}
                          error={!!errors.cost}
                          inputRef={ register({ required: true }) }
                          InputProps={{
                            inputComponent: Money
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3} lg={2}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="outlined"
                          color="primary"
                          onClick={handleSubmit(onSubmit)}
                          // className={materialClasses.submit}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  : null
              }
              {
                ( shippingCostType === 'V' && shippingCostAssignedBy === 'Z' )
                  ? <Grid item xs={12} sm={12} md={11} lg={8}>
                      <ShippingTable title="Zona" data={ areas } onEdit={ editDataHandler }  onDelete={ deleteDataHandler }/>
                    </Grid>
                  : null
              }
              {
                ( shippingCostType === 'V' && shippingCostAssignedBy === 'M' )
                  ? <Grid item xs={12} sm={12} md={11} lg={8}>
                      <ShippingTable title="Municipio" data={ cities } onEdit={ editDataHandler }  onDelete={ deleteDataHandler }/>
                    </Grid>
                  : null
              }
              {
                ( shippingCostType === 'V' && shippingCostAssignedBy === 'D' )
                  ? <Grid item xs={12} sm={12} md={11} lg={8}>
                      <ShippingTable title="Departamento / Provincia" data={ states } onEdit={ editDataHandler }  onDelete={ deleteDataHandler }/>
                    </Grid>
                  : null
              }
              
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PremiumPaymentForms;
