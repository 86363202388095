import * as actionTypes from './actionTypes';
// import axios from '../../axios-decima';

export const layoutInit = ( loading, open ) => {
    return {
        type: actionTypes.LAYOUT_INIT,
        loading: loading,
        open: open,
    };
};

export const openDrawer = () => {
    return {
        type: actionTypes.OPEN_DRAWER
    };
};

export const closeDrawer = () => {
    return {
        type: actionTypes.CLOSE_DRAWER
    };
};
export const enableDrawer = () => {
    return {
        type: actionTypes.ENABLE_DRAWER
    };
};
export const disableDrawer = () => {
    return {
        type: actionTypes.DISABLE_DRAWER
    };
};

/*

export const purchaseInit = () => {
    return {
        type: actionTypes.PURCHASE_INIT
    };
};

export const fetchOrdersSuccess = ( orders ) => {
    return {
        type: actionTypes.FETCH_ORDERS_SUCCESS,
        orders: orders
    };
};

export const fetchOrdersFail = ( error ) => {
    return {
        type: actionTypes.FETCH_ORDERS_FAIL,
        error: error
    };
};

export const fetchOrdersStart = () => {
    return {
        type: actionTypes.FETCH_ORDERS_START
    };
};

export const fetchOrders = (token, userId) => {
    return dispatch => {
        dispatch(fetchOrdersStart());
        const queryParams = '?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"';
        axios.get( '/orders.json' + queryParams)
            .then( res => {
                const fetchedOrders = [];
                for ( let key in res.data ) {
                    fetchedOrders.push( {
                        ...res.data[key],
                        id: key
                    } );
                }
                dispatch(fetchOrdersSuccess(fetchedOrders));
            } )
            .catch( err => {
                dispatch(fetchOrdersFail(err));
            } );
    };
};
*/