import * as actionTypes from '../actions/actionTypes';
import { updateObject, empty } from '../../shared/utility';

const initialState = {
		loading: false,
		groupLoading: false,
		touched: false,
		isFormVisible: false,
		isIncreaseStockDialogVisible: false,
		isDecreaseStockDialogVisible: false,
		isFormVisible: false,
		redirectToForm: false,
		page: 0,
		tableFilter: '',
		error: null,
		defaultData: {
			id: null,
			internalReference: '',
			name: '',
			retailPrice: '',
			isDiscountApplicable: false,
			isFeatured: false,
			discount: '',
			discountType: 'K',//K:Porcentake, J:Valor fijo
			offerPrice: '',
			availableQuantity: 0,
			cost: 1,
			description: '<p>Ingrese aqu&iacute; la descripci&oacute;n del art&iacute;culo</p>',
			brand: null,
			model: null,
			articleType: null,
			unitMeasure: null,
			tags: '',
			isAlwaysAvailable: false,
			isVisibleInStore: true
		},
		data: {},
		tileData:[],
		newVariants: {
			sizes:[],
			colors:[],
			materials:[],
			styles:[],
			variants:[]
		},
		existingVariants:[],
		groups: [],
		groupsLevelTwo: [],
		groupsLevelThree: [],
		untouchedGroups: null,
		brands: [],
		untouchedBrands: null,
		articleTypes: {},
		categories: {},
		unitMeasures: {},
		articles: []
};

const initForm = (state, action) => {
	return updateObject( state, { 
		data: empty(action.data) ? updateObject(state.defaultData, { 
			articleType: state.articleTypes['key1'],
			// unitMeasure: state.unitMeasures['key1'],
			unitMeasure: Object.values(state.unitMeasures)[0],
		} ) : action.data, 
		tileData: action.tileData,
		newVariants: { sizes:[], colors:[], materials:[], styles:[], variants:[] },
		existingVariants: action.existingVariants,
		groups: empty( action.groups ) ? [...state.untouchedGroups] : action.groups, 
		groupsLevelTwo: empty( action.groupsLevelTwo ) ? [] : action.groupsLevelTwo, 
		groupsLevelThree: empty( action.groupsLevelThree ) ? [] :action.groupsLevelThree, 
		brands: empty(action.brands) ? Object.values(state.untouchedBrands) : action.brands, 
		isFormVisible: true, 
		redirectToForm: true, 
		touched: false 
	});
};

const releaseForm = (state, action) => {
	return updateObject( state, { groups:[], brands: [], isFormVisible: false, redirectToForm: false } );
};

const releaseGroups = (state, action) => {
	return updateObject( state, { 
		groups: [], 
		untouchedGroups: null, 
	});
};

const releaseBrands = (state, action) => {
	return updateObject( state, { 
		brands: [], 
		untouchedBrands: null, 
	});
};

const setArticleState = (state, action) => {
	return updateObject( state, action.state );
};

const setData = (state, action) => {
	const updatedData = updateObject( state.data, action.data );
	return updateObject( state, { data: updatedData, touched: true } );
};

const setTileData = (state, action) => {
	return updateObject( state, { tileData: action.tileData, touched: action.touched } );
};

const setNewVariants = (state, action) => {
	const updatedNewVariants = updateObject( state.newVariants, action.newVariants );
	return updateObject( state, { newVariants: updatedNewVariants, touched: true } );
};

const setExistingVariants = (state, action) => {
	return updateObject( state, { existingVariants: action.existingVariants, touched: action.touched } );
};

const setGroups = (state, action) => {
	return updateObject( state, { 
		groups: action.groups, 
		groupsLevelTwo: action.groupsLevelTwo, 
		groupsLevelThree: action.groupsLevelThree, 
		touched: action.touched 
	} );
};

const setBrands = (state, action) => {
	return updateObject( state, { brands: action.brands, touched: action.touched } );
};

const crudeArticleStart = ( state, action ) => {
	return updateObject( state, { loading: true } );
};

const crudeArticleFail = ( state, action ) => {
	return updateObject( state, { 
			loading: false,
			error: action.error
	});
};

const createArticleSuccess = ( state, action ) => {
	const updatedData = updateObject( state.data, action.data );
	const currentGroups = [...state.groups];
	return updateObject( state, {
		groups: !action.settings.isSingleCategoryEnabled ? action.groups : currentGroups,
		// brands: action.brands,
		untouchedGroups: action.untouchedGroups,
		// untouchedBrands: action.untouchedBrands,
		existingVariants: action.existingVariants,
		data: updatedData,
		loading: false,
		touched: false,
		newVariants: { sizes:[], colors:[], materials:[], styles:[], variants:[] }
	} );
};

const fetchCatalogsSuccess = ( state, action ) => {
	return updateObject( state, {
			loading: false,
			articleTypes: action.articleTypes,
			categories: action.categories,
			groups: action.groups,
			untouchedBrands: action.brands,
			untouchedGroups: action.groups,
			unitMeasures: action.unitMeasures,
	} );
};

const fetchGroupsSuccess = ( state, action ) => {
	return updateObject( state, {
			loading: false,
			groups: action.groups,
			untouchedGroups: action.groups
	} );
};

const fetchBrandsSuccess = ( state, action ) => {
	return updateObject( state, {
			loading: false,
			// brands: action.brands,
			untouchedBrands: action.brands
	} );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.INIT_ARTICLE_FORM: return initForm( state, action );
        case actionTypes.RELEASE_ARTICLE_FORM: return releaseForm( state, action );
        case actionTypes.RELEASE_GROUPS: return releaseGroups( state, action );
        case actionTypes.RELEASE_BRANDS: return releaseBrands( state, action );
        case actionTypes.SET_ARTICLE_STATE: return setArticleState( state, action );
        case actionTypes.SET_ARTICLE_DATA: return setData( state, action );
        case actionTypes.SET_TILE_DATA: return setTileData( state, action );
        case actionTypes.SET_NEW_VARIANTS: return setNewVariants( state, action );
        case actionTypes.SET_EXISTING_VARIANTS: return setExistingVariants( state, action );
        case actionTypes.SET_GROUPS: return setGroups( state, action );
        case actionTypes.SET_BRANDS: return setBrands( state, action );
        case actionTypes.CRUDE_ARTICLE_START: return crudeArticleStart( state, action );
        case actionTypes.CRUDE_ARTICLE_FAIL: return crudeArticleFail( state, action );
        case actionTypes.CREATE_ARTICLE_SUCCESS: return createArticleSuccess( state, action )
        case actionTypes.FETCH_CATALOGS_SUCCESS: return fetchCatalogsSuccess( state, action )
        case actionTypes.FETCH_GROUPS_SUCCESS: return fetchGroupsSuccess( state, action )
        case actionTypes.FETCH_BRANDS_SUCCESS: return fetchBrandsSuccess( state, action )
        default: return state;
    }
};

export default reducer;