import React, { useEffect, Suspense } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from './hoc/Layout/Layout';
// import BurgerBuilder from './containers/BurgerBuilder/BurgerBuilder';
import Logout from './containers/Auth/Logout/Logout';
import * as actions from './store/actions/index';
import Shop from './containers/Shop/Shop';
import ReactGA from 'react-ga';

const Auth = React.lazy(() => {
  return import('./containers/Auth/Auth');
});

const App = props => {
  const { onTryAutoSignup } = props;
  ReactGA.initialize('UA-23678568-12');
  ReactGA.pageview(window.location.pathname + window.location.search);
  
  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  let routes = (
    <Switch>
      <Route path="/auth" render={props => <Auth {...props} />} />
      <Route path="/eco" render={props => <Shop {...props} />} />
      {/* <Route path="/eco" component={Shop} /> */}
      {/* <Route path="/" exact component={BurgerBuilder} /> */}
      <Redirect to="/eco" />
    </Switch>
  );

  if (props.isAuthenticated) {
    routes = (
      <Switch>
        <Route path="/logout" component={Logout} />
        <Route path="/auth" render={props => <Auth {...props} />} />
        <Route path="/eco" render={props => <Shop {...props} />} />
        {/* <Route path="/" exact component={BurgerBuilder} /> */}
        <Redirect to="/eco" />
      </Switch>
    );
  }

  return (
    <div>
      <Layout {...props}>
        <Suspense fallback={<p>Loading...</p>}>{routes}</Suspense>
      </Layout>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated
    // isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
