import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import * as actions from '../../../../store/actions/index';
import MainForm from './MainForm';
import Media from './Media';
import Categories from './Categories';
import Variants from './Variants';
import ActionButtons from './ActionButtons';
import ExistingVariants from './ExistingVariants';
import { empty } from '../../../../shared/utility';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  paperForm: {
    flexWrap: 'wrap'
  },
  mgPaper: {
    marginTop: 20,
  },
  mgChip: {
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(3),
  },
  formSwitch: {
    marginLeft: 5,
    marginTop: 36,
  },
  formRadioGroup: {
    display:'inline-flex',
    marginTop:16,
  },
  elementsContainer: {
    alignContent: 'center',
    // justifyContent: 'center'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  button: {
    margin: theme.spacing(0.5),
  },
  editor: {
    marginTop: 20,
  },
  mediaContainer: {
    width: '100%',
  },
  loader: {
    marginTop: theme.spacing(2),
  },
}));

const Form = (props) => {
  const materialClasses = useStyles();
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography component="h5" variant="h5">
          Nuevo artículo
        </Typography>
      </Grid>
      <Grid item container xs={12} md={8}>
        <MainForm materialClasses={materialClasses} {...props}/>
        <Media materialClasses={materialClasses} {...props}/>
      </Grid>
      <Grid item xs={12} md={4}>
        <ActionButtons materialClasses={materialClasses} {...props}/>
        <Categories materialClasses={materialClasses} {...props}/>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Variants materialClasses={materialClasses} {...props}/>
        <ExistingVariants materialClasses={materialClasses} {...props}/>
      </Grid>
    </React.Fragment>
  )
}

export default Form;
