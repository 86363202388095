import React from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Editor } from '@tinymce/tinymce-react';
import * as actions from '../../../../store/actions/index';
import { empty, updateObject } from '../../../../shared/utility';
import config from '../../../../config';

const DocumentTypes = (props) => {
  const documentTypes = useSelector(state => state.setting.settings.documentTypes);
  const dispatch = useDispatch();
  const onSetSettings = (settings) => dispatch(actions.setSettings(settings));
  const switchChangeHandler = (event) => {
    const updatedDocumentTypes = updateObject( documentTypes, {} );
    updatedDocumentTypes[event.target.name]['isEnabled'] = event.target.checked;
    onSetSettings({ documentTypes: updatedDocumentTypes });
  };
  const inputChangeHandler = (event) => {
    const updatedDocumentTypes = updateObject( documentTypes, {} );
    updatedDocumentTypes[event.target.name]['label'] = event.target.value;
    if(empty(event.target.value)) updatedDocumentTypes[event.target.name]['hasError'] = true;
    else updatedDocumentTypes[event.target.name]['hasError'] = false;
    onSetSettings({ documentTypes: updatedDocumentTypes });
  };
  console.log('Document types rendering!');
  return (
    <React.Fragment>
      <Typography variant="h5" className={props.materialClasses.sectionMarginBottom}>
        Tipos de documentos
      </Typography>
      <Grid item container xs={12} md={12} spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid item container spacing={2}>
            { 
              Object.values(documentTypes)
                .map( (documentType) => (
                <Grid key={'grid' + documentType.id} item xs={12} sm={6} md={4} lg={3}>
                  <Paper className={props.materialClasses.paper}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Switch checked={ documentTypes[documentType.id]['isEnabled'] } onChange={switchChangeHandler} name={ documentTypes[documentType.id]['id'] } />}
                          label="Habilitada"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          error={ documentTypes[documentType.id]['hasError'] }
                          disabled={ !documentTypes[documentType.id]['isEnabled'] }
                          label="Etiqueta"
                          margin="normal"
                          name={ documentTypes[documentType.id]['id'] }
                          value={ documentTypes[documentType.id]['label'] }
                          onChange={ inputChangeHandler }
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid> 
              ) ) 
            }
          </Grid> 
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DocumentTypes;
