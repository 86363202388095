import React from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Editor } from '@tinymce/tinymce-react';
import * as actions from '../../../../store/actions/index';
import config from '../../../../config';

const Footer = (props) => {
  const footer = useSelector(state => state.setting.settings.footer);
  const dispatch = useDispatch();
  const onSetSettings = (settings) => dispatch(actions.setSettings(settings));
  const editorChangeHandler = (content, editor) => {
    onSetSettings({ footer: content });
  }
  console.log('General rendering!');
  return (
    <React.Fragment>
      <Typography variant="h5" className={props.materialClasses.sectionMarginBottom}>
        Pie de página
      </Typography>
      <Grid item container xs={12} md={12} spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Paper className={props.materialClasses.paper}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Editor
                  apiKey={ config.editorKey }
                  value={ footer }
                  init={{
                    height: 200,
                    menubar: false,
                    toolbar_mode: 'wrap',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount emoticons'
                    ],
                    toolbar:
                      'undo redo | fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | help'
                  }}
                  onEditorChange={editorChangeHandler}
                />
              </Grid>
            </Grid> 
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Footer;
