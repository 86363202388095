import * as actionTypes from './actionTypes';
import axios from '../../axios-decima';
import { empty, fireToast, fireMessage, fireErrorMessage } from '../../shared/utility';

export const initCouponForm = (id, data) => {
	return {
		type: actionTypes.INIT_COUPON_FORM,
		id: id,
		data: data,
	};
};

export const setCouponState = ( state ) => {
	return {
		type: actionTypes.SET_COUPON_STATE,
		state: state
	};
};

export const couponStart = () => {
	return {
		type: actionTypes.COUPON_START
	};
};

export const couponFail = ( error ) => {
	return {
		type: actionTypes.COUPON_FAIL,
		error: error
	};
}

// export const createArticleSuccess = ( groups, untouchedGroups, brands, untouchedBrands, existingVariants, data ) => {
export const couponCrudSuccess = ( id, data ) => {
	return {
		type: actionTypes.COUPON_CRUD_SUCCESS,
		id: id,
		data: data
	};
};

export const crudeCoupon = ( data, token, formData ) => {
	return dispatch => {
		let url = '/api/sales/createCoupon';
		let edit = false;
		if(!empty(data.id))
		{
			url = '/api/sales/updateCoupon';
			edit = true;
		}
		dispatch( couponStart() );
		axios.post( url + '?token=' + token, data )
			.then( response => {
				if(!empty( response.data.info ))
				{
					fireMessage({
						text: response.data.info
					});

					dispatch( couponFail( response.data.info ) );
				}
				else if(!empty( response.data.success ))
				{
					if(!empty( response.data.message ))
					{
						fireMessage({
							icon: 'success',
							message: '',
							html: response.data.message
						});
					}
					else
					{
						fireToast( response.data.success );
					}

					dispatch(
						couponCrudSuccess( response.data.id, formData )
					);
				}
				else
				{
					fireErrorMessage();

					dispatch( couponFail( 'Unrecognized server message' ) );
				}
				
			} )
			.catch( error => {
				dispatch( couponFail( error ) );
			} );
	};
};

