import axios from 'axios';
import config from './config';

// Set config defaults when creating the instance
const instance = axios.create({
  baseURL: config.firebaseUrl
})

// // Alter defaults after instance has been created
// instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export default instance;
