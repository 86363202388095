import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import ListIcon from '@material-ui/icons/List';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import { makeStyles } from '@material-ui/core/styles';
import { isMobileOnly } from 'react-device-detect';
import Sidebar from '../../components/Navigation/Sidebar/Sidebar';
import IntroPage from '../../components/Shop/IntroPage/IntroPage';
import PlansPage from '../../components/Shop/PlansPage/PlansPage';
import Subscriptions from './Subscriptions/Subscriptions';
import Article from './Article/Article';
import Catalog from './Catalog/Catalog';
import Coupon from './Coupon/Coupon';
import Setting from './Setting/Setting';
import Inventory from './Inventory/Inventory';
import Promotion from './Promotion/Promotion';
import Orders from './Orders/Orders';
import * as actions from '../../store/actions/index';

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const Shop = props => {
  const materialClasses = useStyles();
  const open = useSelector(state => state.layout.open);
  const untouchedGroups = useSelector(state => state.article.untouchedGroups);
  const untouchedBrands = useSelector(state => state.article.untouchedBrands);
  const token = useSelector(state => state.auth.token);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const organizationId = useSelector(state => state.auth.organizationId);
  const settings = useSelector(state => state.setting.settings);
  const dispatch = useDispatch();
  const onCloseDrawer = () => dispatch(actions.closeDrawer());
  const onEnableDrawer = useCallback(
    () => dispatch(actions.enableDrawer()),
    [dispatch]
  );
  const onFetchSettings = useCallback(
    (organizationId) => dispatch(actions.fetchSettings(organizationId)),
    [dispatch]
  );
  const onFetchCatalogs = useCallback(
    (token) => dispatch(actions.fetchCatalogs(token)),
    [dispatch]
  );
  // const onFetchGroups = useCallback(
  //   (token) => dispatch(actions.fetchGroups(token)),
  //   [dispatch]
  // );
  // const onFetchBrands = useCallback(
  //   (token) => dispatch(actions.fetchBrands(token)),
  //   [dispatch]
  // );
  
  useEffect(() => {
    if(window.location.pathname === '/eco/' || window.location.pathname === '/eco')
    {
      props.history.replace('/eco/inicio');
    }
    onEnableDrawer();
  }, [onEnableDrawer, props.history]);
  useEffect(() => {
    if(settings == null)
    {
      onFetchSettings(organizationId);
    }
  }, [onFetchSettings, settings, organizationId]);
  useEffect(() => {
    if(untouchedGroups == null)
    {
      onFetchCatalogs(token);
    }
  }, [onFetchCatalogs, untouchedGroups, token]);  
  // useEffect(() => {
  //   if(untouchedGroups == null)
  //   {
  //     onFetchGroups(token);
  //   }
  // }, [onFetchGroups, untouchedGroups, token]);  
  // useEffect(() => {
  //   if(untouchedBrands == null)
  //   {
  //     onFetchBrands(token);
  //   }
  // }, [onFetchBrands, untouchedBrands, token]);
  
  const navLinkClickHandler = (url) => {
    props.history.push('/eco/' + url);
    if(isMobileOnly) onCloseDrawer();
  };

  // const fixedHeightPaper = clsx(materialClasses.paper, materialClasses.fixedHeight);

  const primaryListItems = (
    <div>
      <ListSubheader inset>DecimaEco</ListSubheader>
      <ListItem button onClick={() => navLinkClickHandler('inicio')}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Inicio" />
      </ListItem>
      <ListItem button onClick={() => navLinkClickHandler('precios')}>
        <ListItemIcon>
          <LocalOfferIcon />
        </ListItemIcon>
        <ListItemText primary="Precios" />
      </ListItem>
      <ListItem button onClick={() => navLinkClickHandler('suscripciones')}>
        <ListItemIcon>
          <TouchAppIcon />
        </ListItemIcon>
        <ListItemText primary="Suscripciones" />
      </ListItem>
    </div>
  );

  const secondaryListItems = (
    <div>
      <ListSubheader inset>Mi Ecommerce</ListSubheader>
      {/* <ListItem button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem> */}
      <ListItem button onClick={() => navLinkClickHandler('pedidos')}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Pedidos" />
      </ListItem>
      <ListItem button onClick={() => navLinkClickHandler('articulos')}>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary="Artículos" />
      </ListItem>
      <ListItem button onClick={() => navLinkClickHandler('cupones')}>
        <ListItemIcon>
          <LoyaltyIcon />
        </ListItemIcon>
        <ListItemText primary="Cupones" />
      </ListItem>
      <ListItem button onClick={() => navLinkClickHandler('promociones')}>
        <ListItemIcon>
          <LocalOfferIcon />
        </ListItemIcon>
        <ListItemText primary="Promociones" />
      </ListItem>
      {/* <ListItem button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Clientes" />
      </ListItem> */}
      <ListItem button onClick={() => navLinkClickHandler('catalogos')}>
        <ListItemIcon>
          <LibraryBooksIcon />
        </ListItemIcon>
        <ListItemText primary="Catálogos" />
      </ListItem>
      <ListItem button onClick={() => navLinkClickHandler('configuraciones')}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Configuraciones" />
      </ListItem>
    </div>
  );

  return (
    <>
      <Sidebar primarylistItems={ isAuthenticated ? secondaryListItems : primaryListItems } secondaryListItems={ !isAuthenticated ? secondaryListItems : null } open={open} handleDrawerClose={onCloseDrawer} navlinkonclicked={navLinkClickHandler}/>
      <main className={materialClasses.content}>
        <div className={materialClasses.appBarSpacer} />
        <Container maxWidth="lg" className={materialClasses.container}>
          <Route
            path={props.match.path + '/inicio'}
            component={IntroPage}
          />
          <Route
            path={props.match.path + '/precios'}
            component={PlansPage}
          />
          <Route
            path={props.match.path + '/suscripciones'}
            component={Subscriptions}
          />
          <Route
            path={props.match.path + '/pedidos'}
            component={Orders}
          />
          <Route
            path={props.match.path + '/articulos'}
            component={Article}
          />
          <Route
            path={props.match.path + '/cupones'}
            component={Coupon}
          />
          <Route
            path={props.match.path + '/promociones'}
            component={Promotion}
          />
          <Route
            path={props.match.path + '/inventario'}
            component={Inventory}
          />
          <Route
            path={props.match.path + '/configuraciones'}
            component={Setting}
          />
          <Route
            path={props.match.path + '/catalogos'}
            component={Catalog}
          />
        </Container>
      </main>
    </>
  )
}

export default Shop;
