import React from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  mediaRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  mediaGridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  mediaPrimary: {
    color: theme.palette.secondary.light,
  },
  mediaSecondary: {
    color: 'white'
  },
  mediaTitleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  }
}));

const ImageGallery = (props) => {
  const materialClasses = useStyles();
  return (
    <div className={materialClasses.mediaRoot}>
      <GridList className={materialClasses.mediaGridList} cols={2.5}>
        {props.tileData.map((tile) => (
          <GridListTile key={'media' + tile.id} style={{height: '250px', width:'auto'}}>
            <img src={tile.image_url} alt="" style={{height: '100%', width:'auto'}} />
            <GridListTileBar
              title={tile.primary?'Principal':'Secundaria'}
              classes={{
                root: materialClasses.mediaTitleBar,
                title: tile.primary?materialClasses.mediaPrimary:materialClasses.mediaSecondary,
              }}
              actionIcon={
                <React.Fragment>
                  <IconButton onClick={() => props.setImageAsPrimary(tile.id)}>
                    <StarBorderIcon className={tile.primary?materialClasses.mediaPrimary:materialClasses.mediaSecondary} />
                  </IconButton>
                  <IconButton onClick={() => props.disassociatesImage(tile.id)}>
                    <DeleteOutlineIcon className={materialClasses.mediaSecondary}/>
                  </IconButton>
                </React.Fragment>
              }
              // actionPosition="left"
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  )
}

export default ImageGallery;
