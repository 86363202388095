import React from 'react';
import {useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import ImageGallery from '../../../../components/Media/ImageGallery/ImageGallery';
import * as actions from '../../../../store/actions/index';
import { empty } from '../../../../shared/utility';

const Media = (props) => {
  const tileData = useSelector(state => state.article.tileData);
  const dispatch = useDispatch();
  const onSetTileData = (data) => dispatch(actions.setTileData(data));
  const onSetImageAsPrimary = (id) => {
    const updatedTileData = [...tileData];

    for ( let tile in updatedTileData )
    {
      updatedTileData[tile]['primary'] = false;

      if(updatedTileData[tile]['id'] === id)
      {
        updatedTileData[tile]['primary'] = true;
      }
    }

    onSetTileData(updatedTileData);
  };
  const onDisassociatesImage = (id, url) => {
    const updatedTileData = [];
    let isPrimaryDeleted = false;

    for ( let tile in tileData )
    {
      if(tileData[tile]['id'] === id && tileData[tile]['primary'] === true)
      {
        isPrimaryDeleted = true;
      }

      if(tileData[tile]['id'] !== id)
      {
        updatedTileData.push(tileData[tile]);
      }
    }

    if(isPrimaryDeleted && !empty(updatedTileData))
    {
      updatedTileData[0]['primary'] = true;
    }

    onSetTileData(updatedTileData);
  }
  console.log('Media rendering!');
  return (
    <Paper className={[props.materialClasses.paper, props.materialClasses.mgPaper, props.materialClasses.mediaContainer].join(' ')}>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography component="h6" variant="h6">
            Media
          </Typography>
        </Box>
        <Box>
          <Button color="primary" startIcon={<CloudUploadIcon />} onClick={() => props.setUploading(true)}>Subir imagen</Button>
        </Box>
      </Box>
      <ImageGallery tileData={tileData} setImageAsPrimary={onSetImageAsPrimary} disassociatesImage={onDisassociatesImage}/>
    </Paper>
  )
}

export default Media;