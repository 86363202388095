import * as actionTypes from '../actions/actionTypes';
import { updateObject, empty } from '../../shared/utility';

const initialState = {
  loading: false,
  touched: false,
  isFormVisible: false,
  // redirectToForm: false,
  page: 0,
  tableFilter: '',
  articleTableFilter: '',
  error: null,
  id: null,
  data: {},
};

const initPromotionForm = (state, action) => {
	return updateObject( state, { 
    touched: false,
		isFormVisible: true, 
    // redirectToForm: true, 
    error: null,
    id: action.id,
		data: empty(action.data) ? {} : action.data
	});
};

const setPromotionState = (state, action) => {
	return updateObject( state, action.state );
};

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
      case actionTypes.INIT_PROMOTION_FORM: return initPromotionForm( state, action );
      case actionTypes.SET_PROMOTION_STATE: return setPromotionState( state, action );
      default: return state;
  }
};

export default reducer;