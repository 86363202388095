import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PromotionTable from './Table/Table';
import PromotionForm from './Form/Form';
// import ImageUploader from './ImageUploader/ImageUploader';
import Spinner from '../../../components/UI/Spinner/Spinner';
import useHttpErrorHandler from '../../../hooks/http-error-handler';
import axios from '../../../axios-decima';
import 'react-dropzone-uploader/dist/styles.css';

const useStyles = makeStyles(theme => ({
  loader: {
    marginTop: theme.spacing(2),
  },
}));

const Promotion = props => {
  const materialClasses = useStyles();
  const [uploading, setUploading] = useState(false);
  const [error, clearError] = useHttpErrorHandler(axios);
  const loading = useSelector(state => state.promotion.loading);
  const data = useSelector(state => state.promotion.data);
  const isFormVisible = useSelector(state => state.promotion.isFormVisible);
  return (
    <Grid container spacing={1}>
      {
        (isFormVisible && !loading && !uploading)
          ? <PromotionForm data={data} setUploading={setUploading}/>
          : null   
      }
      {
        (!isFormVisible && !loading && !uploading)
          ? <PromotionTable/>  
          : null
      }
      {
        (loading)
          ? <Grid item xs={12}>
              <div className={materialClasses.loader}>
                <Spinner/>
              </div>
            </Grid>
          : null
      }
    </Grid>
  );
}

export default Promotion;
