// Layouts
export const LAYOUT_INIT = 'LAYOUT_INIT';
export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const ENABLE_DRAWER = 'ENABLE_DRAWER';
export const DISABLE_DRAWER = 'DISABLE_DRAWER';

// Auth
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FIREBASE_SUCCESS = 'AUTH_FIREBASE_SUCCESS';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

// Articles
export const INIT_ARTICLE_FORM = 'INIT_ARTICLE_FORM';
export const RELEASE_GROUPS = 'RELEASE_GROUPS';
export const RELEASE_BRANDS = 'RELEASE_BRANDS';
export const RELEASE_ARTICLE_FORM = 'RELEASE_ARTICLE_FORM';
export const SET_ARTICLE_STATE = 'SET_ARTICLE_STATE';
export const SET_ARTICLE_DATA = 'SET_ARTICLE_DATA';
export const SET_TILE_DATA = 'SET_TILE_DATA';
export const SET_NEW_VARIANTS = 'SET_NEW_VARIANTS';
export const SET_EXISTING_VARIANTS = 'SET_EXISTING_VARIANTS';
export const SET_GROUPS = 'SET_GROUPS';
export const SET_BRANDS = 'SET_BRANDS';
export const CRUDE_ARTICLE_START = 'CRUDE_ARTICLE_START';
export const CRUDE_ARTICLE_FAIL = 'CRUDE_ARTICLE_FAIL';
export const FETCH_CATALOGS_SUCCESS = 'FETCH_CATALOGS_SUCCESS';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_BRANDS_SUCCESS = 'FETCH_BRANDS_SUCCESS';
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS';
export const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS';
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS';

// Settings
export const RELEASE_SETTINGS = 'RELEASE_SETTINGS';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SETTING_START = 'SETTING_START';
export const SETTING_FAIL = 'SETTING_FAIL';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS';

// Coupon
export const INIT_COUPON_FORM = 'INIT_COUPON_FORM';
export const SET_COUPON_STATE = 'SET_COUPON_STATE';
export const COUPON_START = 'COUPON_START';
export const COUPON_FAIL = 'COUPON_FAIL';
export const COUPON_CRUD_SUCCESS = 'COUPON_CRUD_SUCCESS';

// Order
export const INIT_ORDER_FORM = 'INIT_ORDER_FORM';
export const SET_ORDER_STATE = 'SET_ORDER_STATE';
export const ORDER_START = 'ORDER_START';
export const ORDER_FAIL = 'ORDER_FAIL';
export const ORDER_CRUD_SUCCESS = 'ORDER_CRUD_SUCCESS';

// Promotion
export const INIT_PROMOTION_FORM = 'INIT_PROMOTION_FORM';
export const SET_PROMOTION_STATE = 'SET_PROMOTION_STATE';
export const PROMOTION_START = 'COUPON_START';
export const PROMOTION_FAIL = 'COUPON_FAIL';
export const PROMOTION_CRUD_SUCCESS = 'COUPON_CRUD_SUCCESS';

// Catalog
export const INIT_CATALOG_FORM = 'INIT_CATALOG_FORM';
export const SET_CATALOG_STATE = 'SET_CATALOG_STATE';
export const CATALOG_START = 'COUPON_START';
export const CATALOG_FAIL = 'COUPON_FAIL';
export const CATALOG_CRUD_SUCCESS = 'COUPON_CRUD_SUCCESS';

// Template
// export const INIT_NAME_FORM = 'INIT_NAME_FORM';
// export const SET_NAME_STATE = 'SET_NAME_STATE';
