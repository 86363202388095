import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { empty, ccyFormat, isDemo } from '../../../shared/utility';
import Spinner from '../../../components/UI/Spinner/Spinner';
import * as actions from '../../../store/actions/index';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 150,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  loader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ChangeStatusDialog = (props) => {
  const materialClasses = useStyles();
  const [id, setId] = useState(props.selectedRow.id);
  const [status, setStatus] = useState(props.selectedRow.status);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const settings = useSelector(state => state.layout.settings);
  const loading = useSelector(state => state.order.loading);
  const isDialogVisible = useSelector(state => state.order.isDialogVisible);
  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();
  const onSetOrderState = (state) => dispatch(actions.setOrderState(state));
  const onChangeStatus = (data, token) => dispatch(actions.crudeOrder(data, token));
  useEffect(() => {
    setStatus(props.selectedRow.status);
  }, [props.selectedRow.status]);
  useEffect(() => {
    setId(props.selectedRow.id);
  }, [props.selectedRow.id]);
  const selectHandler = (event) => {
    setStatus(event.target.value);
  };
  const changeStatusHandler = () => {
    // console.log('Cambiar estado del pedido!');
    if(isDemo(isAuthenticated))
    {
      onSetOrderState({ isDialogVisible: false });
      return;
    }

    onChangeStatus({ id: id, status: status }, token);
    // onSetOrderState({ isDialogVisible: false });
  };

  console.log('Rendering ChangeStatusDialog!');
  return (
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth='xs'
        open={isDialogVisible}
        onClose={props.onToggleDialog}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Cambiar estado de pedido</DialogTitle>
        <DialogContent>
          {
            status != 'C'
              ? <DialogContentText>
                  Seleccione el nuevo estado del pedido <strong>#{props.selectedRow.number}</strong> del cliente <strong>{props.selectedRow.client_name}</strong>:
                </DialogContentText>
              : null
          }
          {
            !loading 
              ? 
                <form className={materialClasses.form} noValidate>
                  {
                    status != 'C'
                    ?
                    <FormControl className={materialClasses.formControl}>
                      <Select
                        autoFocus
                        value={status}
                        onChange={selectHandler}
                        // inputProps={{
                        //   name: 'max-width',
                        //   id: 'max-width',
                        // }}
                      >
                        <MenuItem value="P">Pago pendiente</MenuItem>
                        <MenuItem value="A">Comprado</MenuItem>
                        <MenuItem value="B">En preparación</MenuItem>
                        <MenuItem value="D">Despachado</MenuItem>
                        <MenuItem value="E">Entregado</MenuItem>
                        <MenuItem value="F">Facturado</MenuItem>
                        <MenuItem value="C">Cancelado</MenuItem>
                        {/* <MenuItem value="G">Pago rechazado</MenuItem> */}
                      </Select>
                    </FormControl>
                    :
                    <Typography variant="body1" align="center">
                      El pedido <strong>#{props.selectedRow.number}</strong> del cliente <strong>{props.selectedRow.client_name}</strong> se encuentra en estado <strong>cancelado</strong>, y ya no puede ser modificado.
                    </Typography>
                  }
                  
                </form>
              :
              <div className={materialClasses.loader}>
                <Spinner />
              </div>
          }
        </DialogContent>
        {
          !loading
            ?
              <DialogActions>
                {
                  status != 'C'
                    ? <Button onClick={() => changeStatusHandler()} color="primary">
                        Cambiar estado
                      </Button>
                    : null
                }
                <Button onClick={() => onSetOrderState({ isDialogVisible: false })} color="secondary">
                  Regresar
                </Button>
              </DialogActions>
          : null
        }
        
      </Dialog>
    </React.Fragment>
  )
}

export default ChangeStatusDialog;
