import React, {useState} from 'react';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import {Icon} from '@material-ui/core';

const SearchInput = (props) => {
  const [search, setSearch] = useState(props.searchValue);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleCleanSearch = (event) => {
    setSearch('');
  };

  const onEnterKeyPress = (event) => {
    if(event.key === 'Enter')
    {
      props.onSearchChanged(search)
    }
  }

  return (
    <Input
      value={search}
      onChange={handleSearchChange}
      onKeyPress={onEnterKeyPress}
      startAdornment={
        <InputAdornment position="start">
          <Icon>search</Icon>
        </InputAdornment>
      }
      placeholder="Buscar"
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            onClick={handleCleanSearch}
            disabled={search.length === 0}
          >
            <Icon>close</Icon>
          </IconButton>
        </InputAdornment>
      }
    />
  )
}

export default SearchInput;
