import * as actionTypes from './actionTypes';
import axios from '../../axios-decima';
import { empty, fireToast, fireMessage, fireErrorMessage } from '../../shared/utility';

export const initForm = (data, groups, groupsLevelTwo, groupsLevelThree, brands, tileData = [], existingVariants = []) => {
	return {
		type: actionTypes.INIT_ARTICLE_FORM,
		data: data,
		groups: groups,
		groupsLevelTwo: groupsLevelTwo,
		groupsLevelThree: groupsLevelThree,
		brands: brands,
		tileData: tileData,
		existingVariants: existingVariants,
	};
};

export const releaseForm = () => {
	return {
		type: actionTypes.RELEASE_ARTICLE_FORM
	};
};

export const releaseGroups = () => {
	return {
		type: actionTypes.RELEASE_GROUPS
	};
};

export const releaseBrands = () => {
	return {
		type: actionTypes.RELEASE_BRANDS
	};
};

export const setArticleState = ( state ) => {
	return {
		type: actionTypes.SET_ARTICLE_STATE,
		state: state
	};
};

export const setData = ( data ) => {
	return {
		type: actionTypes.SET_ARTICLE_DATA,
		data: data
	};
};

export const setTileData = ( tileData, touched = true ) => {
	return {
		type: actionTypes.SET_TILE_DATA,
		tileData: tileData,
		touched: touched
	};
};

export const setNewVariants = ( newVariants ) => {
	return {
		type: actionTypes.SET_NEW_VARIANTS,
		newVariants: newVariants
	};
};

export const setExistingVariants = ( existingVariants, touched = true ) => {
	return {
		type: actionTypes.SET_EXISTING_VARIANTS,
		existingVariants: existingVariants,
		touched: touched
	};
};

export const setGroups = ( groups, groupsLevelTwo, groupsLevelThree, touched = true ) => {
	return {
		type: actionTypes.SET_GROUPS,
		groups: groups,
		groupsLevelTwo: groupsLevelTwo,
		groupsLevelThree: groupsLevelThree,
		touched: touched
	};
};

export const setBrands = ( brands, touched = true ) => {
	return {
		type: actionTypes.SET_BRANDS,
		brands: brands,
		touched: touched
	};
};

export const crudeArticleStart = () => {
	return {
		type: actionTypes.CRUDE_ARTICLE_START
	};
};

export const crudeArticleFail = ( error ) => {
	return {
		type: actionTypes.CRUDE_ARTICLE_FAIL,
		error: error
	};
}

// export const createArticleSuccess = ( groups, untouchedGroups, brands, untouchedBrands, existingVariants, data ) => {
export const createArticleSuccess = ( groups, untouchedGroups, existingVariants, data, settings) => {
	// if(!empty(data.row))
	return {
		type: actionTypes.CREATE_ARTICLE_SUCCESS,
		groups: groups,
		untouchedGroups: untouchedGroups,
		// brands: brands,
		// untouchedBrands: untouchedBrands,
		existingVariants: existingVariants,
		data: data,
		settings: settings
	};
};

export const crudeArticle = ( data, token, settings ) => {
	return dispatch => {
		let url = '/api/inventory/createArticle';
		let edit = false;
		if(!empty(data.id))
		{
			url = '/api/inventory/updateArticle';
			edit = true;
		}
		dispatch( crudeArticleStart() );
		axios.post( url + '?token=' + token, data )
			.then( response => {
				if(!empty(response.data.info))
				{
					fireMessage({
						text: response.data.info
					});

					dispatch( crudeArticleFail( response.data.info ) );
				}
				else if(!empty(response.data.success))
				{
					if(!empty(response.data.message))
					{
						fireMessage({
							icon: 'success',
							message: '',
							html: response.data.message
						});
					}
					else
					{
						fireToast(response.data.success);
					}

					dispatch(
						createArticleSuccess( Object.values(response.data.articleGroups), Object.values(response.data.groups), Object.values(response.data.variants), { id: response.data.id }, settings )
					);
				}
				else
				{
					fireErrorMessage();

					dispatch( crudeArticleFail( 'Unrecognized server message' ) );
				}
				
			} )
			.catch( error => {
				dispatch( crudeArticleFail( error ) );
			} );
	};
};

export const fetchCatalogsSuccess = ( articleTypes, brands, categories, groups, unitMeasures ) => {
	return {
		type: actionTypes.FETCH_CATALOGS_SUCCESS,
		articleTypes: articleTypes,
		brands: brands,
		categories: categories,
		groups: groups,
		unitMeasures: unitMeasures
	};
};

export const fetchCatalogs = ( token ) => {
	return dispatch => {
		dispatch( crudeArticleStart() );
		axios.get( '/api/inventory/getEcoCatalogs?token=' + token )
			.then( res => {
				dispatch( fetchCatalogsSuccess( res.data.articleTypes, res.data.brands, res.data.categories, Object.values(res.data.ecoGroups), res.data.unitMeasures ) );
			} )
			.catch( error => {
				dispatch( crudeArticleFail( error ) );
			} );
    };
};

export const fetchGroupsSuccess = ( groups ) => {
	return {
		type: actionTypes.FETCH_GROUPS_SUCCESS,
		groups: groups
	};
};

export const fetchGroups = ( token ) => {
	return dispatch => {
		dispatch( crudeArticleStart() );
		axios.get( '/api/inventory/getGroups?token=' + token )
			.then( res => {
				dispatch( fetchGroupsSuccess( Object.values(res.data) ) );
			} )
			.catch( error => {
				dispatch( crudeArticleFail( error ) );
			} );
    };
};

export const fetchBrandsSuccess = ( brands ) => {
	return {
		type: actionTypes.FETCH_BRANDS_SUCCESS,
		brands: brands
	};
};

export const fetchBrands = ( token ) => {
	return dispatch => {
		dispatch( crudeArticleStart() );
		axios.get( '/api/inventory/getBrands?token=' + token )
			.then( res => {
				// dispatch( fetchBrandsSuccess( Object.values(res.data) ) );
				dispatch( fetchBrandsSuccess( res.data ) );
			} )
			.catch( error => {
				dispatch( crudeArticleFail( error ) );
			} );
    };
};