import * as actionTypes from '../actions/actionTypes';
import { updateObject, empty } from '../../shared/utility';

const initialState = {
  loading: false,
  touched: false,
  isFormVisible: false,
  page: 0,
  tableFilter: '',
  error: null,
  id: null,
  data: {},
};

const initCatalogForm = (state, action) => {
	return updateObject( state, { 
    touched: false,
		isFormVisible: true, 
    // redirectToForm: true, 
    error: null,
    id: action.id,
		data: empty(action.data) ? {} : action.data
	});
};

const setCatalogState = (state, action) => {
	return updateObject( state, action.state );
};

const catalogStart = ( state, action ) => {
	return updateObject( state, { loading: true } );
};

const catalogFail = ( state, action ) => {
  return updateObject( state, { 
    loading: false,
    error: action.error
	});
};

// const catalogCrudSuccess = ( state, action ) => {
// 	return updateObject( state, {
//     loading: false,
// 		touched: false,
//     error: null,
//     id: action.id,
//     data: empty(action.data) ? {} : action.data
// 	} );
// };

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.INIT_CATALOG_FORM: return initCatalogForm( state, action );
    case actionTypes.SET_CATALOG_STATE: return setCatalogState( state, action );
    case actionTypes.CATALOG_START: return catalogStart( state, action );
    case actionTypes.CATALOG_FAIL: return catalogFail( state, action );
    // case actionTypes.CATALOG_CRUD_SUCCESS: return catalogCrudSuccess( state, action )
    default: return state;
  }
};

export default reducer;