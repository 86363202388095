import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { createMuiTheme } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';


const initialState = {
	loading: true,
    open: true,
    drawerDisabled: false,
    fixedHeightPaper: null,
    colors: null,
    theme: null
};

const layoutInit = ( state, action ) => {
	const theme = createMuiTheme({
		palette: {
			// type: 'dark',
			primary: {
				main: '#424242',
				// main: '#fff',
			},
			secondary: {
				main: '#ff1744',
				// main: '#4fc3f7',
				
			},
		},
	}, esES);

	return updateObject( state, { 
		loading: action.loading,
		open: action.open,
		theme: theme
	} );
};

const openDrawer = ( state, action ) => {
    return updateObject( state, { open: true } );
};

const closeDrawer = ( state, action ) => {
    return updateObject( state, { open: false } );
};

const enableDrawer = ( state, action ) => {
    return updateObject( state, { drawerDisabled: false } );
};

const disableDrawer = ( state, action ) => {
    return updateObject( state, { drawerDisabled: true } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.LAYOUT_INIT: return layoutInit( state, action );
        case actionTypes.OPEN_DRAWER: return openDrawer( state, action );
        case actionTypes.CLOSE_DRAWER: return closeDrawer( state, action );
        case actionTypes.ENABLE_DRAWER: return enableDrawer( state, action );
        case actionTypes.DISABLE_DRAWER: return disableDrawer( state, action );
        default: return state;
    }
};

export default reducer;