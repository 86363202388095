import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import config from '../../../../config';

const PremiumPaymentForms = (props) => {
  console.log('Premium ayment forms rendering!');
  return (
    <React.Fragment>
      <Typography variant="h5" className={props.materialClasses.sectionMarginBottom}>
        Formas de pago premium
      </Typography>
      <Grid item container xs={12} md={12} spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Paper className={props.materialClasses.paper}>
            <Typography variant="subtitle1" gutterBottom style={{ marginTop: '0.35em' }}>
              Si desea integrar una de estas pasarelas de pago en su tienda, envíe una solicitud de activación al correo <strong>{config.ecoSupportEmail}</strong>, 
              cada pasarela de pago se habilita con sus credenciales, por lo que usted debe contratar por separado cada servicio. El costo adicional para el activación por pasarela pago es de $75.00.
            </Typography>
            <Grid item container>
              <Grid item xs={12} md={6} style={{padding: '15px'}}>
                <img src='https://storage.googleapis.com/decimala-main-bucket/organizations/4/paypal.jpg' style={{height: 'auto', width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={6} style={{padding: '15px'}}>
                <img src='https://storage.googleapis.com/decimala-main-bucket/organizations/4/pagadito.jpeg' style={{height: 'auto', width:'100%'}} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PremiumPaymentForms;
