import React from 'react';
import {useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import ImageGallery from '../../../../components/Media/ImageGallery/ImageGallery';
import * as actions from '../../../../store/actions/index';
import { empty } from '../../../../shared/utility';

const Media = (props) => {
  const tileData = useSelector(state => state.setting.settings.mainPageGallery);
  const bannerWidth = useSelector(state => state.setting.settings.bannerWidth);
  const dispatch = useDispatch();
  const onSetSettings = (settings) => dispatch(actions.setSettings(settings));
  const onSetImageAsPrimary = (id) => {
    const updatedTileData = [...tileData];

    for ( let tile in updatedTileData )
    {
      updatedTileData[tile]['primary'] = false;

      if(updatedTileData[tile]['id'] === id)
      {
        updatedTileData[tile]['primary'] = true;
      }
    }

    onSetSettings({ mainPageGallery: updatedTileData });    
  };
  const onDisassociatesImage = (id, url) => {
    const updatedTileData = [];
    let isPrimaryDeleted = false;

    for ( let tile in tileData )
    {
      if(tileData[tile]['id'] === id && tileData[tile]['primary'] === true)
      {
        isPrimaryDeleted = true;
      }

      if(tileData[tile]['id'] !== id)
      {
        updatedTileData.push(tileData[tile]);
      }
    }

    if(isPrimaryDeleted && !empty(updatedTileData))
    {
      updatedTileData[0]['primary'] = true;
    }

    onSetSettings({ mainPageGallery: updatedTileData });
  }
  // const sortTileData = () => {
  //   const sortedTileData = [...tileData];

    // sortedTileData.sort(function (a, b) 
    // {
    //   if(b.primary) return 1;
    //   if(a.primary) return -1;
    //   return 0;
    // });

  //   console.log(sortedTileData);

  //   return sortedTileData;
  // }
  console.log('Media rendering!');
  return (
    <Grid item container xs={12} md={12} spacing={2}>
      <Grid item xs={12}>
        <Box display="flex" className={props.materialClasses.sectionMarginBottom}>
          <Box flexGrow={1}>
            <Typography variant="h5">
              Carrusel en página de inicio (tabletas y escritorio)
            </Typography>
          </Box>
          <Box>
            <Button color="primary" startIcon={<CloudUploadIcon />} onClick={() => props.setUploading(true)}>Subir imagen</Button>
          </Box>
        </Box>
        <Divider />
      </Grid>
      <Grid item xs={12}>
      <Paper className={props.materialClasses.paper} style={{ paddingTop: 0 }}>
        <Typography variant="subtitle1" gutterBottom style={{ marginTop: '0.35em' }}>
          El ancho mínimo de las imágenes debe ser de { bannerWidth }px, adicionalmente se recomienda que el alto no sea menor a 400px y que todas las imágenes del carrusel tengan las mismas dimensiones.
        </Typography>
        <ImageGallery tileData={tileData} setImageAsPrimary={onSetImageAsPrimary} disassociatesImage={onDisassociatesImage}/>
      </Paper>
      </Grid>
    </Grid>
  )
}

export default Media;