import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Editor } from '@tinymce/tinymce-react';
import * as actions from '../../../../store/actions/index';
import { empty, updateObject } from '../../../../shared/utility';
import config from '../../../../config';

const PaymentForms = (props) => {
  const paymentForms = useSelector(state => state.setting.settings.paymentForms);
  const dispatch = useDispatch();
  const onSetSettings = (settings) => dispatch(actions.setSettings(settings));
  const switchChangeHandler = (event) => {
    const updatedPaymentForms = updateObject( paymentForms, {} );
    updatedPaymentForms[event.target.name]['isEnabled'] = event.target.checked;
    onSetSettings({ paymentForms: updatedPaymentForms });
  };
  const inputChangeHandler = (event) => {
    const updatedPaymentForms = updateObject( paymentForms, {} );
    updatedPaymentForms[event.target.name]['label'] = event.target.value;
    if(empty(event.target.value)) updatedPaymentForms[event.target.name]['hasError'] = true;
    else updatedPaymentForms[event.target.name]['hasError'] = false;
    onSetSettings({ paymentForms: updatedPaymentForms });
  };
  const editorChangeHandler = (content, editor) => {
    // console.log(editor);
    // console.log(editor.targetElm.name);
    const updatedPaymentForms = updateObject( paymentForms, {} );
    updatedPaymentForms[editor.targetElm.name]['instructions'] = content;
    onSetSettings({ paymentForms: updatedPaymentForms });
  }
  console.log('Payment forms rendering!');
  return (
    <React.Fragment>
      <Typography variant="h5" className={props.materialClasses.sectionMarginBottom}>
        Formas de pago
      </Typography>
      <Grid item container xs={12} md={12} spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid item container spacing={2}>
            { 
              Object.values(paymentForms)
                .filter(paymentForm => !paymentForm.isPremium)
                .map( (paymentForm) => (
                <Grid key={'grid' + paymentForm.id} item xs={12} sm={6}>
                  <Paper className={props.materialClasses.paper}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Switch checked={ paymentForms[paymentForm.id]['isEnabled'] } onChange={switchChangeHandler} name={ paymentForms[paymentForm.id]['id'] } />}
                          label="Habilitada"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          error={ paymentForms[paymentForm.id]['hasError'] }
                          disabled={ !paymentForms[paymentForm.id]['isEnabled'] }
                          label="Etiqueta"
                          margin="normal"
                          name={ paymentForms[paymentForm.id]['id'] }
                          value={ paymentForms[paymentForm.id]['label'] }
                          onChange={ inputChangeHandler }
                        />
                      </Grid>
                      {
                        paymentForms[paymentForm.id]['isEnabled']
                          ? <React.Fragment>
                              <Grid item xs={12}>
                                <Typography component="legend" className="MuiFormLabel-root">
                                  Instrucciones de pago
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Editor
                                  apiKey={ config.editorKey }
                                  // disabled={ !paymentForms[paymentForm.id]['isEnabled'] }
                                  // value={ !empty(paymentForms[paymentForm.id]['instructions']) ? paymentForms[paymentForm.id]['instructions'] : getDefaultPaymentMessage(paymentForms[paymentForm.id]['id']) }
                                  value={ paymentForms[paymentForm.id]['instructions'] }
                                  textareaName={ paymentForms[paymentForm.id]['id'] }
                                  init={{
                                    height: 200,
                                    menubar: false,
                                    toolbar_mode: 'wrap',
                                    plugins: [
                                      'advlist autolink lists link image charmap print preview anchor',
                                      'searchreplace visualblocks code fullscreen',
                                      'insertdatetime media table paste code help wordcount emoticons'
                                    ],
                                    toolbar:
                                      'undo redo | bold italic | outdent indent | numlist bullist'
                                  }}
                                  onEditorChange={editorChangeHandler}
                                />
                              </Grid>
                            </React.Fragment>
                          : null
                      }
                    </Grid>
                  </Paper>
                </Grid> 
              ) ) 
            }
          </Grid> 
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PaymentForms;
