import React from 'react';
import {useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import MaterialTable from "material-table";

import * as actions from '../../../../store/actions/index';
import { empty} from '../../../../shared/utility';

const ExistingVariants = (props) => {
  const id = useSelector(state => state.article.data.id);
  const existingVariants = useSelector(state => state.article.existingVariants);
  const dispatch = useDispatch();
  const onSetExistingVariants = (existingVariants) => dispatch(actions.setExistingVariants(existingVariants));
  let existingVariantsContainer = null;
  
  console.log('Existing variant rendering!');

  if(!empty(id))
  {
    existingVariantsContainer = (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography component="h5" variant="h5">
            Variantes existentes
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            options={{
              toolbar:false,
              paging: false,
              headerStyle: {
                fontWeight:'bold',
                textAlign:'center',
                padding:'0.5rem'
              }
            }}
            columns={[
              { field: 'id', hidden: true },
              { field: 'name', hidden: true },
              { title: 'Variante', field: 'variant'},
              { title: 'Código', field: 'internal_reference'},
              { title: 'Precio', field: 'retail_price', type: 'numeric'},
              { title: 'Existencia reservada', field: 'reserved', type: 'numeric', editable: 'never'},
              { title: 'Existencia disponible', field: 'available_balance', type: 'numeric', editable: 'never'},
              { title: 'Costo', field: 'cost', type: 'numeric', editable: 'never'},
              { title: 'Aplica descuento', field: 'is_discount_applicable', type: 'boolean'},
              { title: 'Vender sin stock', field: 'is_always_available', type: 'boolean'},
              { title: 'Visible', field: 'is_visible_in_store', type: 'boolean'},
            ]}
            data={existingVariants}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data = [...existingVariants];
                  const index = data.indexOf(oldData);
                  data[index] = newData;
                  onSetExistingVariants(data);
                  resolve();
                })
            }}
          />
        </Grid>
      </React.Fragment>
    );
  }

  return existingVariantsContainer;
}

export default ExistingVariants;
