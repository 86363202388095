import * as actionTypes from './actionTypes';
import axios from '../../axios-decima';
import { empty, fireToast, fireMessage, fireErrorMessage } from '../../shared/utility';

export const initOrderForm = (id, data) => {
	return {
		type: actionTypes.INIT_ORDER_FORM,
		id: id,
		data: data,
	};
};

export const setOrderState = ( state ) => {
	return {
		type: actionTypes.SET_ORDER_STATE,
		state: state
	};
};

export const orderStart = () => {
	return {
		type: actionTypes.ORDER_START
	};
};

export const orderFail = ( error ) => {
	return {
		type: actionTypes.ORDER_FAIL,
		error: error
	};
}

// export const createArticleSuccess = ( groups, untouchedGroups, brands, untouchedBrands, existingVariants, data ) => {
// export const orderCrudSuccess = ( id ) => {
export const orderCrudSuccess = ( ) => {
	return {
		type: actionTypes.ORDER_CRUD_SUCCESS,
		// id: id,
	};
};

export const crudeOrder = ( data, token ) => {
	return dispatch => {
		let url = '/api/cms/createOrder';
		let edit = false;
		if(!empty(data.id))
		{
			url = '/api/cms/updateOrder';
			edit = true;
		}
		// if(!empty(data.id) && (data.status === 'C' || data.status === 'G'))
		// if(!empty(data.id) && data.status === 'C')
		// {
		// 	url = '/api/cms/cancelOrder';
		// 	edit = true;
		// }
		dispatch( orderStart() );
		axios.post( url + '?token=' + token, data )
			.then( response => {
				if(!empty( response.data.info ))
				{
					fireMessage({
						text: response.data.info
					});

					dispatch( orderFail( response.data.info ) );
				}
				else if(!empty( response.data.success ))
				{
					if(!empty( response.data.message ))
					{
						fireMessage({
							icon: 'success',
							message: '',
							html: response.data.message
						});
					}
					else
					{
						fireToast( response.data.success );
					}

					dispatch(
						orderCrudSuccess()
						// orderCrudSuccess( response.data.id )
					);
				}
				else
				{
					fireErrorMessage();

					dispatch( orderFail( 'Unrecognized server message' ) );
				}
				
			} )
			.catch( error => {
				dispatch( orderFail( error ) );
			} );
	};
};

