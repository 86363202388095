import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { isMobileOnly } from 'react-device-detect';
import SubscriptionTypes from '../SubscriptionTypes/SubscriptionTypes';
import Copyright from '../../Footer/Copyright/Copyright';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: isMobileOnly ? theme.spacing(0, 0, 6) : theme.spacing(8, 0, 6),
  }
}));

const PlansPage = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Precios
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
          {/* Elige la modalidad de pago que más te convenga, el contrato del servicio no le exige un tiempo mínimo de contración, puede cancelarlo cuando se venza la última cuota pagada. */}
          Elige la modalidad de pago que más te convenga, puede cancelar el servicio cuando se venza la última cuota pagada.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <SubscriptionTypes selectedSubscription='' {...props}/>
        <Grid item xs={12}>
          <div style={{marginTop: '20px'}}>
            <Copyright />
          </div>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default PlansPage;
