import React from 'react'

const Inventory = (props) => {
  return (
    <div>
      ¡Muy pronto, estará habilitado!
    </div>
  )
}

export default Inventory
