import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable from "material-table";
import { parse } from 'date-fns'
import CustomMaterialTableToolbar from '../../../../components/UI/CustomMaterialTableToolbar/CustomMaterialTableToolbar';
import * as actions from '../../../../store/actions/index';
import { empty, updateObject } from '../../../../shared/utility';
import axios from '../../../../axios-decima';

const useStyles = makeStyles(theme => ({
  paper: {
  },
}));

const Table = (props) => {
  const materialClasses = useStyles();
  const [useGlobalPage, setUseGlobalPage] = useState(true);
  // const isFormVisible = useSelector(state => state.coupon.isFormVisible);
  // const redirectToForm = useSelector(state => state.coupon.redirectToForm);
  const page = useSelector(state => state.coupon.page);
  const tableFilter = useSelector(state => state.coupon.tableFilter);
  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();
  const onInitForm = (id, data) => dispatch(actions.initCouponForm(id, data));
  const onSetState = (state) => dispatch(actions.setCouponState(state));
  // useEffect(() => {
  //   if(!empty(isFormVisible) && redirectToForm)
  //   {
  //     props.setFormVisible(true);
  //   }
  // }, [isFormVisible, redirectToForm, props]);
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h4">
              Cupones
            </Typography>
          </Box>
          <Box>
            {/* <Button variant="contained" color="primary" onClick={() => test()}> */}
            <Button variant="contained" color="primary" onClick={() => onInitForm(null, null)} startIcon={<AddIcon />}>
              Nuevo
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          title=''
          options={{
            pageSize: 10,
            pageSizeOptions: [5, 10, 20, 30, 40, 50],
            search: false,
            searchText: tableFilter,
            // dateSetting: { locale: esES },
            headerStyle: {
              fontWeight:'bold',
              textAlign:'center',
              padding:'0.5rem'
            }
          }}
          columns={[
            { title: 'Código', field: 'code' },
            { title: 'Nombre', field: 'name' },
            { title: 'Tipo', field: 'retail_price'},
            { title: 'Descuento', field: 'value_label', cellStyle:{textAlign:'center'} },
            // { title: 'Máx. núm. de uso', field: 'discount_label', cellStyle:{textAlign:'center'}},
            { title: 'Máx. uso', field: 'maximum_number', type: 'numeric', cellStyle:{textAlign:'center'} },
            { title: 'Desde', field: 'from_date_formatted', cellStyle:{textAlign:'center'} },
            // render: row => <span>{ row["field_name"] /*formatting code here*/ }</span>
            { title: 'Hasta', field: 'to_date_formatted', cellStyle:{textAlign:'center'} },
            { title: 'Monto mínimo', field: 'min_amount', type: 'currency' },
            { title: 'Monto máximo', field: 'max_amount', type: 'currency' },
            { title: 'Envío gratis', field: 'is_free_delivery', type: 'boolean', cellStyle:{textAlign:'center'} },
            { title: 'Restringir promociones', field: 'is_promotion_restricted', type: 'boolean', cellStyle:{textAlign:'center'} },
            { title: 'Uno x cliente', field: 'is_client_restricted', type: 'boolean', cellStyle:{textAlign:'center'} },
            { title: 'Activo', field: 'is_active', type: 'boolean', cellStyle:{textAlign:'center'} },
            // { title: 'Oferta', field: 'offer_price', type: 'currency' },
            // { title: 'Stock reservado', field: 'reserved', type: 'numeric', cellStyle:{textAlign:'center'}},
          ]}
          data={query =>
            new Promise((resolve, reject) => {
              let currentPage = query.page;
              if(useGlobalPage) currentPage = page;
              let url = axios.defaults.baseURL + '/api/sales/getCouponSmtRows?';
              url += 'rows=' + query.pageSize;
              url += '&page=' + (query.page + 1);
              url += '&page=' + (currentPage + 1);
              url += '&filter=' + query.search;
              // url += '&filter=' + currentFilter;
              url += '&token=' + token;
              fetch(url)
                .then(response => response.json())
                .then(result => {
                  onSetState({
                    page: result.page - 1,
                    tableFilter: query.search
                  });
                  setUseGlobalPage(false);
                  resolve({
                    data: Object.values(result.rows),
                    page: result.page - 1,
                    totalCount: result.records,
                  })
                })
            })
          }
          actions={[
            {
              icon: 'edit',
              tooltip: 'Editar',
              onClick: (event, rowData) => {
                console.log(parse(rowData.from_date, 'yyyy-MM-dd', new Date()));
                console.log(new Date());
                onInitForm(rowData.id, {
                  code: rowData.code,
                  name: rowData.name,
                  value: rowData.value,
                  type: rowData.type,
                  fromDate: !empty(rowData.from_date) ? parse(rowData.from_date, 'yyyy-MM-dd', new Date()) : null,
                  toDate: !empty(rowData.to_date) ? parse(rowData.to_date, 'yyyy-MM-dd', new Date()) : null,
                  minAmount: rowData.min_amount,
                  maxAmount: rowData.max_amount,
                  maximumNumber: rowData.maximum_number,
                  isFreeDelivery: rowData.is_free_delivery,
                  isPromotionRestricted: rowData.is_promotion_restricted,
                  isOneTimeClientRestricted: rowData.is_one_time_client_restricted,
                  isClientRestricted: rowData.is_client_restricted,
                  isActive:rowData.is_active,
                });
              }
            }
          ]}
          components={{
            Toolbar: props => (
              <CustomMaterialTableToolbar {...props}/>
            ),
          }}
        />
      </Grid>
    </React.Fragment>
  )
}

export default Table;
