import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

const Copyright = (props) => {
  const materialClasses = useStyles();
  return (
    <div className={materialClasses.footer}>
      <p>
        <strong style={{fontSize: '14px'}}>© 2020 Insight Decima S.A. de C.V., todos los derechos reservados.</strong><br></br>
        <span style={{fontSize: '12px'}}>
          DecimaERP® y DecimaEco® son marcas registradas de Insight Decima S.A. de C.V.<br></br>
          Teléfono: +503 2289-9148, Correo electrónico: info@decima.la<br></br>
          Antiguo Cuscatlán, La Libertad, El Salvador
        </span>
      </p>
    </div>
  )
}

export default Copyright;
