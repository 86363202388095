import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import MaterialTable from "material-table";
import CustomMaterialTableToolbar from '../../../../components/UI/CustomMaterialTableToolbar/CustomMaterialTableToolbar';
import * as actions from '../../../../store/actions/index';
import { empty, updateObject } from '../../../../shared/utility';
import axios from '../../../../axios-decima';
import IncreaseStockDialog from './IncreaseStockDialog';
import DecreaseStockDialog from './DecreaseStockDialog';

const useStyles = makeStyles(theme => ({
  paper: {
  },
}));

const Table = (props) => {
  const materialClasses = useStyles();
  const [useGlobalPage, setUseGlobalPage] = useState(true);
  const isFormVisible = useSelector(state => state.article.isFormVisible);
  const redirectToForm = useSelector(state => state.article.redirectToForm);
  const untouchedGroups = useSelector(state => state.article.untouchedGroups);
  const untouchedBrands = useSelector(state => state.article.untouchedBrands);
  const articleTypes = useSelector(state => state.article.articleTypes);
  const unitMeasures = useSelector(state => state.article.unitMeasures);
  const page = useSelector(state => state.article.page);
  const tableFilter = useSelector(state => state.article.tableFilter);
  const token = useSelector(state => state.auth.token);
  const settings = useSelector(state => state.setting.settings);
  const dispatch = useDispatch();
  const onInitForm = (data, groups, groupsLevelTwo, groupsLevelThree, brands, tileData, existingVariants) => dispatch(actions.initForm(data, groups, groupsLevelTwo, groupsLevelThree, brands, tileData, existingVariants));
  const onSetArticleState = (state) => dispatch(actions.setArticleState(state));

  // console.log('useGlobalPage');
  // console.log(useGlobalPage);
  
  useEffect(() => {
    if(!empty(isFormVisible) && redirectToForm)
    {
      props.setFormVisible(true)
    }
  }, [isFormVisible, redirectToForm, props]);

  const test = () =>  {
    console.log('Hola');

    let url, authData;
    // url = '/api/cms/signInUser?key=qJE60M6Oiafjr7PMxV1Eu56I4gFMkxt4pQso34RTr57DMneL6REYDMLX3x7O';
    // url = '/api/cms/sendPasswordResetEmail?key=qJE60M6Oiafjr7PMxV1Eu56I4gFMkxt4pQso34RTr57DMneL6REYDMLX3x7O';
    // url = '/api/cms/validateRememberToken?key=qJE60M6Oiafjr7PMxV1Eu56I4gFMkxt4pQso34RTr57DMneL6REYDMLX3x7O';
    // url = '/api/cms/resetPassword?key=qJE60M6Oiafjr7PMxV1Eu56I4gFMkxt4pQso34RTr57DMneL6REYDMLX3x7O';
    // url = '/api/cms/getAllBlogPosts?key=qJE60M6Oiafjr7PMxV1Eu56I4gFMkxt4pQso34RTr57DMneL6REYDMLX3x7O';
    url = '/api/cms/getBlogPosts?key=qJE60M6Oiafjr7PMxV1Eu56I4gFMkxt4pQso34RTr57DMneL6REYDMLX3x7O';
    
    authData = {
      // username: 'mgallegos',
      // email: 'mgallegos@decimaerp.com',
      // password: 'secretPassword',
      // password_confirmation: 'secretPassword',
      // token: 'IYoiI4TZ3mcE2FQRuNf1MhW475ZO67JFr1vyphH5',
      "filter": '',
      "category": '',
      "rows": "2",
      "page": "1"
    }
		axios.post(url, authData)
			.then(response => {
				console.log('response');
				console.log(response);
			})
			.catch(err => {
				console.log('errr');
				console.log(err);
			});
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h4">
              Artículos
            </Typography>
          </Box>
          <Box>
            {/* <Button variant="contained" color="primary" onClick={() => test()}> */}
            <Button variant="contained" color="primary" onClick={() => onInitForm()} startIcon={<AddIcon />}>
              Nuevo
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <IncreaseStockDialog />
        <DecreaseStockDialog />
        <MaterialTable
            title=''
            options={{
              // toolbar:false,
              // paging: false,
              // actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: [5, 10, 20, 30, 40, 50],
              search: false,
              searchText: tableFilter,
              headerStyle: {
                fontWeight:'bold',
                textAlign:'center',
                padding:'0.5rem'
              }
            }}
            columns={[
              // { field: 'id', hidden: true },
              // { title: 'Código', field: 'internal_reference' },
              // { title: 'Nombre', field: 'name' },
              { title: "Imagen", field: "url", render: rowData => <img src={rowData.image_url} style={{width: 100}} alt=""/> },
              { title: 'Artículo', field: 'label' },
              { title: 'Marca', field: 'brand_name' },
              { title: 'Precio', field: 'retail_price', type: 'currency' },
              { title: 'Aplica descuento', field: 'is_discount_applicable', type: 'boolean', cellStyle:{textAlign:'center'}},
              { title: 'Descuento', field: 'discount_label', cellStyle:{textAlign:'center'}},
              { title: 'Oferta', field: 'offer_price', type: 'currency' },
              { title: 'Costo promedio', field: 'cost', type: 'currency' },
              { title: 'Stock reservado', field: 'reserved', type: 'numeric', cellStyle:{textAlign:'center'}},
              { title: 'Stock disponible', field: 'available_balance', type: 'numeric', cellStyle:{textAlign:'center'}},
              { title: 'Destacado', field: 'is_featured', type: 'boolean', cellStyle:{textAlign:'center'}},
              { title: 'Vender sin/stock', field: 'is_always_available', type: 'boolean', cellStyle:{textAlign:'center'}},
              { title: 'Visible', field: 'is_visible_in_store', type: 'boolean', cellStyle:{textAlign:'center'}},
              // { title: 'Costo', field: 'cost', type: 'numeric' },
              //description
              //groups
              //media
              //variants
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                let currentPage = query.page;
                if(useGlobalPage) currentPage = page;
                // let currentFilter = query.search;
                // if(useGlobalPage) 
                // {
                //   currentPage = page;
                //   currentFilter = tableFilter;
                // }
                let url = axios.defaults.baseURL + '/api/inventory/getEcoArticles?';
                url += 'rows=' + query.pageSize;
                url += '&page=' + (query.page + 1);
                url += '&page=' + (currentPage + 1);
                url += '&filter=' + query.search;
                // url += '&filter=' + currentFilter;
                url += '&token=' + token;
                fetch(url)
                  .then(response => response.json())
                  .then(result => {
                    onSetArticleState({
                      page: result.page - 1,
                      tableFilter: query.search
                    });
                    setUseGlobalPage(false);
                    resolve({
                      data: Object.values(result.rows),
                      page: result.page - 1,
                      totalCount: result.records,
                    })
                  })
              })
            }
            actions={[
              {
                icon: AddCircleOutlineIcon,
                tooltip: 'Aumentar stock',
                onClick: (event, rowData) => {
                  // console.log(rowData)
                  // setSelectedRow(rowData);
                  onSetArticleState({ isIncreaseStockDialogVisible: true });
                }
              },
              {
                icon: RemoveCircleOutlineIcon,
                tooltip: 'Disminuir stock',
                onClick: (event, rowData) => {
                  // console.log(rowData)
                  // setSelectedRow(rowData);
                  onSetArticleState({ isDecreaseStockDialogVisible: true });
                }
              },
              {
                icon: 'edit',
                tooltip: 'Editar',
                onClick: (event, rowData) => {
                  // console.log(event);
                  // console.log(rowData);
                  const groups = [];
                  let untouchedGroupsLevelTwo = [];
                  const groupsLevelTwo = [];
                  let untouchedGroupsLevelThree = [];
                  const groupsLevelThree = [];

                  for ( let group in untouchedGroups)
                  {
                    if(settings.isSingleCategoryEnabled)
                    {
                      if(untouchedGroups[group]['value'] === rowData.group_level_one_id)
                      {
                        groups.push(updateObject(untouchedGroups[group], { checked: true }));
                        untouchedGroupsLevelTwo = [...untouchedGroups[group]['subgroups']];
                      }
                      else
                      {
                        groups.push(updateObject(untouchedGroups[group], { checked: false }));
                      }  
                    }
                    else
                    {
                      if(rowData.groups.includes(untouchedGroups[group]['value']))
                      {
                        groups.push(updateObject(untouchedGroups[group], { checked: true }));
                      }
                      else
                      {
                        groups.push(updateObject(untouchedGroups[group], { checked: false }));
                      }  
                    } 
                  }
                  for ( let group in untouchedGroupsLevelTwo)
                  {
                    if(untouchedGroupsLevelTwo[group]['value'] === rowData.group_level_two_id)
                    {
                      groupsLevelTwo.push( updateObject( untouchedGroupsLevelTwo[group], { checked: true } ) );
                      untouchedGroupsLevelThree = [...untouchedGroupsLevelTwo[group]['subgroups']];
                    }
                    else
                    {
                      groupsLevelTwo.push(updateObject(untouchedGroupsLevelTwo[group], { checked: false }));
                    }
                  }
                  onInitForm({
                    id: rowData.value,
                    internalReference: rowData.internal_reference,
                    name: rowData.name,
                    retailPrice: rowData.retail_price,
                    isDiscountApplicable: rowData.is_discount_applicable == 1 ? true : false,
                    discount: rowData.discount,
                    discountType: rowData.discount_type,//K:Porcentake, J:Valor fijo
                    offerPrice: rowData.offer_price,
                    availableQuantity: rowData.available_balance,
                    cost: rowData.cost,
                    description: rowData.description,
                    tags: rowData.tags,
                    brand: !empty(rowData.brand_id) ? untouchedBrands['key' + rowData.brand_id] : null,
                    articleType: articleTypes['key' + rowData.article_type_id],
                    unitMeasure: unitMeasures['key' + rowData.unit_measure_id],
                    isFeatured: rowData.is_featured === 1 ? true : false,
                    isAlwaysAvailable: rowData.is_always_available === 1 ? true : false,
                    isVisibleInStore: rowData.is_visible_in_store === 1 ? true : false,
                  }, groups, groupsLevelTwo, groupsLevelThree, Object.values(untouchedBrands), rowData.gallery, rowData.variants);
                }
              },
            ]}
            components={{
              Toolbar: props => (
                <CustomMaterialTableToolbar {...props}/>
              ),
            }}
          />
      </Grid>
    </React.Fragment>
  )
}

export default Table;
