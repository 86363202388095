import React, { useState, useEffect, useCallback } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import * as actions from '../../../../store/actions/index';
import { updateObject, empty } from '../../../../shared/utility';

const Categories = (props) => {
  const [addingGroup, setAddingGroup] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [groupTempId, setGroupTempId] = useState(10000);
  const groups = useSelector(state => state.article.groups);
  const groupsLevelTwo = useSelector(state => state.article.groupsLevelTwo);
  const groupsLevelThree = useSelector(state => state.article.groupsLevelThree);
  const settings = useSelector(state => state.setting.settings);
  const dispatch = useDispatch();
  const onsetGroups = (groups, groupsLevelTwo, groupsLevelThree) => dispatch( actions.setGroups(groups, groupsLevelTwo, groupsLevelThree) );

  const saveGroupHandler = (event) => { 
    if(empty(groupName))
    {
      setAddingGroup(false);
      return;
    }

    const updatedGroups = [...groups];

    updatedGroups.push({
      value: groupTempId, 
      label: groupName, 
      checked: true,
      isNewGroup: true
    });
    
    setAddingGroup(false);
    setGroupName('');
    setGroupTempId(groupTempId+1);
    onsetGroups(updatedGroups, groupsLevelTwo, groupsLevelThree);
  };

  const categoryLevelOneListItemToggleHandler = (value) => () => {
    let updatedGroups = [];
    let updatedGroupsLevelTwo = [];
    let updatedGroup = null;

    for ( let group in groups )
    {
      if(groups[group]['value'] === value)
      {
        updatedGroup = updateObject(groups[group], { checked: !groups[group]['checked'] });
        updatedGroupsLevelTwo = [...groups[group]['subgroups']];
      }
      else
      { 
        if(settings.isSingleCategoryEnabled) updatedGroup = updateObject( groups[group], { checked: false } );
        else updatedGroup = updateObject( groups[group], {} );
      }
      
      updatedGroups.push( updatedGroup );
    }
    
    onsetGroups(updatedGroups, updatedGroupsLevelTwo, groupsLevelThree);
  };

  const categoryLevelTwoListItemToggleHandler = (value) => () => {
    let updatedGroupsLevelTwo = [];
    let updatedGroupsLevelThree = [];
    let updatedGroup = null;

    for ( let group in groupsLevelTwo )
    {
      if(groupsLevelTwo[group]['value'] === value)
      {
        updatedGroup = updateObject(groupsLevelTwo[group], { checked: !groupsLevelTwo[group]['checked'] });
        updatedGroupsLevelThree = [...groupsLevelTwo[group]['subgroups']];
      }
      else
      { 
        if(settings.isSingleCategoryEnabled) updatedGroup = updateObject( groupsLevelTwo[group], { checked: false } );
        else updatedGroup = updateObject( groupsLevelTwo[group], {} );
      }
      
      updatedGroupsLevelTwo.push( updatedGroup );
    }
    
    onsetGroups(groups, updatedGroupsLevelTwo, updatedGroupsLevelThree);
  };

  const categoryLevelOneListItemDeleteHandler = (value) => () => {
    let updatedGroups = [];
    let updatedGroupsLevelTwo = [...groupsLevelTwo];
    let updatedGroup = null;

    for ( let group in groups )
    {
      if(groups[group]['value'] === value)
      {
        updatedGroup = updateObject(groups[group], { isGroupDeleted: true });
        if( groups[group]['checked'] ) updatedGroupsLevelTwo = [];
      }
      else
      { 
        updatedGroup = updateObject(groups[group], {});
      }
      
      updatedGroups.push(updatedGroup);
    }
    
    onsetGroups(updatedGroups, updatedGroupsLevelTwo, groupsLevelThree);
  };

  const categoryLevelTwoListItemDeleteHandler = (value) => () => {
    let updatedGroupsLevelTwo = [];
    let updatedGroupsLevelThree = [...groupsLevelThree];
    let updatedGroup = null;

    for ( let group in groupsLevelTwo )
    {
      if(groupsLevelTwo[group]['value'] === value)
      {
        updatedGroup = updateObject(groupsLevelTwo[group], { isGroupDeleted: true });
        if( groupsLevelTwo[group]['checked'] ) updatedGroupsLevelThree = [];
      }
      else
      { 
        updatedGroup = updateObject(groupsLevelTwo[group], {});
      }
      
      updatedGroupsLevelTwo.push(updatedGroup);
    }
    
    onsetGroups(groups, updatedGroupsLevelTwo, updatedGroupsLevelThree);
  };

  console.log('Categories rendering!');

  return (
    <React.Fragment>
      <Paper className={[props.materialClasses.paper, props.materialClasses.mgPaper].join(' ')}>
        <List subheader={
          settings.isSingleCategoryEnabled 
            ? <ListSubheader>Categorías nivel 1</ListSubheader>
            : <ListSubheader>Categorías</ListSubheader>
          }
        >
          {
            groups.filter(group => empty(group.isGroupDeleted)).map(group => {
              const labelId = `checkbox-list-label-${group.value}`;
              return (
                <ListItem key={group.value} role={undefined} dense button onClick={ categoryLevelOneListItemToggleHandler(group.value)}>
                  <ListItemIcon>
                    {
                      ( !empty(settings) && settings.isSingleCategoryEnabled )
                        ? <Radio
                            edge="start"
                            checked={group.checked}
                            tabIndex={-1}
                            disableRipple
                            name="radio-category-level-one"
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        : <Checkbox
                            edge="start"
                            checked={group.checked}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                    }
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={group.label} />
                  {
                    !settings.isSingleCategoryEnabled
                      ? <ListItemSecondaryAction onClick={ categoryLevelOneListItemDeleteHandler(group.value) }>
                          <IconButton edge="end" aria-label="groups">
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      : null
                  }
                </ListItem>
              );
            })
          }
          {
            addingGroup 
            ? <ListItem role={undefined} dense button>
                <ListItemIcon>
                  <TextField name="groupName" label="Categoría" value={groupName} onChange={(event) => setGroupName(event.target.value)} autoFocus/>
                </ListItemIcon>
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="groups" onClick={saveGroupHandler}>
                    <SaveIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            : null
           }
        </List>
        {
          !settings.isSingleCategoryEnabled
            ? <div className={props.materialClasses.elementsContainer}>
                {
                  ! addingGroup 
                    ? <Button color="primary" className={props.materialClasses.button} startIcon={<AddIcon />} onClick={() => setAddingGroup(true)}>Nueva categoría</Button>
                    : null
                }
              </div>
            : null
        }
        {
          settings.isSingleCategoryEnabled
            ? <List subheader={<ListSubheader>Categorías nivel 2</ListSubheader>}>
              {
                groupsLevelTwo.filter(group => empty(group.isGroupDeleted)).map(group => {
                  const labelId = `checkbox-list-label-${group.value}`;
                  return (
                    <ListItem key={group.value} role={undefined} dense button onClick={ categoryLevelTwoListItemToggleHandler(group.value)}>
                      <ListItemIcon>
                        {
                          ( !empty(settings) && settings.isSingleCategoryEnabled )
                            ? <Radio
                                edge="start"
                                checked={group.checked}
                                tabIndex={-1}
                                disableRipple
                                name="radio-category-level-two"
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            : <Checkbox
                                edge="start"
                                checked={group.checked}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                        }
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={group.label} />
                      {/* <ListItemSecondaryAction onClick={ categoryLevelTwoListItemDeleteHandler(group.value) }>
                        <IconButton edge="end" aria-label="groups">
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction> */}
                    </ListItem>
                  );
                })
              }
            </List>
            : null
        }
      </Paper>
    </React.Fragment>
  )
}

export default Categories;
