let config;

if(process.env.NODE_ENV === 'development')
{
	config = {
		apiKey: process.env.REACT_APP_API_KEY,
		editorKey: process.env.REACT_APP_TINY_MCE_KEY,
		apiUrl: process.env.REACT_APP_DEV_API_URL,
		ecoSupportEmail: process.env.REACT_APP_SUPPORT_EMAIL,
		demoToken: process.env.REACT_APP_DEMO_TOKEN,
		demoOrganizationId: process.env.REACT_APP_DEMO_ORGANIZATION_ID,
		firebaseUrl: process.env.REACT_APP_FIREBASE_URL,
	};
}
else
{
	config = {
		apiKey: process.env.REACT_APP_API_KEY,
		editorKey: process.env.TINY_MCE_KEY,
		apiUrl: process.env.REACT_APP_DEV_PROD_URL,
		ecoSupportEmail: process.env.REACT_APP_SUPPORT_EMAIL,
		demoToken: process.env.REACT_APP_DEMO_TOKEN,
		demoOrganizationId: process.env.REACT_APP_DEMO_ORGANIZATION_ID,
		firebaseUrl: process.env.REACT_APP_FIREBASE_URL,
	};
}

export default config;
