import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const updateObject = ( oldObject, updatedProperties ) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const fireToast = ( title = '', icon = 'success', properties = {} ) => {
		const MySwal = withReactContent(Swal);

    const toast = MySwal.mixin(updateObject({    
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: true,
			onOpen: (toast) => {
					toast.addEventListener('mouseenter', MySwal.stopTimer)
					toast.addEventListener('mouseleave', MySwal.resumeTimer)
			}
		}, 
		properties
		));

		toast.fire({
			icon: icon,
			title: title
		});
};

export const fireMessage = ( properties = {} ) => {
		const MySwal = withReactContent(Swal);

		MySwal.fire(updateObject({
      icon: 'info',
      title: '',
			text: '',
			customClass:{
				title: 'decima-swall-font-size-small-title',
				content: 'decima-swall-font-size-small-content',
				cancelButton: 'decima-swall-custom-btn-padding',
				// confirmButton: 'btn btn-success'
			},
		},
		properties
		));
};

export const fireErrorMessage = ( properties = {} ) => {
	return fireMessage({
    icon: 'error',
    title: 'Oops...',
		text: 'Su petición no ha podido ser procesada, por favor intente de nuevo más tarde.'
	});
};

/**
 * JavaScript equivalent to PHP's empty
 *
 * @param mixed mixed_var
 *
 * @returns boolean
 */
export const empty = ( mixed_var ) => {
  //   example 1: empty(null);
  //   returns 1: true
  //   example 2: empty(undefined);
  //   returns 2: true
  //   example 3: empty([]);
  //   returns 3: true
  //   example 4: empty({});
  //   returns 4: true
  //   example 5: empty({'aFunc' : function () { alert('humpty'); } });
  //   returns 5: false

  let undef, key, i, len;
  let emptyValues = [undef, null, false, 0, '', '0', '0.0', '0.00', '0.000', '0.0000', '0.00000', '0.000000'];

  for (i = 0, len = emptyValues.length; i < len; i++)
  {
    if (mixed_var === emptyValues[i])
	{
      return true;
    }
  }

  if (typeof mixed_var === 'object')
  {
    for (key in mixed_var)
    {
      // TODO: should we check for own properties only?
      //if (mixed_var.hasOwnProperty(key)) {
      return false;
      //}
    }

    return true;
  }

  return false;
}

export const round = ( value, decimals ) => {
  return Number(Math.round(parseFloat(value) + 'e' + decimals) + 'e-' + decimals);
}

export const ccyFormat = (num) => {
  return `${num.toFixed(2)}`;
}

export const makeid = (length) => {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for ( let i = 0; i < length; i++ )
  {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const isDemo = ( isAuthenticated ) => {

  if(!isAuthenticated)
  {
    fireMessage({
      text: 'Gracias por su interés en conocer nuestra plataforma, se encuentra en una versión demo y no se pueden efectuar cambios.'
    });

    return true;
  }

  return false;
}

