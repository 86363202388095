import React, { useEffect, useCallback } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';
import SaveIcon from '@material-ui/icons/Save';
import * as actions from '../../../../store/actions/index';
import { empty, isDemo, fireMessage } from '../../../../shared/utility';

const ActionButtons = (props) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const organizationId = useSelector(state => state.auth.organizationId);
  const touched = useSelector(state => state.article.touched);
  const isFormVisible = useSelector(state => state.article.isFormVisible);
  const formData = useSelector(state => state.article.data);
  const defaultData = useSelector(state => state.article.defaultData);
  const newVariants = useSelector(state => state.article.newVariants);
  const existingVariants = useSelector(state => state.article.existingVariants);
  const tileData = useSelector(state => state.article.tileData);
  const groups = useSelector(state => state.article.groups);
  const groupsLevelTwo = useSelector(state => state.article.groupsLevelTwo);
  const groupsLevelThree = useSelector(state => state.article.groupsLevelThree);
  const articleTypes = useSelector(state => state.article.articleTypes);
  const unitMeasures = useSelector(state => state.article.unitMeasures);
  const token = useSelector(state => state.auth.token);
  const settings = useSelector(state => state.setting.settings);
  const dispatch = useDispatch();
  const onCrudArticle = (data, token, settings) => dispatch(actions.crudeArticle(data, token, settings));
  const onInitForm = () => dispatch(actions.initForm());
  const onReleaseForm = () => dispatch(actions.releaseForm());

  useEffect(() => {
    if(empty(isFormVisible))
    {
      props.setFormVisible(false);
    }
  }, [isFormVisible, props]);

  const discardChanges = () => {
    onInitForm();
    setTimeout(() => {
      onInitForm();
    }, 500);
  }

  const saveHandler = (formData) => {
    let articleGroups = [];
    let newGroups = [];
    let newLevelOneGroups = [];
    let newLevelTwoGroups = [];
    let newLevelThreeGroups = [];
    let deletedGroups = [];
    let gallery = [];
    let storeIds = [];
    let imageUrl = null;
    let imageLargeUrl = null;
    let imageMediumUrl = null;
    let imageSmallUrl = null;
    let groupLevelOneId = '';
    let groupLevelTwoId = '';
    let groupLevelThreeId = '';
    let unitMeasureDefaultKey = 'key1';

    if(isDemo(isAuthenticated))
    {
      return;
    }

    if(empty(formData.internalReference))
    {
      fireMessage({
        text: 'El campo código es requerido.'
      });

      return;
    }

    if(empty(formData.name))
    {
      fireMessage({
        text: 'El campo nombre es requerido.'
      });

      return;
    }

    if(empty(formData.retailPrice))
    {
      fireMessage({
        text: 'El campo código precio es requerido.'
      });

      return;
    }

    for ( let group in groups )
    {
      if(groups[group]['checked'] === true && empty(groups[group]['isNewGroup']) && empty(groups[group]['isGroupDeleted']))
      {
        if(settings.isSingleCategoryEnabled)
        {
          groupLevelOneId = groups[group]['value'];
        }
        else
        {
          articleGroups.push(groups[group]['value']);
        }
      }
      
      if(!empty(groups[group]['isNewGroup']))
      {
        if(settings.isSingleCategoryEnabled)
        {
          newLevelOneGroups.push(groups[group]['label']);
        }
        else
        {
          newGroups.push(groups[group]['label']);
        }
      }

      if(!empty(groups[group]['isGroupDeleted']))
      {
        deletedGroups.push(groups[group]['value']);
      }
    }

    for ( let group in groupsLevelTwo )
    {
      if(groupsLevelTwo[group]['checked'] === true)
      {
        groupLevelTwoId = groupsLevelTwo[group]['value'];
      }
    }

    for ( let tile in tileData )
    {
      if(tileData[tile]['primary'] === true)
      {
        imageUrl = tileData[tile]['image_url'];
        imageLargeUrl = tileData[tile]['image_url'];
        imageMediumUrl = tileData[tile]['image_url'];
        imageSmallUrl = tileData[tile]['image_url'];
      }
      else
      {
        gallery.push({
          'image_url': tileData[tile]['image_url'],
          'image_large_url': tileData[tile]['image_url'],
          'image_medium_url': tileData[tile]['image_url'],
          'image_small_url': tileData[tile]['image_url']
        });
      }
    }

    if(!settings.isNewEco && !empty(settings.ecoSubdomain))
    {
      storeIds.push(settings.ecoSubdomain);
    }

    if(!settings.isNewEco && !empty(settings.ownDomain))
    {
      storeIds.push(settings.ownDomain.replace(new RegExp('\\.', 'g'), ','));
    }

    if(organizationId === 10)
    {
      unitMeasureDefaultKey = 'key4';
    }

    onCrudArticle({
      'id': formData.id,
      'internal_reference': formData.internalReference,
      'name': formData.name,
      'retail_price': formData.retailPrice,
      'is_discount_applicable':formData.isDiscountApplicable,
      'discount': formData.discount,
      'discount_type': formData.discountType,
      'offer_price': formData.offerPrice,
      'available_quantity': formData.availableQuantity,
      'cost': formData.cost,
      'description': formData.description!==defaultData.description?formData.description:'',
      'tags': formData.tags,
      'is_featured': formData.isFeatured,
      'is_visible_in_store': formData.isVisibleInStore,
      'is_always_available': formData.isAlwaysAvailable,
      'variants': newVariants.variants,
      'existing_variants': existingVariants,
      'groups': articleGroups,
      'newGroups': newGroups,
      
      'deletedGroups': deletedGroups,
      'gallery': gallery,
      // 'unit_measure_id': (organizationId === 10) ? 4 : 1,
      'image_url': imageUrl,
      'image_large_url': imageLargeUrl,
      'image_medium_url': imageMediumUrl,
      'image_small_url': imageSmallUrl,
      
      'group_level_one_id': groupLevelOneId,
      'group_level_two_id': groupLevelTwoId,
      'group_level_three_id': groupLevelThreeId,
      
      'brand_id': !empty(formData.brand) ? formData.brand.value : '',
      'brand_label': !empty(formData.brand) ? formData.brand.label : '',
      
      // 'model_id': !empty(formData.model) ? formData.model.value : null,
      // 'model_label': !empty(formData.model) ? formData.model.label : null,

      'unit_measure_id': !empty(formData.unitMeasure) ? formData.unitMeasure.value : unitMeasures[unitMeasureDefaultKey]['value'],
      'unit_measure_label': !empty(formData.unitMeasure) ? formData.unitMeasure.label : unitMeasures[unitMeasureDefaultKey]['label'],

      'article_type_id': !empty(formData.articleType) ? formData.articleType.value : articleTypes['key1']['value'],
      'article_type_label': !empty(formData.articleType) ? formData.articleType.label : articleTypes['key1']['label'],

      'storeIds': storeIds,
      'validateStock': settings.validateStock,
      // 'isSingleCategoryEnabled': settings.isSingleCategoryEnabled,
      // 'is_discount_applicable': state.isDiscountApplicable,
    }, token, settings );
  };

  console.log('ActionButtons rendering!');
  return (
    <Paper className={props.materialClasses.paper}>
      <div className={props.materialClasses.elementsContainer}>
        {/* <Button variant="contained" color="secondary" className={props.materialClasses.button}  onClick={() => onReleaseForm()}> {touched?'Descartar cambios': 'Regresar'} </Button>
        <Button variant="contained" color="primary" className={props.materialClasses.button} onClick={() => saveHandler(formData)}>Guardar</Button> */}
      {/* orientation="vertical" change for mobile */}
      <ButtonGroup size="medium" aria-label="small outlined button group" orientation={(touched || (!touched && !empty(formData.id))?"vertical":"horizontal")} fullWidth={true}>
      {/* <ButtonGroup size="small" aria-label="small outlined button group"> */}
        <Button variant="contained" color="secondary"  startIcon={<UndoIcon />} onClick={() => onReleaseForm()}>Regresar</Button>
        {
          touched
            ? <Button variant="outlined" color="secondary" startIcon={<DeleteIcon />} onClick={() => discardChanges()}>Descartar cambios</Button>
            : null
        }
        <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={() => saveHandler(formData)}>Guardar</Button>
        {
          (!touched && !empty(formData.id))
            ? <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={() => discardChanges()}>Nuevo</Button>
            : null
        }
      </ButtonGroup>
      </div>
    </Paper>
  );
}

export default ActionButtons;
