import * as actionTypes from '../actions/actionTypes';
import { updateObject, empty } from '../../shared/utility';

const initialState = {
  loading: false,
  touched: false,
  isFormVisible: false,
  isDialogVisible: false,
  reloadTable: true,
  // redirectToForm: false,
  page: 0,
  tableFilter: '',
  error: null,
  id: null,
  data: {},
};

const initOrderForm = (state, action) => {
	return updateObject( state, { 
    touched: false,
		isFormVisible: true, 
    // redirectToForm: true, 
    error: null,
    id: action.id,
		data: empty(action.data) ? {} : action.data
	});
};

const setOrderState = (state, action) => {
	return updateObject( state, action.state );
};

const orderStart = ( state, action ) => {
	return updateObject( state, { loading: true } );
};

const orderFail = ( state, action ) => {
  return updateObject( state, { 
    loading: false,
    error: action.error
	});
};

const orderCrudSuccess = ( state, action ) => {
	return updateObject( state, {
    loading: false,
		touched: false,
    error: null,
    reloadTable: !state.reloadTable,
    isDialogVisible: false
    // id: action.id,
    // data: empty(action.data) ? {} : action.data
	} );
};

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.INIT_ORDER_FORM: return initOrderForm( state, action );
    case actionTypes.SET_ORDER_STATE: return setOrderState( state, action );
    case actionTypes.ORDER_START: return orderStart( state, action );
    case actionTypes.ORDER_FAIL: return orderFail( state, action );
    case actionTypes.ORDER_CRUD_SUCCESS: return orderCrudSuccess( state, action )
    default: return state;
  }
};

export default reducer;