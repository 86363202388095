import React, { useEffect, useCallback } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';
import SaveIcon from '@material-ui/icons/Save';
import * as actions from '../../../../store/actions/index';
import { updateObject, isDemo, fireMessage, empty } from '../../../../shared/utility';
import config from '../../../../config';

const ActionButtons = (props) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const organizationId = useSelector(state => state.auth.organizationId);
  const currencySymbol = useSelector(state => state.auth.currencySymbol);
  const token = useSelector(state => state.auth.token);
  const firebaseToken = useSelector(state => state.auth.firebaseToken);
  const groups = useSelector(state => state.article.groups);
  const settings = useSelector(state => state.setting.settings);
  const ecoSubdomains = useSelector(state => state.setting.ecoSubdomains);
  const dispatch = useDispatch();
  const onSaveSetting = (data, firebaseToken) => dispatch(actions.saveSetting(data, firebaseToken));
  const onFetchSettings = useCallback(
    (organizationId) => dispatch(actions.fetchSettings(organizationId)),
    [dispatch]
  );
  const onSaveSettingsSuccess = useCallback(
    () => dispatch(actions.saveSettingsSuccess()),
    [dispatch]
  );

  useEffect(() => {
    if(settings == null)
    {
      onFetchSettings(organizationId);
    }
    else
    {
      onSaveSettingsSuccess();
    }
  }, [onFetchSettings, onSaveSettingsSuccess, settings, organizationId]);

  const discardChanges = () => {
    // onInitForm();
    // setTimeout(() => {
    //   onInitForm({description: defaultData.description});
    // }, 500);
  }

  const saveHandler = () => {
    
    if(isDemo(isAuthenticated))
    {
      return;
    }
    
    if(isAuthenticated && settings.ecoSubdomain === 'demo' && parseInt(organizationId) !== parseInt(config.demoOrganizationId))
    {
      fireMessage({
        text: 'Debe elegir un nombre para el subdominio de su comercio en línea, y recuerde que una vez haya elegido un nombre, solo podrá cambiarlo a través de una solicitud por correo electrónico.'
      });

      return;
    }

    if(settings.isNewEco && ecoSubdomains.includes(settings.ecoSubdomain))
    {
      fireMessage({
        text: 'Ya existe en comercio registrado con ese subdominio, por favor por favor elija uno diferente.'
      });

      return;
    }

    //validar nombre

    // if(rowData.groups.includes(untouchedGroups[group]['value']))

    onSaveSetting(updateObject( settings, { 
      fixedShippingCost: !empty(settings.fixedShippingCost) ? parseFloat(settings.fixedShippingCost) : 0,
      categories: groups,
      organizationId: organizationId,
      currencySymbol: currencySymbol,
      isNewEco: false
    } ), firebaseToken );
  };

  console.log('ActionButtons rendering!');
  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography component="h4" variant="h4">
        Configuraciones
        </Typography>
      </Box>
      <Box>
        {/* <Button variant="contained" color="primary" onClick={() => test()}> */}
        <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={() => saveHandler()}>
          Guardar
        </Button>
      </Box>
    </Box>
  );
}

export default ActionButtons;
