import React, { useEffect, useCallback } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import {isMobileOnly} from 'react-device-detect';
import Spinner from '../../components/UI/Spinner/Spinner'
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';

import * as actions from '../../store/actions/index';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  }
}));

const Layout = props => {
  const materialClasses = useStyles();
  const dispatch = useDispatch();
  const open = useSelector(state => state.layout.open);
  const drawerDisabled = useSelector(state => state.layout.drawerDisabled);
  const loading = useSelector(state => state.layout.loading);
  const theme = useSelector(state => state.layout.theme);
  const name = useSelector(state => state.auth.name);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const onInitLayout = useCallback(
    (colors, open) => dispatch(actions.layoutInit(colors, open)),
    [dispatch]
  );
  const onOpenDrawer = () => dispatch(actions.openDrawer());
  const onLogout = () => dispatch(actions.logout());
  useEffect(() => {
    onInitLayout(false, !isMobileOnly);
  }, [onInitLayout]);
  const toolbarClickHandler = (url) => {
    if(url === '/auth')
    {
      props.history.replace(url);
    }
    else
    {
      props.history.push(url);
    }
  };
  let layout = <Spinner />;
  if(!loading)
  {
    layout = (
      <ThemeProvider theme={theme}>
        <div className={materialClasses.root}>
          <CssBaseline />
          <Toolbar
            isAuthenticated={isAuthenticated}
            name={name}
            open={open}
            drawerDisabled={drawerDisabled}
            handleDrawerOpen={onOpenDrawer}
            clicked={toolbarClickHandler}
            logout={onLogout}
          />
          {props.children} 
        </div>
      </ThemeProvider>
    );
  }

  return layout;
};

export default Layout;
