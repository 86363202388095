import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Dropzone from 'react-dropzone-uploader';
import MaterialTable from "material-table";
import CustomMaterialTableToolbar from '../../UI/CustomMaterialTableToolbar/CustomMaterialTableToolbar';
import { makeStyles } from '@material-ui/core/styles';
import axios from '../../../axios-decima';
import { empty } from '../../../shared/utility';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  mgPaper: {
    marginTop: 20,
  }
}));

const Uploader = (props) => {
  const materialClasses = useStyles();
  return (
    <React.Fragment>
      <Grid item xs={12} md={6}>
        <Typography component="h5" variant="h5">
          Subir imagen
        </Typography>
        <Paper className={[materialClasses.paper, materialClasses.mgPaper].join(' ')}>
          <Dropzone
            getUploadParams={props.getUploadParams}
            onChangeStatus={props.handleChangeStatus}
            // onSubmit={handleSubmit}
            accept="image/*"
            inputContent={(files, extra) => (extra.reject ? 'Solo se aceptan imágenes' : 'Haga clic aquí o arrastre una imagen')}
            SubmitButtonComponent={null}
            styles={{
              dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
              inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography component="h5" variant="h5">
          Asociar imagen existente
        </Typography>
        <div className={materialClasses.mgPaper}>
          <MaterialTable
            columns={[
              { title: "Imagen", field: "url", render: rowData => <img src={rowData.url} style={{width: 100}} alt=""/> },
              { title: "Nombre", field: "name", cellStyle: {width: '50%'}, headerStyle: {width: '50%'}},
              { title: "id", field: "id", type: "numeric", hidden:true},
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                // console.log(query.page);
                let url = axios.defaults.baseURL + props.url + '?';
                url += 'rows=' + query.pageSize;
                url += '&page=' + (query.page + 1);
                url += '&filter=' + query.search;
                url += '&token=' + props.token;
                if( !empty( props.imageWidth ) ) url += '&customWhereLikeData=' + props.imageWidth;
                // console.log(query);
                // let url = 'https://reqres.in/api/users?'
                // url += 'per_page=' + query.pageSize
                // url += '&page=' + (query.page + 1)
                fetch(url)
                  .then(response => response.json())
                  .then(result => {
                    // console.log(result.page);
                    // console.log(result.total);
                    resolve({
                      // data: result.data,
                      // page: result.page - 1,
                      // totalCount: result.total,
                      data: Object.values(result.rows),
                      page: result.page - 1,
                      totalCount: result.records,
                    })
                  })
              })
            }
            title="Imágenes"
            actions={[
              {
                icon: 'check',
                // iconProps: 'SaveIcon',
                // icon: <SaveIcon />,
                tooltip: 'Seleccionar imagen',
                onClick: (event, rowData) => {
                  props.associateImage(rowData.id, rowData.url);
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              search: false,
              // toolbar: toolbar
              // debounceInterval: 1000
            }}
            components={{
              Toolbar: props => (
                <CustomMaterialTableToolbar {...props}/>
              ),
            }}

          />
        </div>
      </Grid>       
    </React.Fragment>
  )
}

export default Uploader;
