import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import MaterialToolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import NotificationsIcon from '@material-ui/icons/Notifications';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { makeStyles } from '@material-ui/core/styles';
import { isMobileOnly } from 'react-device-detect';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: isMobileOnly ? 0 : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  name: {
    margin: theme.spacing(1, 0.5),
  },
}));

const Toolbar = ( props ) => {

  const materialClasses = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (isLogOut) => {
    setAnchorEl(null);

    if(isLogOut)
    {
      props.logout();
    }
  };

  let loginButton = <Button color="inherit" variant="outlined" className={materialClasses.link} onClick={() => props.clicked('/auth')}>Iniciar sesión</Button>;
  if(isMobileOnly) loginButton = <IconButton color="inherit" onClick={() => props.clicked('/auth')}>
    <VpnKeyIcon/>
  </IconButton>;

  return (
    <AppBar position="absolute" className={clsx(materialClasses.appBar, !props.drawerDisabled && props.open && materialClasses.appBarShift)}>
        <MaterialToolbar className={materialClasses.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            className={clsx(materialClasses.menuButton, props.open && materialClasses.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h4" color="inherit" noWrap className={materialClasses.title}>Decima</Typography>
          <nav>
            {/* https://material-ui.com/guides/composition/#link */}
            {/* <Link variant="button" color="inherit" className={materialClasses.link} component={RouterLink} to="/erp">
              DecimaERP
            </Link>
            <Link variant="button" color="inherit" className={materialClasses.link} component={RouterLink} to="/eco">
              DecimaShop
            </Link>
            <Link variant="button" color="inherit" className={materialClasses.link} component={RouterLink} to="/pos">
              DecimaPOS
            </Link>
            <Link variant="button" color="inherit" className={materialClasses.link} component={RouterLink} to="/dev">
              DecimaDev
            </Link> */}
          </nav>
          {
            !props.isAuthenticated
              ? loginButton
              : <div>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                    <Link variant="button" color="inherit" className={materialClasses.name}>
                      {props.name}
                    </Link>
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={open}
                    onClose={() => handleClose(false)}
                  >
                    {/* <MenuItem onClick={handleClose}>Mi per</MenuItem> */}
                    <MenuItem onClick={() => handleClose(true)}>Salir</MenuItem>
                  </Menu>
                </div>
          }
          <IconButton color="inherit" onClick={() => props.clicked('/')}>
            <HomeWorkIcon/>
          </IconButton> 
          <IconButton color="inherit">
            <Badge badgeContent={0} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </MaterialToolbar>
      </AppBar>
  )
}

export default Toolbar;
