import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/core/styles';
import { isMobileOnly } from 'react-device-detect';

const drawerWidth = isMobileOnly ? '100%' : 240;

const useStyles = makeStyles(theme => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
}));

const Sidebar = (props) => {

  const materialClasses = useStyles();

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(materialClasses.drawerPaper, !props.open && materialClasses.drawerPaperClose),
      }}
      open={props.open}
      style={ isMobileOnly && props.open ? {width: '100%'} : {}}
    >
      <div className={materialClasses.toolbarIcon}>
        <IconButton onClick={props.handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>{props.primarylistItems}</List>
      {
        props.secondaryListItems
        ? <><Divider /><List>{props.secondaryListItems}</List></>
        : null
      }
    </Drawer>
  )
}

export default Sidebar;
