import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from '../../Footer/Copyright/Copyright';
import { empty } from '../../../shared/utility';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  // appBar: {
  //   borderBottom: `1px solid ${theme.palette.divider}`,
  // },
  // toolbar: {
  //   flexWrap: 'wrap',
  // },
  // toolbarTitle: {
  //   flexGrow: 1,
  // },
  // link: {
  //   margin: theme.spacing(1, 1.5),
  // },
  // heroContent: {
  //   padding: theme.spacing(8, 0, 6),
  // },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  // footer: {
  //   borderTop: `1px solid ${theme.palette.divider}`,
  //   marginTop: theme.spacing(8),
  //   paddingTop: theme.spacing(3),
  //   paddingBottom: theme.spacing(3),
  //   [theme.breakpoints.up('sm')]: {
  //     paddingTop: theme.spacing(6),
  //     paddingBottom: theme.spacing(6),
  //   },
  // },
}));

const SubscriptionTypes = (props) => {
  const classes = useStyles();
  const tiers = [
    // {
    //   id: 'trimestral',
    //   title: 'Pago Trimestral',
    //   subheader: '12% Descuento',
    //   price: '22.00',
    //   description: [
    //     '2 usuarios incluidos', 
    //     '2 GB de almacenamiento', 
    //     'URL de acceso bajo el dominio decimaeco.com',
    //     'Conecta tu propio dominio', 
    //     // 'Help center access', 
    //     'Soporte por correo electrónico'
    //   ],
    //   buttonText: 'Suscribirse',
    //   buttonVariant: 'contained',
    // },
    // {
    //   id: 'mensual',
    //   title: 'Pago Mensual',
    //   subheader: 'Más popular',
    //   price: '25.00',
    //   description: [
    //     '2 usuarios incluidos',
    //     '2 GB de almacenamiento',
    //     'URL de acceso bajo el dominio decimaeco.com', 
    //     'Conecta tu propio dominio', 
    //     // 'Help center access',
    //     'Soporte por correo electrónico',
    //   ],
    //   buttonText: 'Suscribirse',
    //   buttonVariant: 'contained',
    // },
    // {
    //   id: 'semestral',
    //   title: 'Pago Semestral',
    //   subheader: '20% Descuento',
    //   price: '20.00',
    //   description: [
    //     '2 usuarios incluidos',
    //     '2 GB de almacenamiento',
    //     'URL de acceso bajo el dominio decimaeco.com', 
    //     'Conecta tu propio dominio', 
    //     // 'Help center access',
    //     'Soporte por correo electrónicot',
    //   ],
    //   buttonText: 'Suscribirse',
    //   buttonVariant: 'contained',
    // },
    {
      id: 'trimestral',
      title: 'Pago Trimestral',
      subheader: '_',
      price: '150.00',
      description: [
        '2 usuarios incluidos', 
        '2 GB de almacenamiento', 
        'URL de acceso bajo el dominio decimaeco.com',
        'Conecta tu propio dominio', 
        // 'Help center access', 
        'Soporte por correo electrónico'
      ],
      buttonText: 'Suscribirse',
      buttonVariant: 'contained',
    },
    {
      id: 'semestral',
      title: 'Pago Semestral',
      subheader: '20% Descuento',
      price: '240.00',
      description: [
        '2 usuarios incluidos',
        '2 GB de almacenamiento',
        'URL de acceso bajo el dominio decimaeco.com', 
        'Conecta tu propio dominio', 
        // 'Help center access',
        'Soporte por correo electrónico',
      ],
      buttonText: 'Suscribirse',
      buttonVariant: 'contained',
    },
    {
      id: 'anual',
      title: 'Pago anual',
      subheader: '40% Descuento',
      price: '360.00',
      description: [
        '2 usuarios incluidos',
        '2 GB de almacenamiento',
        'URL de acceso bajo el dominio decimaeco.com', 
        'Conecta tu propio dominio', 
        // 'Help center access',
        'Soporte por correo electrónico',
      ],
      buttonText: 'Suscribirse',
      buttonVariant: 'contained',
    },
  ];

  return (
    <Grid container spacing={5} alignItems="flex-end">
      {tiers.map((tier) => (
        // Enterprise card is full width at sm breakpoint
        <Grid item key={tier.title} xs={12} sm={tier.title === 'Pago anual' ? 12 : 6} md={4}>
          <Card>
            <CardHeader
              title={tier.title}
              subheader={tier.subheader}
              titleTypographyProps={{ align: 'center' }}
              subheaderTypographyProps={{ align: 'center' }}
              action={tier.title === 'Pago Mensual' ? <StarIcon /> : null}
              className={classes.cardHeader}
            />
            <CardContent>
              <div className={classes.cardPricing}>
                <Typography component="h2" variant="h3" color="textPrimary">
                  ${tier.price}
                </Typography>
                {/* <Typography variant="h6" color="textSecondary">
                  /mes
                </Typography> */}
              </div>
              <ul>
                {tier.description.map((line) => (
                  <Typography component="li" variant="subtitle1" align="center" key={line}>
                    {line}
                  </Typography>
                ))}
              </ul>
            </CardContent>
            <CardActions>
              {/* <Button fullWidth variant={tier.buttonVariant} color="primary" href="mailto:info@decima.la"> */}
              <Button fullWidth variant={ tier.buttonVariant } color="primary" onClick={() => empty(props.clickHandler) ? props.history.push('suscripciones') : props.clickHandler(tier.id)}>
                { empty(props.buttonText) ? tier.buttonText : props.buttonText }
              </Button> 
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

export default SubscriptionTypes;
