import * as actionTypes from './actionTypes';
import axios from '../../axios-decima';
import { empty, fireToast, fireMessage, fireErrorMessage } from '../../shared/utility';

export const initPromotionForm = (id, data) => {
	return {
		type: actionTypes.INIT_PROMOTION_FORM,
		id: id,
		data: data,
	};
};

export const setPromotionState = ( state ) => {
	return {
		type: actionTypes.SET_PROMOTION_STATE,
		state: state
	};
};

