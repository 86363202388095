import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import config from '../../config';

const initialState = {
	token: config.demoToken,
	firebaseToken: '',
	isAuthenticated: false,
	organizationId: config.demoOrganizationId,
	storeName: 'demo',
	storeSubdomain: 'demo',
	email: null,
	name: null,
	currencySymbol: '$',
	costPricePrecision: 4,
	error: null,
	loading: false,
	authRedirectPath: '/eco'//cambiar '/eco' por '/'
	};

const authStart = ( state, action ) => {
	return updateObject( state, { error: null, loading: true } );
};

const authSuccess = (state, action) => {
	return updateObject( state, { 
		token: action.idToken,
		isAuthenticated: true,
		organizationId: action.organizationId,
		// userId: action.userId,
		name: action.name,
		email: action.email,
		currencySymbol: action.currencySymbol,
		costPricePrecision: action.costPricePrecision,
		error: null,
		loading: false
	} );
};

const authFirebaseSuccess = (state, action) => {
	return updateObject( state, { 
		firebaseToken: action.firebaseToken
	} );
};

const subscribeSuccess = (state, action) => {
	return updateObject( state, { 
		error: null,
		loading: false
	} );
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { 
				token: config.demoToken, 
				isAuthenticated: false,
				organizationId: config.demoOrganizationId,
				storeName: 'demo',
				storeSubdomain: 'demo',
        // userId: null,
        name: null,
        email: null,
        currencySymbol: '$',
        costPricePrecision: 4
    });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path })
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FIREBASE_SUCCESS: return authFirebaseSuccess(state, action);
        case actionTypes.SUBSCRIBE_SUCCESS: return subscribeSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        default:
            return state;
    }
};

export default reducer;