import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { empty, ccyFormat, isDemo } from '../../../../shared/utility';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import Money from '../../../../components/UI/Input/Money';
import Number from '../../../../components/UI/Input/Number';
import * as actions from '../../../../store/actions/index';

const useStyles = makeStyles(theme => ({
  // form: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   margin: 'auto',
  //   width: 'fit-content',
  // },
  // formControl: {
  //   marginTop: theme.spacing(2),
  //   minWidth: 150,
  // },
  // formControlLabel: {
  //   marginTop: theme.spacing(1),
  // },
  loader: {
    marginTop: theme.spacing(2),
  },
}));

const DecreaseStockDialog = (props) => {
  const materialClasses = useStyles();
  const [status, setStatus] = useState(null);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const settings = useSelector(state => state.layout.settings);
  const loading = useSelector(state => state.article.loading);
  const isDecreaseStockDialogVisible = useSelector(state => state.article.isDecreaseStockDialogVisible);
  const dispatch = useDispatch();
  const onSetArticleState = (state) => dispatch(actions.setArticleState(state));
  console.log('Rendering DecreaseStockDialog!');
  // useEffect(() => {
  //   setStatus(props.selectedRow.status);
  // }, [props.selectedRow.status]);
  const selectHandler = (event) => {
    setStatus(event.target.value);
  };
  const increaseStockHandler = () => {
    // console.log('Cambiar estado del pedido!');
    if(isDemo(isAuthenticated))
    {
      onSetArticleState({ isDecreaseStockDialogVisible: false });
      return;
    }

    //Implementar cambio de estado!
    onSetArticleState({ isDecreaseStockDialogVisible: false });
  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth='xs'
        open={isDecreaseStockDialogVisible}
        onClose={props.onToggleDialog}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Dismunir stock</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Seleccione el nuevo estado del pedido # del cliente...:
          </DialogContentText> */}
          {
            !loading 
              ? 
                <Grid item container spacing={3}>
                  <Grid item xs={12}>
                    <TextField 
                      required
                      fullWidth  
                      label="Descripción" 
                      defaultValue="Salida de mercadería" 
                    />
                  </Grid>  
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      fullWidth
                      autoFocus={true}
                      label='Cantidad'
                      autoComplete="off"
                      // name="availableQuantity"
                      // id="availableQuantity"
                      // style={{ margin: 8}}
                      InputProps={{
                        inputComponent: Number,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      fullWidth
                      label="Costo"
                      disabled={true}
                      defaultValue={0}
                      // autoComplete="off"
                      // value={state.retailPrice}
                      // onChange={inputChangeHandler}
                      // onKeyUp={keyUpHandler}
                      name="cost"
                      id="cost"
                      // style={{ margin: 8}}
                      // margin="normal"
                      // style={{width: '20ch'}}
                      // className={props.materialClasses.textField}
                      InputProps={{
                        inputComponent: Money,
                      }}
                    />
                  </Grid>
                </Grid>
              :
              <div className={materialClasses.loader}>
                <Spinner />
              </div>
          }
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => increaseStockHandler()} color="primary">
            Dismunir
          </Button>
          <Button onClick={() => onSetArticleState({ isDecreaseStockDialogVisible: false })} color="secondary">
            Regresar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default DecreaseStockDialog;
