import React from 'react'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { isMobileOnly } from 'react-device-detect';
import Carousel from 'react-material-ui-carousel';
import Copyright from '../../Footer/Copyright/Copyright';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    width: '100%'
  }
}));

const IntroPage = (props) => {
  const materialClasses = useStyles();
  const tileData = [
    {
      img: 'https://storage.googleapis.com/decimala-main-bucket/organizations/4/decimaeco0201v1.jpg',
      title: '',
      // cols: 2,
    },
    {
      img: 'https://images.unsplash.com/photo-1555421689-491a97ff2040?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=490&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=650',
      // img: 'https://storage.googleapis.com/decimala-main-bucket/organizations/4/decimaeco0202v1.jpg',
      title: '',
      // cols: 2,
    },
    {
      img: 'https://storage.googleapis.com/decimala-main-bucket/organizations/4/decimaeco0206v1.jpg',
      title: '',
      // cols: 2,
    },
    {
      img: 'https://images.unsplash.com/photo-1582004531564-50f300aae039?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=490&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=650',
      // img: 'https://storage.googleapis.com/decimala-main-bucket/organizations/4/decimaeco0204v1.jpg',
      title: '',
      // cols: 2,
    },
    {
      img: 'https://storage.googleapis.com/decimala-main-bucket/organizations/4/decimaeco0205v1.jpg',
      title: '',
      // cols: 2,
    },
    {
      img: 'https://images.unsplash.com/photo-1554774853-7f03caeb4b15?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=490&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=650',
      // img: 'https://storage.googleapis.com/decimala-main-bucket/organizations/4/decimaeco0203v1.jpg',
      title: '',
      // cols: 2,
    }
  ];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Paper className={materialClasses.paper}>
          <Grid item container xs={12} spacing={4} alignItems="center">
            <Grid item xs={12} sm={6}>
              <img
                  alt=""
                  src='https://storage.googleapis.com/decimala-main-bucket/organizations/4/decimaeco0301v4_1.jpg'
                  // src='https://storage.googleapis.com/decimala-main-bucket/organizations/4/decimaeco0301v3_2.jpg'
                  // src='https://storage.googleapis.com/decimala-main-bucket/organizations/4/decimaeco0301v1.jpg'
                  // src='https://images.unsplash.com/photo-1448932223592-d1fc686e76ea?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=365&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=650'
                  // src='https://source.unsplash.com/random/1800x400/?ecommerce'
                  style={{ height: 'auto', width: '100%'}}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography align={ isMobileOnly ? "center" : "left" } variant={ isMobileOnly ? "h4" : "h2" } gutterBottom>
                Haz crecer tu negocio
              </Typography>
              <Typography variant={ isMobileOnly ? "h6" : "h4" } color="textSecondary" gutterBottom>
                Empezar con una tienda de Instagram está bien, pero para crecer necesitas tu propia tienda en línea, <strong><Link href="https://demo.decimaeco.com" target="_blank">visita nuestra tienda DEMO</Link></strong>.
              </Typography>
            </Grid>        
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={materialClasses.paper}>
          <Grid item container xs={12} spacing={4}>
            <Grid item xs={12}>
            <Container maxWidth="md" className={materialClasses.heroContent}>
              <Typography align="center" variant={ isMobileOnly ? "h4" : "h2" } gutterBottom> 
                Tu administras tu tienda
              </Typography>
              <Typography variant={ isMobileOnly ? "h6" : "h4" } align="center" color="textSecondary" gutterBottom>              
                Explora el menú de la izquierda para que conozcas todas las opciones que tienes disponibles para administrar y personalizar tu tienda.
              </Typography>
            </Container>
            </Grid>
            <Grid item xs={12}>
              <Container maxWidth="md" className={materialClasses.heroContent}>
              <div className={materialClasses.root}>
                { 
                  isMobileOnly 
                    ?  <img src="https://images.unsplash.com/photo-1582004531564-50f300aae039?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=200&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=600" alt="" />
                    : <GridList cellHeight={160} className={materialClasses.gridList} cols={3}>
                        {tileData.map((tile) => (
                          <GridListTile key={tile.img} cols={tile.cols || 1}>
                            <img src={tile.img} alt={tile.title} />
                          </GridListTile>
                        ))}
                      </GridList>
                }
                
              </div>
              </Container>
            </Grid>        
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={materialClasses.paper}>
          <Grid item container xs={12} spacing={4} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography align={ isMobileOnly ? "center" : "left" } variant={ isMobileOnly ? "h4" : "h2" } gutterBottom>
                Toma la iniciativa
              </Typography>
              <Typography variant={ isMobileOnly ? "h6" : "h4" } color="textSecondary" gutterBottom>
                <strong><Link style={{cursor: 'pointer'}} onClick={(event) => {event.preventDefault();props.history.push('suscripciones')}} >Suscribete aquí</Link></strong> y comienza a transformar tu negocio físico en un negocio online.
              </Typography>
            </Grid>      
            <Grid item xs={12} sm={6}>
              <Carousel navButtonsAlwaysVisible={true}>
                <img
                  alt=""
                  src='https://storage.googleapis.com/decimala-main-bucket/organizations/4/decimaeco01v1.jpg'
                  // src='https://source.unsplash.com/random/1800x400/?ecommerce'
                  style={{ height: 'auto', width: '100%'}}
                />
                <img
                  alt=""
                  src='https://storage.googleapis.com/decimala-main-bucket/organizations/4/decimaeco02v1.jpg'
                  style={{ height: 'auto', width: '100%'}}
                />
                <img
                  alt=""
                  src='https://storage.googleapis.com/decimala-main-bucket/organizations/4/decimaeco03v1.jpg'
                  style={{ height: 'auto', width: '100%'}}
                />
                <img
                  alt=""
                  src='https://storage.googleapis.com/decimala-main-bucket/organizations/4/decimaeco04v1.jpg'
                  style={{ height: 'auto', width: '100%'}}
                />
              </Carousel>
            </Grid>  
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={materialClasses.paper}>
          <Grid item container xs={12} spacing={4} alignItems="center">    
            <Grid item xs={12} sm={6}>
              <Carousel navButtonsAlwaysVisible={true}>
                <a target="_blank" href="http://fusionbeautycare.com/">
                  <img
                    alt=""
                    src='https://storage.googleapis.com/decimala-main-bucket/organizations/4/decimaeco0401v1.jpg'
                    // src='https://source.unsplash.com/random/1800x400/?ecommerce'
                    style={{ height: 'auto', width: '100%'}}
                  />
                </a>
                <a target="_blank" href="https://www.alohasv.com/">
                  <img
                    alt=""
                    src='https://storage.googleapis.com/decimala-main-bucket/organizations/4/decimaeco0402v1.jpg'
                    // src='https://source.unsplash.com/random/1800x400/?ecommerce'
                    style={{ height: 'auto', width: '100%'}}
                  />
                </a>
              </Carousel>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography align={ isMobileOnly ? "center" : "left" } variant={ isMobileOnly ? "h4" : "h2" } gutterBottom>
                Nuestros clientes
              </Typography>
              <Typography variant={ isMobileOnly ? "h6" : "h4" } color="textSecondary" gutterBottom>
                Ellos ya tomaron la iniciativa, conoce las tiendas de nuestros clientes.
              </Typography>
            </Grid>    
          </Grid>
        </Paper>
      </Grid>
      {/* <Paper className={materialClasses.paper}>
        <Grid item container xs={12} spacing={4}>
          
          <Grid item xs={12} sm={6}>
            <Typography variant="h2" gutterBottom>
              Tu administras tu tienda
            </Typography>
            <Typography variant="h4" color="textSecondary"  gutterBottom>
              Explora el menú de la izquierda para que conozcas todas las opciones que tienes disponibles para administrar y personalizar tu tienda.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <img
              alt=""
              src='https://storage.googleapis.com/decimala-main-bucket/organizations/4/decimaeco01v1.jpg'
              // src='https://source.unsplash.com/random/1800x400/?ecommerce'
              style={{ height: 'auto', width: '100%'}}
            />
          </Grid>
                  
        </Grid>
      </Paper> */}
      <Grid item xs={12}>
        <Copyright />
      </Grid>
    </Grid>
  )
}

export default IntroPage;
