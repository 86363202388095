import * as actionTypes from '../actions/actionTypes';
import { updateObject, empty } from '../../shared/utility';

const initialState = {
	defaultSettings: {
		primaryColor: '#2196f3',
		secondaryColor: '#f50057',
		ecoName: 'Demo',
		ecoSubdomain: '',
		ownSubdomain: '',
		ownDomain: '',
		categories: [],
		mainPageGallery: [],
		documentTypes: {
			'T': {id: 'T', label: 'Ticket', isEnabled: false },
			'F': {id: 'F', label: 'Factura de consumidor final', isEnabled: true },
			'C': {id: 'C', label: 'Comprobante de crédito fiscal', isEnabled: false },
		},
		paymentForms: {
			'E': {
				id: 'E', 
				defaultLabel: 'Efectivo (contra entrega)', 
				label: 'Efectivo (contra entrega)', 
				instructions: '<p>Cuando el repartidor le haga entrega de los productos, deber&aacute; de cancelarle en efectivo el total de su pedido. Favor de tomar en cuenta que no se aceptan billetes de $50 y $100.</p>', 
				isEnabled: true, 
				isPremium: false, 
				hasError: false 
			},
			'T': {
				id: 'T',
				defaultLabel: 'Tarjeta de crédito (vía telefónica)',
				label: 'Tarjeta de crédito (vía telefónica)',
				instructions: '<p>Un miembro de nuestro equipo se pondr&aacute; en contacto con usted v&iacute;a telef&oacute;nica al n&uacute;mero que nos ha proporcionado para tomarle los datos de su tarjeta y realizar el pago.</p>',
				isEnabled: false,
				isPremium: false,
				hasError: false
			},
			'P': {
				id: 'P',
				defaultLabel: 'Tarjeta de crédito (POS Móvil)',
				label: 'Tarjeta de crédito (POS Móvil)',
				instructions: '<p>Al recibir su pedido, deber&aacute; de presentar su tarjeta al repartidor para que le procese el pago a trav&eacute;s de nuestro POS M&oacute;vil.</p>',
				isEnabled: false,
				isPremium: false,
				hasError: false
			},
			'B': {
				id: 'B',
				defaultLabel: 'Transferencia bancaria',
				label: 'Transferencia bancaria',
				instructions: '<p>Realice una transferencia bancaria por el monto total del pedido, a cualquiera de las siguientes cuentas:</p> <ul> <li>Banco A al n&uacute;mero de cuenta #000000</li> <li>Banco B al n&uacute;mero de cuenta #000000</li> </ul> <p>Una vez haya realizado la transferencia, env&iacute;enos el comprobante al correo pedidos@mitenda.com</p>',
				isEnabled: false,
				isPremium: false,
				hasError: false
			},
			'Y': {
				id: 'Y',
				defaultLabel: 'Paypal vía email',
				label: 'Paypal vía email',
				instructions: '<p>Un miembro de nuestro equipo le enviar&aacute; por correo electr&oacute;nico un enlace con la solicitud de pago a trav&eacute;s del servicio de Paypal.</p>',
				isEnabled: false,
				isPremium: false,
				hasError: false
			},
			'G': {
				id: 'G',
				defaultLabel: 'Pagadito vía email',
				label: 'Pagadito vía email',
				instructions: '<p>Un miembro de nuestro equipo le enviar&aacute; por correo electr&oacute;nico un enlace con la solicitud de pago a trav&eacute;s del servicio de Pagadito.</p>',
				isEnabled: false,
				isPremium: false,
				hasError: false
			},
			'A': {
				id: 'A',
				defaultLabel: 'Paypal',
				label: 'Paypal',
				instructions: '<p>Al realizar el pedido, ser&aacute; redirigido a la p&aacute;gina de <em><strong>PayPal</strong></em> para efectuar el pago, una vez realizado el pago, regreser&aacute; de forma autom&aacute;tica a nuestra tienda para confirmar su pedido.</p>',
				isEnabled: false,
				isPremium: true,
				hasError: false,
				environment: 'sandbox',
				sandboxUid: '',
				sandboxWsk: '',
				sandboxWspg: '',
				productionUid: '',
				productionWsk: '',
				productionWspg: '',
			},
			'D': {
				id: 'D',
				defaultLabel: 'Pagadito',
				label: 'Pagadito',
				instructions: '<p>Al realizar el pedido, ser&aacute; redirigido a la p&aacute;gina de <em><strong>Pagadito</strong></em> para efectuar el pago, una vez realizado el pago, regreser&aacute; de forma autom&aacute;tica a nuestra tienda para confirmar su pedido.</p>',
				isEnabled: false,
				isPremium: true,
				hasError: false,
				environment: 'sandbox',
				sandboxAccount: '',
				sandboxClientId: '',
				sandboxSecret: '',
				productionAccount: '',
				productionClientId: '',
				productionSecret: ''
			},
		},
		shippingCostType: 'F',
		shippingCostAssignedBy: 'Z',//Z = Zona, M = Municipio, D = Departamento
		fixedShippingCost: 0,
		areas: {},
		cities: {},
		states: {},
		footer: '<p style="text-align: center;"><strong>&copy; 2020 Nombre de la tienda, todos los derechos reservados.<br /></strong>Tel&eacute;fono: +503 000-000, Correo electr&oacute;nico: info@nombretienda.com<strong><br /></strong>Direcci&oacute;n tienda, <br />Municipio, Provincia / Departamento, Pa&iacute;s</p>',
		alwaysShowAllArticles: false,
		validateStock: false,
		variantLabel: 'Variante',
		favicon: '',
		gaTrackingID: '',
		imageWidth: 500,
		bannerWidth: 1800,
		isSingleCategoryEnabled: true,
		isNewEco: true
	},
	settings:  null,
	ownSubdomainCopy: '',
	ownDomainCopy: '',
	ecoSubdomains: [],
	error: null,
	loading: true
	};


const releaseSettings = (state, action) => {
	return updateObject( state, { 
		settings: null, 
		loading: true,
		ownSubdomainCopy: '',
		ownDomainCopy: ''
	});
};

const setSettings = (state, action) => {
	const updatedSettings = updateObject( state.settings, action.settings );
	return updateObject( state, { 
		settings: updatedSettings,
	} );
};

const settingStart = ( state, action ) => {
	return updateObject( state, { error: null, loading: true } );
};

const settingFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const fetchSettingsSuccess = (state, action) => {
	let settings = {};
	if(!empty(action.settings)) settings = action.settings;
	const updatedDocumentTypes = updateObject( state.defaultSettings.documentTypes, !empty(settings.documentTypes) ? settings.documentTypes : {} );
	const updatedPaymentForms = updateObject( state.defaultSettings.paymentForms, !empty(settings.paymentForms) ? settings.paymentForms : {} );
	const updatedSettings = updateObject( settings, {
		documentTypes: updatedDocumentTypes,
		paymentForms: updatedPaymentForms
	} );
	// updatedSettings = updateObject( state.defaultSettings, !empty(action.settings) ? action.settings : {} );
	return updateObject( state, { 
		// settings: updatedSettings,
		settings: updateObject( state.defaultSettings, updatedSettings ),
		ecoSubdomains: action.ecoSubdomains,
		ownSubdomainCopy: updatedSettings.ownSubdomain,
		ownDomainCopy: updatedSettings.ownDomain,
		error: null,
		loading: false
	} );
};

const saveSettingsSuccess = (state, action) => {
	return updateObject( state, {
		error: null,
		loading: false
	} );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.RELEASE_SETTINGS: return releaseSettings(state, action);
        case actionTypes.SET_SETTINGS: return setSettings(state, action);
        case actionTypes.SETTING_START: return settingStart(state, action);
        case actionTypes.SETTING_FAIL: return settingFail(state, action);
        case actionTypes.FETCH_SETTINGS_SUCCESS: return fetchSettingsSuccess(state, action);
        case actionTypes.SAVE_SETTINGS_SUCCESS: return saveSettingsSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;