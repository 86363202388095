import React from 'react';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

const Money = (props) => {
  const { inputRef, onChange, ...other } = props;
  const currencySymbol = useSelector(state => state.auth.currencySymbol);
  const costPricePrecision = useSelector(state => state.auth.costPricePrecision);
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={costPricePrecision}
      fixedDecimalScale={true}
      prefix={currencySymbol}
    />
  )
}

export default Money;