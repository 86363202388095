import React from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { format, isValid } from 'date-fns';
import MainForm from './MainForm';
// import Media from './Media';
import * as actions from '../../../../store/actions/index';
import { empty, round, updateObject, isDemo, fireMessage } from '../../../../shared/utility';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%'
  },
  formSwitch: {
    marginLeft: 5,
    marginTop: 15,
  },
  loader: {
    marginTop: theme.spacing(2),
  },
}));

const Form = (props) => {
  const materialClasses = useStyles();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const token = useSelector(state => state.auth.token);
  // const settings = useSelector(state => state.setting.settings);
  const id = useSelector(state => state.coupon.id);
  const dispatch = useDispatch();
  const onCrudeCoupon = (data, token, formData) => dispatch(actions.crudeCoupon(data, token, formData));
  const saveHandler = (data) => {
    let validation = '';
    let requeriredFields = [
      { label: 'Código', name: 'code' },
      { label: 'Nombre', name: 'name' },
    ];
    let defaultZeroFields = ['value', 'type', 'minAmount', 'maxAmount', 'maximumNumber'];
    if(isDemo(isAuthenticated)) return;
    for ( let field of defaultZeroFields ) if(empty(data[field])) data = updateObject(data, { [field] : 0 });
    for ( let field of requeriredFields ) if(empty(data[field['name']])) validation += ( !empty(validation) ? ', ' + field['label'] : field['label'] );
    if(!empty(validation)) fireMessage( { text: 'Los siguientes campos son requeridos: ' + validation } );
    onCrudeCoupon({
      id: id,
      code: data.code,
      name: data.name,
      value: data.value,
      type: data.type,
      from_date: isValid(data.fromDate) ? format(data.fromDate, 'yyyy-MM-dd') : null,
      to_date: isValid(data.toDate) ? format(data.toDate, 'yyyy-MM-dd') : null,
      min_amount: data.minAmount,
      max_amount: data.maxAmount,
      maximum_number: data.maximumNumber,
      is_free_delivery: data.isFreeDelivery,
      is_promotion_restricted: data.isPromotionRestricted,
      is_one_time_client_restricted: data.isOneTimeClientRestricted,
      is_client_restricted: data.isClientRestricted,
      is_active: data.isActive,
      changeDateFormat: false,
    }, token, data);
  }
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography component="h5" variant="h5">
          Nuevo cupón
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <MainForm materialClasses={materialClasses} onSaveHandler={saveHandler}  {...props}/>
      </Grid>
      {/* <Grid item container xs={12} md={8}>
        <MainForm materialClasses={materialClasses} {...props}/>
        <Media materialClasses={materialClasses} {...props}/>
      </Grid>
      <Grid item xs={12} md={4}>
        
      </Grid>
      <Grid container item xs={12} spacing={3}>
        
      </Grid> */}
    </React.Fragment>
  )
}

export default Form;
