import React from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import UndoIcon from '@material-ui/icons/Undo';
import * as actions from '../../../../store/actions/index';
import { empty, fireToast, fireMessage, fireErrorMessage, isDemo } from '../../../../shared/utility';
import axios from '../../../../axios-decima';
import Uploader from '../../../../components/Media/Uploader/Uploader';

const ImageUploader = (props) => {
  const token = useSelector(state => state.auth.token);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const tileData = useSelector(state => state.setting.settings.mainPageGallery);
  const bannerWidth = useSelector(state => state.setting.settings.bannerWidth);
  const dispatch = useDispatch();
  const onSetSettings = (settings) => dispatch(actions.setSettings(settings));

  const onGetUploadParams = ({ meta }) => {
    // console.log('Decima- getUploadParams');
    if(!isAuthenticated)
    {
      return {};
    }

    const url = axios.defaults.baseURL + '/api/files/uploadFile'

    return { 
      url, 
      fields: {
        token: token, 
        parent_file_id: '', 
        system_reference_id: '', 
        parent_folder: 'CMS/Galería página principal', 
        minWidth: bannerWidth + '', 
        // minWidth: '1800', 
        // minHeight: '500', 
        sameWidthAsHeight: '0',
        sizes: [bannerWidth], 
        // resizeByHeight: '1', 
        isPublic: 1
      }, 
      meta: { fileUrl: `${url}/${encodeURIComponent(meta.name)}` } 
    }
  }

  const onHandleChangeStatus = ({ meta, remove, xhr }, status) => {
    // console.log(status, meta);
    if (status === 'done')
    {
      let response = JSON.parse(xhr.response);

      console.log(response);

      if(!empty(response.error))
      {
        // console.log(response.error);
        fireMessage({
          title: response.error,
        });
      }

      if(!empty(response.success))
      {
        onAssociateImage(response.dataFiles[1]['id'], response.dataFiles[1]['url']);
      }
    }  

    if (status === 'error_upload' || status === 'exception_upload')
    {
      console.log(xhr);
      console.log(xhr.statusText);//statusText
      console.log(xhr.responseText);//responseText
      fireErrorMessage();
    }  

    if (status === 'error_upload_params')
    {
      isDemo(isAuthenticated);
    }  
  }

  const onAssociateImage = (id, url) => {
    if(isDemo(isAuthenticated))
    {
      return;
    }

    let gallery = [...tileData];
    let primary = tileData.length === 0 ? true : false;

    gallery.push({
      id: id,
      image_url: url,
      primary: primary,
      position: tileData.length + 1,
    });
    
    onSetSettings({ mainPageGallery: gallery });

    fireToast('Se asoció la imagen exitosamente!');
  }

  return (
    <React.Fragment>
      <Grid item xs={12} md={7}>
        <Button color="primary" startIcon={<UndoIcon />} onClick={() => props.setUploading(false)}>Regresar</Button>
      </Grid>
      <Uploader 
        url='/api/files/getMainPageGalleryImages' 
        imageWidth={ bannerWidth }
        token={token} 
        getUploadParams={onGetUploadParams} 
        handleChangeStatus={onHandleChangeStatus}
        associateImage={onAssociateImage}
      /> 
    </React.Fragment>
  )
}

export default ImageUploader;
