import * as actionTypes from '../actions/actionTypes';
import { updateObject, empty } from '../../shared/utility';

const initialState = {
  loading: false,
  touched: false,
  isFormVisible: false,
  // redirectToForm: false,
  page: 0,
  tableFilter: '',
  error: null,
  id: null,
  data: {},
};

const initCouponForm = (state, action) => {
	return updateObject( state, { 
    touched: false,
		isFormVisible: true, 
    // redirectToForm: true, 
    error: null,
    id: action.id,
		data: empty(action.data) ? {} : action.data
	});
};

const setCouponState = (state, action) => {
	return updateObject( state, action.state );
};

const couponStart = ( state, action ) => {
	return updateObject( state, { loading: true } );
};

const couponFail = ( state, action ) => {
  return updateObject( state, { 
    loading: false,
    error: action.error
	});
};

const couponCrudSuccess = ( state, action ) => {
	return updateObject( state, {
    loading: false,
		touched: false,
    error: null,
    id: action.id,
    data: empty(action.data) ? {} : action.data
	} );
};

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.INIT_COUPON_FORM: return initCouponForm( state, action );
    case actionTypes.SET_COUPON_STATE: return setCouponState( state, action );
    case actionTypes.COUPON_START: return couponStart( state, action );
    case actionTypes.COUPON_FAIL: return couponFail( state, action );
    case actionTypes.COUPON_CRUD_SUCCESS: return couponCrudSuccess( state, action )
    default: return state;
  }
};

export default reducer;