import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import ArticleTable from './Table/Table';
import ArticleForm from './Form/Form';
import ImageUploader from './ImageUploader/ImageUploader';

import Spinner from '../../../components/UI/Spinner/Spinner';
import useHttpErrorHandler from '../../../hooks/http-error-handler';
import axios from '../../../axios-decima';
import 'react-dropzone-uploader/dist/styles.css';

const useStyles = makeStyles(theme => ({
  loader: {
    marginTop: theme.spacing(2),
  },
}));

const Article = props => {
  const materialClasses = useStyles();
  const [formVisible, setFormVisible] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [error, clearError] = useHttpErrorHandler(axios);
  const loading = useSelector(state => state.article.loading);
  const settingLoading = useSelector(state => state.setting.loading);
  return (
    <Grid container spacing={1}>
      {
        (formVisible && !loading && !uploading)
          ? <ArticleForm setUploading={setUploading} setFormVisible={setFormVisible}/>
          : null   
      }
      {
        (!formVisible && !loading && !uploading)
          ? <ArticleTable setFormVisible={setFormVisible}/>  
          : null
      }
      {
        (uploading && !loading)
          ? <ImageUploader setUploading={setUploading} />
          : null
      }
      {
        (loading || settingLoading)
          ? <Grid item xs={12}>
              <div className={materialClasses.loader}>
                <Spinner/>
              </div>
            </Grid>
          : null
      }
    </Grid>
  )
}

export default Article;
