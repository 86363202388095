import React from 'react';
import {MTableToolbar} from 'material-table';
import {Box} from '@material-ui/core'
import SearchInput from './SearchInput/SearchInput';

const CustomMaterialTableToolbar = (props) => {
  return (
    <Box display="flex" flexDirection="row" p={0} m={1}>
      <Box p={0} flexGrow={1}>
        <MTableToolbar {...props} />
      </Box>
      <Box p={2} alignItems="center">
        <SearchInput
          searchValue={props.searchText}
          onSearchChanged={props.onSearchChanged}
        />
      </Box>
    </Box>
  )
}

export default CustomMaterialTableToolbar
