import React from 'react';
import {useDispatch, useSelector } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Editor } from '@tinymce/tinymce-react';
import CompanyMoney from '../../../../components/UI/Input/CompanyMoney';
import Money from '../../../../components/UI/Input/Money';
import Number from '../../../../components/UI/Input/Number';
import * as actions from '../../../../store/actions/index';
import { empty, round } from '../../../../shared/utility';
import config from '../../../../config';

const MainForm = (props) => {
  const state = useSelector(state => state.article.data);
  const brands = useSelector(state => state.article.brands);
  const articleTypes = useSelector(state => state.article.articleTypes);
  const unitMeasures = useSelector(state => state.article.unitMeasures);
  const dispatch = useDispatch();
  const onSetState = (data) => dispatch(actions.setData(data));
  const inputChangeHandler = (event) => {
    onSetState({[event.target.name]:event.target.value});
  };
  const autocompleteChangeHandler = (value, name) => {
    onSetState({[name]: value});
  }
  const editorChangeHandler = (content, editor) => {
    onSetState({[editor.id]: content});
  }
  const toggleCheckedHandler = (event) => {
    onSetState({
      [event.target.name]: !state[event.target.name],
      'discount': '0',
      'offerPrice': state.retailPrice
    });
  };
  const checkboxChangeHandler = (event) => {
    onSetState({[event.target.name]: event.target.checked});
  };
  const discountTypeButtonHandler = (event) => {
    onSetState({
      [event.target.name]: event.target.value,
      'discount': '0',
      'offerPrice': state.retailPrice
    });
  };
  const keyUpHandler = (event) => {
    const updatedState = {};
    if(event.keyCode === 9 || event.keyCode === 16)
    {
      return;
    }
    switch (event.target.name)
    {
      case 'retailPrice':
        updatedState['offerPrice'] = calculateOfferPrice(event.target.value, state.isDiscountApplicable, state.discount, state.discountType);
        break;
      case 'discount':
        updatedState['offerPrice'] = calculateOfferPrice(state.retailPrice, state.isDiscountApplicable, event.target.value, state.discountType);
        break;
      case 'offerPrice':
        updatedState['discount'] = calculateDiscount(state.retailPrice, event.target.value, state.discountType);
        break;
      default:
        break;
    }
    onSetState(updatedState);
  };
  const calculateOfferPrice = (retailPrice, isDiscountApplicable, discount, discountType) => {
    if(!isDiscountApplicable)
    {
      return retailPrice;
    }

    //ojocurrency
    if(isNaN(retailPrice))
    {
      retailPrice = parseFloat(retailPrice.replace(new RegExp('\\$|,', 'g'), ''));
    }

    if(isNaN(discount))
    {
      discount = parseFloat(discount.replace(new RegExp('\\$|,', 'g'), '')); 
    }

    if(discountType === 'K')
    {
      return round(retailPrice - (retailPrice * (discount/100)), 2);
    }
    else
    {
      return round((retailPrice - discount), 2);
    }
  }
  
  const calculateDiscount = (retailPrice, offerPrice, discountType) => {
    if(isNaN(retailPrice))
    {
      retailPrice = parseFloat(retailPrice.replace(new RegExp('\\$|,', 'g'), ''));
    }

    if(isNaN(offerPrice))
    {
      offerPrice = parseFloat(offerPrice.replace(new RegExp('\\$|,', 'g'), ''));
    }

    // console.log(retailPrice);
    // console.log(offerPrice);

    if(discountType === 'K')
    {
      return round(((retailPrice - offerPrice) / retailPrice) * 100, 2);
    }
    else
    {
      return round((retailPrice - offerPrice), 2);
    }
  }
  console.log('main form rendering!');
  return (
    <Paper className={[props.materialClasses.paper, props.materialClasses.paperForm, props.materialClasses.mediaContainer].join(' ')}>
      <Grid item container spacing={3}>
        <Grid item xs={12} md={3}>
          <TextField
            required
            fullWidth
            autoFocus
            name="internalReference"
            label="Código"
            autoComplete="internalReference"
            margin="normal"
            value={state.internalReference}
            onChange={inputChangeHandler}
            style={{ margin: 8}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            id="name"
            name="name"
            label="Nombre"
            autoComplete="name"
            margin="normal"
            value={state.name}
            onChange={inputChangeHandler}
            style={{ margin: 8}}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            required
            fullWidth
            label="Precio"
            autoComplete="off"
            value={state.retailPrice}
            onChange={inputChangeHandler}
            onKeyUp={keyUpHandler}
            name="retailPrice"
            id="retailPrice"
            style={{ margin: 8}}
            // margin="normal"
            // style={{width: '20ch'}}
            // className={props.materialClasses.textField}
            InputProps={{
              inputComponent: Money,
            }}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={6} lg={3}>
          <Autocomplete
            options={ brands }
            getOptionLabel={ (option) => option.label }
            value={ state.brand }
            onChange={ (event, newValue) => autocompleteChangeHandler(newValue, 'brand') }
            renderInput={ (params) => 
              <TextField {...params}  
                label="Marca" 
                fullWidth 
                style={{ margin: 8}}
              /> 
            }
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <Autocomplete
            options={ [] }
            getOptionLabel={ (option) => option.label }
            value={ state.model }
            onChange={ (event, newValue) => autocompleteChangeHandler(newValue, 'model') }
            renderInput={ (params) => 
              <TextField {...params}  
                label="Modelo" 
                fullWidth 
                style={{ margin: 8}}
              /> 
            }
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <Autocomplete
            options={ Object.values( articleTypes ) }
            getOptionLabel={ (option) => option.label }
            value={ state.articleType }
            onChange={ (event, newValue) => autocompleteChangeHandler(newValue, 'articleType') }
            renderInput={ (params) => 
              <TextField {...params}  
                label="Tipo de cuenta" 
                required
                fullWidth 
                style={{ margin: 8}}
              /> 
            }
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <Autocomplete
            options={ Object.values( unitMeasures ) }
            getOptionLabel={ (option) => option.label }
            value={ state.unitMeasure }
            onChange={ (event, newValue) => autocompleteChangeHandler(newValue, 'unitMeasure') }
            renderInput={ (params) => 
              <TextField {...params}  
                label="Unidad de medida" 
                required
                fullWidth 
                style={{ margin: 8}}
              /> 
            }
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={12} lg={6}>
          <TextField
            // required
            fullWidth
            label='Keywords'
            autoComplete="off"
            value={state.tags}
            onChange={inputChangeHandler}
            name="tags"
            id="tags"
            // className={props.materialClasses.textField}
            style={{ margin: 8}}
            // InputProps={{
            //   inputComponent: CompanyMoney,
            // }}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <TextField
            // required
            fullWidth
            label={empty(state.id)?'Stock inicial':'Stock disponible'}
            disabled={empty(state.id)?false:true}
            autoComplete="off"
            value={state.availableQuantity}
            onChange={inputChangeHandler}
            onKeyUp={keyUpHandler}
            name="availableQuantity"
            id="availableQuantity"
            // className={props.materialClasses.textField}
            style={{ margin: 8}}
            InputProps={{
              inputComponent: Number,
            }}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <TextField
            // required
            fullWidth
            label={empty(state.id)?'Costo inicial':'Costo promedio'}
            disabled={empty(state.id)?false:true}
            autoComplete="off"
            value={state.cost}
            onChange={inputChangeHandler}
            name="cost"
            id="cost"
            // className={props.materialClasses.textField}
            style={{ margin: 8}}
            InputProps={{
              inputComponent: CompanyMoney,
            }}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch name="isDiscountApplicable" checked={state.isDiscountApplicable} onChange={toggleCheckedHandler}/>}
            label="Oferta"
            className={props.materialClasses.formSwitch}
          />
          <FormControlLabel
            control={<Switch name="isVisibleInStore" checked={state.isVisibleInStore} onChange={checkboxChangeHandler}/>}
            label="Visible"
            className={props.materialClasses.formSwitch}
          />
          <FormControlLabel
            control={<Switch name="isFeatured" checked={state.isFeatured} onChange={checkboxChangeHandler}/>}
            label="Destacado"
            className={props.materialClasses.formSwitch}
          />
          <FormControlLabel
            control={<Switch name="isAlwaysAvailable" checked={state.isAlwaysAvailable} onChange={toggleCheckedHandler}/>}
            label="Vender sin stock"
            className={props.materialClasses.formSwitch}
          />
        </Grid>
      </Grid>
      {
        state.isDiscountApplicable
          ?<Grid item container spacing={3}>
            <Grid item xs={6} lg={3}>
              <TextField
                label="Descuento"
                value={state.discount}
                onChange={inputChangeHandler}
                onKeyUp={keyUpHandler}
                name="discount"
                id="discount"
                margin="normal"
                fullWidth
                style={{ margin: 8}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => discountTypeButtonHandler({target:{name:"discountType", value:"K"}})}
                      edge="end"
                      color={state.discountType === 'K'?'secondary':'primary'}
                    >
                      %
                    </IconButton>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => discountTypeButtonHandler({target:{name:"discountType", value:"J"}})}
                      edge="end"
                      color={state.discountType === 'J'?'secondary':'primary'}
                    >
                      <CheckBoxOutlineBlankIcon />
                    </IconButton>
                  </InputAdornment>,
                  inputComponent: Number
                }}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <TextField
                label="Oferta"
                value={state.offerPrice}
                onChange={inputChangeHandler}
                onKeyUp={keyUpHandler}
                name="offerPrice"
                id="offerPrice"
                margin="normal"
                fullWidth
                // className={props.materialClasses.textField}
                style={{ margin: 8}}
                InputProps={{
                  inputComponent: Money
                }}
              />
            </Grid>
          </Grid>
          : null
      }
      <div className={props.materialClasses.editor}>
        <Editor
          apiKey={ config.editorKey }
          value={state.description}
          placeholder='Ingrese aquí la descripción del producto'
          id="description"
          init={{
            height: 300,
            menubar: false,
            toolbar_mode: 'wrap',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen placeholder',
              'insertdatetime media table paste code help wordcount emoticons'
            ],
            toolbar:
              'undo redo | fontsizeselect formatselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | charmap emoticons | removeformat | help'
          }}
          onEditorChange={editorChangeHandler}
        />
      </div>
    </Paper>
  )
}

export default MainForm;
