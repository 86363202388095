import React, { useState, useEffect, useCallback } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import MenuIcon from '@material-ui/icons/Menu';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import UndoIcon from '@material-ui/icons/Undo';

import { SwatchesPicker, SketchPicker } from 'react-color';

import * as actions from '../../../../store/actions/index';


const ColorPicker = (props) => {
  let theme = null;
  const [color, setColor] = useState('#fff');
  const [applyTo, setApplyTo] = useState('primaryColor');
  const [colorSelector, setColorSelector] = useState('swatches');
  const primaryColor = useSelector(state => state.setting.settings.primaryColor);
  const secondaryColor = useSelector(state => state.setting.settings.secondaryColor);
  const dispatch = useDispatch();
  const onSetSettings = (settings) => dispatch(actions.setSettings(settings));
  const applyToChangeHandler = (event) => {
    setApplyTo(event.target.value);
  };
  const colorSelectorChangeHandler = (event) => {
    setColorSelector(event.target.value);
  };
  const inputChangeHandler = (event) => {
    onSetSettings({ [event.target.name]: event.target.value });
  };

  const changeCompleteHandler = (color, event) => {
    console.log(color.hex);
    setColor(color.hex);
    onSetSettings({ [applyTo]: color.hex });
  };

  theme = createMuiTheme({
		palette: {
			primary: {
				main: primaryColor,
			},
			secondary: {
				main: secondaryColor,
			},
		},
  });
  
  console.log('Color picker rendering!');
  return (
    <React.Fragment>
      <Typography variant="h5" className={props.materialClasses.sectionMarginBottom}>
        Paleta de colores
      </Typography>
      <Grid item container xs={12} md={12} spacing={2}>
        <Grid item xs={12}>
          <Divider  />
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper className={props.materialClasses.paper}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Tipo de selector de colores:</FormLabel>
              <RadioGroup row aria-label="position" name="position" value={applyTo} onChange={applyToChangeHandler}>
                <FormControlLabel value="primaryColor" control={<Radio color="primary" />} label="Primario" />
                <FormControlLabel value="secondaryColor" control={<Radio color="primary" />} label="Secundario" />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormLabel component="legend">Tipo de selector de colores:</FormLabel>
              <RadioGroup row aria-label="position" name="position" value={colorSelector} onChange={colorSelectorChangeHandler}>
                <FormControlLabel value="swatches" control={<Radio color="primary" />} label="Swatches" />
                <FormControlLabel value="sketch" control={<Radio color="primary" />} label="Sketch" />
              </RadioGroup>
            </FormControl>
            <TextField
              required
              fullWidth
              autoFocus
              disabled
              name="primaryColor"
              label="Color primario"
              autoComplete="primaryColor"
              margin="normal"
              value={primaryColor}
              onChange={inputChangeHandler}
              // style={{ margin: 8}}
            /> 
            <TextField
              required
              fullWidth
              autoFocus
              disabled
              name="secondaryColor"
              label="Color secundario"
              autoComplete="secondaryColor"
              margin="normal"
              value={secondaryColor}
              onChange={inputChangeHandler}
              // style={{ margin: 8}}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          {
            colorSelector == 'swatches'
              ? <SwatchesPicker 
                  height="100%" 
                  width="100%"
                  onChangeComplete={ changeCompleteHandler }
                />
              : <SketchPicker 
                  // height="100%" 
                  width="95%"
                  color = {color}
                  onChangeComplete={ changeCompleteHandler }
                />
          
          }
        </Grid>
        <Grid item xs={12} md={3}>
          <ThemeProvider theme={theme}>
            <Paper className={props.materialClasses.paper}>
              <AppBar position="static">
                <Toolbar>
                  <IconButton edge="start" className={props.materialClasses.menuButton} color="inherit" aria-label="menu">
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" className={props.materialClasses.title}>
                    Barra superior
                  </Typography>
                </Toolbar>
              </AppBar>
              <Button variant="contained" color="secondary" className={props.materialClasses.standardMarginTop} startIcon={<UndoIcon />}>Regresar</Button>
              <Button variant="contained" color="primary" className={props.materialClasses.standardMarginTop} startIcon={<ShoppingCartIcon />}>Checkout</Button>
            </Paper>
          </ThemeProvider>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default ColorPicker;
