import React, { useState, useEffect, useRef } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import UpdateIcon from '@material-ui/icons/Update';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import ChangeStatusDialog from './ChangeStatusDialog';
import DetailPanel from './DetailPanel';
import CustomMaterialTableToolbar from '../../../components/UI/CustomMaterialTableToolbar/CustomMaterialTableToolbar';
import * as actions from '../../../store/actions/index';
import useHttpErrorHandler from '../../../hooks/http-error-handler';
import axios from '../../../axios-decima';
import { fireMessage, isDemo } from '../../../shared/utility';

const useStyles = makeStyles(theme => ({
  paper: {
  },
}));

const Orders = (props) => {
  const materialClasses = useStyles();
  const orderTableRef = useRef();
  const [useGlobalPage, setUseGlobalPage] = useState(true);
  const [selectedRow, setSelectedRow] = useState({
    'number': '',
    'client_name': '',
    'status': 'A',
  });
  const [error, clearError] = useHttpErrorHandler(axios);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const page = useSelector(state => state.order.page);
  const tableFilter = useSelector(state => state.order.tableFilter);
  const reloadTable = useSelector(state => state.order.reloadTable);
  const loading = useSelector(state => state.order.loading);
  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();
  const onSetOrderState = (state) => dispatch(actions.setOrderState(state));
  // const onInitForm = (data, groups, tileData, existingVariants) => dispatch(actions.initForm(data, groups, tileData, existingVariants));
  
  // useEffect(() => {
  //   if(!empty(isFormVisible) && redirectToForm)
  //   {
  //     props.setFormVisible(true)
  //   }
  // }, [isFormVisible, redirectToForm, props]); 

  useEffect(() => {
    orderTableRef.current.onQueryChange();
  }, [reloadTable]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h4">
              Pedidos
            </Typography>
          </Box>
          <Box>
            {/* <Button variant="contained" color="primary" onClick={() => onInitForm()} startIcon={<AddIcon />}>
              Nuevo
            </Button> */}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ChangeStatusDialog selectedRow={selectedRow}/>
        <MaterialTable
            // title="Pedidos existentes"
            tableRef={orderTableRef}
            title=''
            options={{
              // toolbar:false,
              // paging: false,
              // actionsColumnIndex: -1,
              selection: true,
              pageSize: 10,
              pageSizeOptions: [5, 10, 20, 30, 40, 50],
              search: false,
              searchText: tableFilter,
              headerStyle: {
                fontWeight:'bold',
                textAlign:'center',
                padding:'0.5rem'
              }
            }}
            columns={[
              { title: '', 
                field: 'links', 
                sorting: false, 
                width: 100,
                cellStyle:{textAlign:'right', padding:"0px 5px" },
                render: rowData => (
                  <div className="d-inline-flex">
                    <Tooltip title="Cambiar estado" aria-label="Cambiar estado">
                      <IconButton onClick={(event) => {
                        // console.log(rowData)
                        setSelectedRow(rowData);
                        onSetOrderState({ isDialogVisible: true });
                      }}>
                        <UpdateIcon style={{ color: "#1B2A49" }}/>
                      </IconButton>
                    </Tooltip>
                    {/* <Tooltip title="Editar" aria-label="Editar">
                      <IconButton onClick={(evt) => this.editRowAction(rowData)}>
                        <EditIcon fontSize="small" style={{ color: "#1B2A49" }}/>
                      </IconButton>
                    </Tooltip> */}
                  </div>
                )
              },
              { title: 'Pedido', field: 'number', cellStyle:{textAlign:'center'} },
              { title: 'Código', field: 'code', cellStyle:{textAlign:'center'} },
              { title: 'Cliente', field: 'client_name', cellStyle:{ textAlign:'left', whiteSpace: 'nowrap' } },
              { title: 'Fecha y hora', field: 'datetime_es_am_pm', cellStyle:{ textAlign:'left', whiteSpace: 'nowrap'}},
              { title: 'Tipo de documento', field: 'document_type_label', cellStyle:{ textAlign:'left', whiteSpace: 'nowrap'}},
              { title: 'Forma de pago', field: 'payment_form_label', cellStyle:{ textAlign:'left', whiteSpace: 'nowrap'}},
              { title: 'Items', field: 'quantity', type: 'numeric', cellStyle:{textAlign:'center'}},
              { title: 'Subtotal', field: 'subtotal', type: 'currency', cellStyle:{textAlign:'right'}},
              { title: 'Envío', field: 'shipping_cost', type: 'currency', cellStyle:{textAlign:'right'}},
              { title: 'Descuento', field: 'discount', type: 'currency', cellStyle:{textAlign:'right'}},
              { title: 'Total', field: 'total_amount_due', type: 'currency', cellStyle:{textAlign:'right'}},
              { title: 'Estado', field: 'status_label', cellStyle:{textAlign:'center'}}
              // { title: 'Visible', field: 'is_visible_in_store', type: 'boolean', cellStyle:{textAlign:'center'}},
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                let currentPage = query.page;
                if(useGlobalPage) currentPage = page;
                let url = axios.defaults.baseURL + '/api/cms/getOrders?';
                url += 'rows=' + query.pageSize;
                url += '&page=' + (query.page + 1);
                url += '&filter=' + query.search;
                url += '&token=' + token;
                fetch(url)
                  .then(response => response.json())
                  .then(result => {
                    onSetOrderState({
                      page: result.page - 1,
                      tableFilter: query.search
                    });
                    setUseGlobalPage(false);
                    resolve({
                      data: Object.values(result.rows),
                      page: result.page - 1,
                      totalCount: result.records,
                    })
                  })
              })
            }
            actions={[
              // {
              //   icon: 'update',
              //   tooltip: 'Cambiar estado',
              //   onClick: (event, rowData) => {
              //     // console.log(rowData)
              //     setSelectedRow(rowData);
              //     onSetOrderState({ isDialogVisible: true });
              //   }
              // },
              {
                icon: PictureAsPdfOutlinedIcon,
                tooltip: 'Generar PDF',
                onClick: (event, rowData) => {
                  // console.log(rowData)
                  fireMessage({
                    title: 'Oops...',
                    text: 'Su tienda no tiene configurado un formato PDF.'
                  });
                  
                }
              }
            ]}
            detailPanel={[
              {
                tooltip: 'Ver detalles',
                render: rowData => {
                  return (
                    <DetailPanel data = {rowData}/>
                  )
                },
              }
            ]}
            components={{
              Toolbar: props => (
                <CustomMaterialTableToolbar {...props}/>
              ),
            }}
          />
      </Grid>
    </Grid>
  )
}

export default Orders;
