import React from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import * as actions from '../../../../store/actions/index';
// import { empty } from '../../../../shared/utility';
// import config from '../../../../config';

const Inventory = (props) => {
  const validateStock = useSelector(state => state.setting.settings.validateStock);
  const dispatch = useDispatch();
  const onSetSettings = (settings) => dispatch(actions.setSettings(settings));
  const switchChangeHandler = (event) => {
    onSetSettings({ validateStock: event.target.checked });
  };
  console.log('Inventory rendering!');
  return (
    <React.Fragment>
      <Typography variant="h5" className={props.materialClasses.sectionMarginBottom}>
        Inventario
      </Typography>
      <Grid item container xs={12} md={12} spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Paper className={props.materialClasses.paper}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch checked={ validateStock } onChange={ switchChangeHandler } />}
                  label="Mostrar solo productos con inventario y reservar inventario al recibir un pedido"
                />
              </Grid>
              <Grid item xs={6} md={6}>
              </Grid>
            </Grid> 
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Inventory;
