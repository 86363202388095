import React from 'react';
import MaterialTable from "material-table";
import {useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  cellDescription: {
    borderBottom: 0
  },
  boxDescription: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    // [theme.breakpoints.down('sm')]: {
    //   width: '80%',
    // },
    [theme.breakpoints.up('md')]: {
      flexWrap: 'inherit'
    },
    // [theme.breakpoints.up('xl')]: {
    //   width: '30%',
    // },
  },
}));

const ShippingTable = (props) => {
  const materialClasses = useStyles();
  // const currencySymbol = '$';
  return (
    <MaterialTable
      // title="Render Image Preview"
      options={{
        toolbar:false,
        paging: false,
        headerStyle: {
          fontWeight:'bold',
          textAlign:'center',
          padding:'0.5rem'
        }
      }}
      columns={[
        { title: props.title, field: 'name',  },
        { title: 'Costo', field: 'cost', type: 'currency' },
      ]}
      data={ Object.values(props.data) }
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            // console.log(newData);
            props.onEdit(newData.id, newData);
            resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            // console.log(newData);
            console.log(oldData);
            props.onDelete(oldData.id);
            resolve();
          })
      }}
    />
  )
}

export default ShippingTable;
