import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';
import SaveIcon from '@material-ui/icons/Save';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useForm, Controller } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
// import { format, formatDistance, formatRelative, subDays }  from 'date-fns'
import esLocale from "date-fns/locale/es";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Editor } from '@tinymce/tinymce-react';
import CompanyMoney from '../../../../components/UI/Input/CompanyMoney';
import Money from '../../../../components/UI/Input/Money';
import Number from '../../../../components/UI/Input/Number';
import * as actions from '../../../../store/actions/index';
import { empty, round, updateObject, isDemo } from '../../../../shared/utility';
import config from '../../../../config';

const MainForm = (props) => {
  const [state, setState] = useState({
    touched: false,
    code: !empty(props.data.code) ? props.data.code : '',
    name: !empty(props.data.name) ? props.data.name : '',
    value: !empty(props.data.value) ? props.data.value : 0,
    type: !empty(props.data.type) ? props.data.type : 'K',//K -> Porcentaje, J -> Valor fijo
    fromDate: typeof props.data.fromDate == 'object' ? props.data.fromDate : null,
    toDate: typeof props.data.toDate == 'object' ? props.data.toDate : null,
    minAmount: !empty(props.data.minAmount) ? props.data.minAmount : 0,
    maxAmount: !empty(props.data.maxAmount) ? props.data.maxAmount : 0,
    maximumNumber: !empty(props.data.maximumNumber) ? props.data.maximumNumber : 0,
    isFreeDelivery: !empty(props.data.isFreeDelivery) ? props.data.isFreeDelivery : false,
    isPromotionRestricted: !empty(props.data.isPromotionRestricted) ? props.data.isPromotionRestricted : false,
    isOneTimeClientRestricted: !empty(props.data.isOneTimeClientRestricted) ? props.data.isOneTimeClientRestricted : false,
    isClientRestricted: !empty(props.data.name) ? props.data.name : false,
    isActive: !empty(props.isActive) ? props.data.isActive : true,
  });
  // const [state, setState] = useState({
  //   touched: false,
  //   id: { value: !empty(props.data) ? props.data.id : '', touched: false, pattern: '' },
  //   code: { value: !empty(props.data) ? props.data.code : '', touched: false, pattern: '' },
  //   name: { value: !empty(props.data) ? props.data.name : '', touched: false, pattern: '' },
  //   value: { value: !empty(props.data) ? props.data.value : '', touched: false, pattern: '' },
  //   type: { value: !empty(props.data) ? props.data.name : 'K', touched: false, pattern: '' },//K -> Porcentaje, J -> Valor fijo
  //   fromDate: { value: !empty(props.data) ? props.data.fromDate : null, touched: false, pattern: '' },
  //   toDate: { value: !empty(props.data) ? props.data.toDate : null, touched: false, pattern: '' },
  //   minAmount: { value: !empty(props.data) ? props.data.minAmount : 0, touched: false, pattern: '' },
  //   maxAmount: { value: !empty(props.data) ? props.data.maxAmount : 0, touched: false, pattern: '' },
  //   maximumNumber: { value: !empty(props.data) ? props.data.maximumNumber : 0, touched: false, pattern: '' },
  //   isOneTimeClientRestricted: { value: !empty(props.data) ? props.data.isOneTimeClientRestricted : false, touched: false, pattern: '' },
  //   isClientRestricted: { value: !empty(props.data) ? props.data.name : false, touched: false, pattern: '' },
  //   isActive: { value: !empty(props.data) ? props.data.name : true, touched: false, pattern: '' },
  // });
  const dispatch = useDispatch();
  const onSetState = (data) => dispatch(actions.setCouponState(data));
  const { register, errors, triggerValidation, setValue, getValues, watch, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      
    }
  });
  const changeHandler = (name, value) => {
    // console.log(getValues());
    setState( updateObject( state, { [name]: value, touched: true } ) );
  };
  const inputChangeHandler = (event) => {
    setState( updateObject( state, { [event.target.name]: event.target.value, touched: true } ) );
  };
  const toggleCheckedHandler = (event) => {
    if( event.target.name === 'isFreeDelivery' && !state[event.target.name])
    {
      setState( updateObject( state, { 
        value: 0, 
        type: 'J', 
        [event.target.name]: !state[event.target.name], 
        touched: true 
      } ) );
    }
    else
    {
      setState( updateObject( state, { [event.target.name]: !state[event.target.name], touched: true } ) );
    }
    
  };
  console.log('main form rendering!');
  return (
    <Paper className={ props.materialClasses.paper }>
      <Grid item container spacing={2}>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            required
            fullWidth
            autoFocus
            name="code"
            label="Código"
            value={state.code}
            onChange={inputChangeHandler}
            // error={!!errors.code}
            // inputRef={ register({ required: true }) }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            required
            fullWidth
            name="name"
            label="Nombre"
            value={state.name}
            onChange={inputChangeHandler}
            // error={!!errors.name}
            // inputRef={ register({ required: true }) }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            required
            label="Descuento"
            value={state.value}
            disabled={ state.isFreeDelivery ? true : false }
            onChange={inputChangeHandler}
            name="value"
            fullWidth
            // inputRef={ register({ required: true, min: 0, max: 100 }) }
            // error={!!errors.discount}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="Descuento"
                  onClick={() => changeHandler('type', 'K') }
                  disabled={ state.isFreeDelivery ? true : false }
                  edge="end"
                  color={state.type === 'K'?'secondary':'primary'}
                >
                  %
                </IconButton>
                <IconButton
                  aria-label="Descuento"
                  onClick={() => changeHandler('type', 'J') }
                  disabled={ state.isFreeDelivery ? true : false }
                  edge="end"
                  color={state.type === 'J'?'secondary':'primary'}
                >
                  <CheckBoxOutlineBlankIcon />
                </IconButton>
              </InputAdornment>,
              inputComponent: Number
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            label="Máx. uso"
            value={state.maximumNumber}
            onChange={inputChangeHandler}
            name="maximumNumber"
            fullWidth
            // inputRef={ register({ required: true, min: 0 }) }
            // error={!!errors.maximumNumber}
            InputProps={{
              inputComponent: Number,
            }}
            helperText="Cero si no hay límite"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            label="Monto mínimo"
            value={state.minAmount}
            onChange={inputChangeHandler}
            name="minAmount"
            fullWidth
            // inputRef={ register({ required: true, min: 0 }) }
            // error={!!errors.minAmount}
            InputProps={{
              inputComponent: Money
            }}
            helperText="Cero si no hay mínimo"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            label="Monto máximo"
            value={state.maxAmount}
            onChange={inputChangeHandler}
            name="maxAmount"
            fullWidth
            // inputRef={ register({ required: true, min: 0 }) }
            // error={!!errors.maxAmount}
            InputProps={{
              inputComponent: Money,
            }}
            helperText="Cero si no hay máximo"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              // required
              fullWidth
              name="fromDate"
              label="Válido desde"
              format="dd/MM/yyyy"
              // maxDate={new Date()}
              // inputRef={ register({ required: true }) }
              value={state.fromDate}
              onChange={date =>  changeHandler('fromDate', date)}
              KeyboardButtonProps={{
                'aria-label': 'Válido desde',
              }}
              invalidDateMessage="Formato de fecha no válido"
              // InputLabelProps={{ shrink: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              // required
              fullWidth
              name="toDate"
              label="Válido hasta"
              format="dd/MM/yyyy"
              // maxDate={new Date()}
              // inputRef={ register({ required: true }) }
              value={state.toDate}
              onChange={date =>  changeHandler('toDate', date)}
              KeyboardButtonProps={{
                'aria-label': 'Válido hasta',
              }}
              invalidDateMessage="Formato de fecha no válido"
              // InputLabelProps={{ shrink: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControlLabel
            control={<Switch name="isOneTimeClientRestricted" checked={state.isOneTimeClientRestricted} onChange={toggleCheckedHandler}/>}
            label="Uno por cliente"
            className={props.materialClasses.formSwitch}
          />
          <FormControlLabel
            control={<Switch name="isFreeDelivery" checked={state.isFreeDelivery} onChange={toggleCheckedHandler}/>}
            label="Envío gratis"
            className={props.materialClasses.formSwitch}
          />
          <FormControlLabel
            control={<Switch name="isPromotionRestricted" checked={state.isPromotionRestricted} onChange={toggleCheckedHandler}/>}
            label="Restringir promociones"
            className={props.materialClasses.formSwitch}
          />
          <FormControlLabel
            control={<Switch name="isActive" checked={state.isActive} onChange={toggleCheckedHandler}/>}
            label="Activo"
            className={props.materialClasses.formSwitch}
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonGroup size="medium" aria-label="small outlined button group" orientation="horizontal" fullWidth={false}>
            <Button variant="contained" color="secondary"  startIcon={<UndoIcon />} onClick={() => onSetState({isFormVisible: false})}>Regresar</Button>
            {/* {
              state.touched
                ? <Button variant="outlined" color="secondary" startIcon={<DeleteIcon />} onClick={() => discardChanges()}>Descartar cambios</Button>
                : null
            } */}
            <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={() => props.onSaveHandler(state)}>Guardar</Button> 
          </ButtonGroup>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default MainForm;
