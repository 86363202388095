import React, { useRef, useState, useEffect, useCallback } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { isMobileOnly } from 'react-device-detect';
import { useForm, Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactHtmlParser from 'react-html-parser';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Spinner from '../../../components/UI/Spinner/Spinner';
import * as actions from '../../../store/actions/index';
import { updateObject, empty, ccyFormat, fireMessage } from '../../../shared/utility';
import countries from '../../../shared/countries';
// import currencies from '../../../shared/currencies';
import useHttpErrorHandler from '../../../hooks/http-error-handler';
import axios from '../../../axios-decima';
import Copyright from '../../../components/Footer/Copyright/Copyright';
import SubscriptionTypes from '../../../components/Shop/SubscriptionTypes/SubscriptionTypes';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 0,
    paddingBottom: theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0
  },
  loader: {
    marginTop: theme.spacing(8),
  },
  // paper: {
  //   marginTop: theme.spacing(1),
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  // },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  }
}));

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const steps = ['Datos personales', 'Tipo de suscripción', 'Información del pago'];

const Subscriptions = props => {
  const materialClasses = useStyles();
  const isFirstRun = useRef(true);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState('mensual');
  const [documentType, setDocumentType] = useState('C');
  const [paymentForm, setPaymentForm] = useState('B');
  const [countryAutocomplete, setCountryAutocomplete] = useState(countries[201]);  
  const [error, clearError] = useHttpErrorHandler(axios);
  const loading = useSelector(state => state.auth.loading);
  const subscriptionError = useSelector(state => state.auth.error);
  const [subscriptionData, setSubscriptionData] = useState({
    tradeName: '',
    clientName: '',
    commercialTrade: '',
    singleIdentityDocumentNumber: '',
    registrationNumber: '',
    taxId: '',
    clientAddress: '',
    clientCity: '',
    clientState: '',
    remark: '',
  });
  const dispatch = useDispatch();
  const onSubscribe = (data) => dispatch(actions.subscribe(data));
  const { register, errors, triggerValidation, setValue, getValues, watch, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  });

  const subscriptionTypes = {
    // "mensual": {
    //   "id": "mensual",
    //   "label": "Mensual",
    //   "price": 25
    // },
    "trimestral": {
      "id": "trimestral",
      "label": "Trimestral",
      "price": 150
    },
    "semestral": {
      "id": "semestral",
      "label": "Semestral",
      "price": 240
    },
    "anual": {
      "id": "semestral",
      "label": "Semestral",
      "price": 360
    }
  };

  const settingsDocumentTypes = {
    "C": {
      "id": "C",
      "isEnabled": true,
      "label": "Comprobante de crédito fiscal"
    },
    "F": {
      "id": "F",
      "isEnabled": true,
      "label": "Factura de consumidor final"
    },
    "E": {
      "id": "E",
      "isEnabled": true,
      "label": "Factura de exportación"
    },
    "T": {
      "id": "T",
      "isEnabled": false,
      "label": "Ticket"
    }
  };

  const settingsPaymentForms = {
    "B": {
      "defaultLabel": "Transferencia bancaria",
      "hasError": false,
      "id": "B",
      "instructions": "<p>Realice una transferencia bancaria por el monto total de la suscripción, a cualquiera de las siguientes cuentas:</p> <ul> <li>Banco de Am&eacute;rica Central al n&uacute;mero de cuenta #201066990</li> <li>Banco Agr&iacute;cola al n&uacute;mero de cuenta #3490352144</li> <li>Banco Cuscatl&aacute;n al n&uacute;mero de cuenta #030302000006765</li> <li>Banco Davivienda al n&uacute;mero de cuenta #8540534894</li> </ul> <p>Una vez haya realizado la transferencia, env&iacute;enos el comprobante al correo <em><strong>facturacion@decima.la</strong></em></p>",
      "isEnabled": true,
      "isPremium": false,
      "label": "Transferencia bancaria"
    },
    // "D": {
    //   "defaultLabel": "Pagadito",
    //   "environment": "sandbox",
    //   "hasError": false,
    //   "id": "D",
    //   "instructions": "<p>Al realizar el pedido, ser&aacute; redirigido a la p&aacute;gina de <em><strong>Pagadito</strong></em> para efectuar el pago, una vez realizado el pago, regreser&aacute; de forma autom&aacute;tica a nuestra tienda para confirmar su pedido.</p>",
    //   "isEnabled": false,
    //   "isPremium": true,
    //   "label": "Pagadito",
    //   "productionAccount": "",
    //   "productionClientId": "",
    //   "productionSecret": "",
    //   "sandboxAccount": "",
    //   "sandboxClientId": "",
    //   "sandboxSecret": ""
    // },
    // "G": {
    //   "defaultLabel": "Pagadito vía email",
    //   "hasError": false,
    //   "id": "G",
    //   "instructions": "<p>Un miembro de nuestro equipo le enviar&aacute; por correo electr&oacute;nico un enlace con la solicitud de pago a trav&eacute;s del servicio de Pagadito.</p>",
    //   "isEnabled": true,
    //   "isPremium": false,
    //   "label": "Pagadito vía email"
    // },
    // "P": {
    //   "defaultLabel": "Tarjeta de crédito (POS Móvil)",
    //   "hasError": false,
    //   "id": "P",
    //   "instructions": "<p>Al recibir su pedido, deber&aacute; de presentar su tarjeta al repartidor para que le procese el pago a trav&eacute;s de nuestro POS M&oacute;vil.</p>",
    //   "isEnabled": true,
    //   "isPremium": false,
    //   "label": "Tarjeta de crédito (POS Móvil)"
    // },
    // "T": {
    //   "defaultLabel": "Tarjeta de crédito (vía telefónica)",
    //   "hasError": false,
    //   "id": "T",
    //   "instructions": "<p>Un miembro de nuestro equipo se pondr&aacute; en contacto con usted v&iacute;a telef&oacute;nica al n&uacute;mero que nos ha proporcionado para tomarle los datos de su tarjeta y realizar el pago.</p>",
    //   "isEnabled": true,
    //   "isPremium": false,
    //   "label": "Tarjeta de crédito (vía telefónica)"
    // },
    "Y": {
      "defaultLabel": "Paypal vía email",
      "hasError": false,
      "id": "Y",
      "instructions": "<p>Un miembro de nuestro equipo le enviar&aacute; por correo electr&oacute;nico un enlace con la solicitud de pago a trav&eacute;s del servicio de Paypal.</p>",
      "isEnabled": true,
      "isPremium": false,
      "label": "Paypal vía email"
    }
  }

  useEffect(() => {
    if(!empty(subscriptionError)) setActiveStep(0);
  }, [subscriptionError]);

  useEffect(() => {
    if (isFirstRun.current)
    {
      isFirstRun.current = false;
      return;
    }

    setValue([
      //Step1
      { firstname : subscriptionData.firstname },
      { lastname : subscriptionData.lastname },
      { phoneNumber : subscriptionData.phoneNumber },
      { email : subscriptionData.email },
      { password : subscriptionData.password },
      { passwordConfirmation : subscriptionData.passwordConfirmation },
      { address : subscriptionData.address },
      { city : subscriptionData.city },
      { state : subscriptionData.state },
      { type : subscriptionData.type },
      { reference : subscriptionData.reference },
      //Step2
      { tradeName : subscriptionData.tradeName },
      { clientName : subscriptionData.clientName },
      { commercialTrade : subscriptionData.commercialTrade },
      { singleIdentityDocumentNumber : subscriptionData.singleIdentityDocumentNumber },
      { registrationNumber : subscriptionData.registrationNumber },
      { taxId : subscriptionData.taxId },
      { clientAddress : subscriptionData.clientAddress },
      { clientCity : subscriptionData.clientCity },
      { clientState : subscriptionData.clientState },
      { remark : subscriptionData.remark }
    ]);

    if(activeStep === 2 && empty(subscriptionData.clientName)) setValue('clientName', subscriptionData.firstname + ' ' + subscriptionData.lastname);
    if(activeStep === 2 && empty(subscriptionData.clientAddress)) setValue('clientAddress', subscriptionData.address);
    if(activeStep === 2 && empty(subscriptionData.clientCity)) setValue('clientCity', subscriptionData.city);
    if(activeStep === 2 && empty(subscriptionData.clientState)) setValue('clientState', subscriptionData.state);

    if(activeStep === 3)
    { 
      onSubscribe({
        // datetime: '2020-06-20 20:10:45', //Y-m-d H:i:s
        payment_form: paymentForm,//E - Efectivo, ...
        payment_form_label: settingsPaymentForms[paymentForm]['label'],//E - Efectivo, ...
        payment_form_instructions: settingsPaymentForms[paymentForm]['instructions'],//E - Efectivo, ...
        remark: subscriptionData.remark,
        status: 'P',//estado inicial
        subscription_type: subscriptionTypes[subscriptionType]['label'],
        total_amount_due: subscriptionTypes[subscriptionType]['price'],
        document_type: documentType, //Factura, Ticket, Comprobante de crédito fiscal
        document_type_label: settingsDocumentTypes[documentType]['label'],  
        country_id: empty(countryAutocomplete['id']) ? countryAutocomplete['id'] : null,
        user: {
          firstname: subscriptionData.firstname,
          lastname: subscriptionData.lastname,
          email: subscriptionData.email,
          password: subscriptionData.password,
          confirm_password: subscriptionData.passwordConfirmation,
          registration_number: subscriptionData.registrationNumber,
          tax_id: subscriptionData.taxId,
          street1: subscriptionData.clientAddress,//dirección
          city_name: subscriptionData.clientCity,//municipio
          state_name: subscriptionData.clientState,//departamento
          country_id: empty(countryAutocomplete['id']) ? countryAutocomplete['id'] : null 
        },
        client: {
          name: subscriptionData.clientName,
          email: subscriptionData.email,
          trade_name: subscriptionData.tradeName,
          phone_number: subscriptionData.phoneNumber,
          commercial_trade: subscriptionData.commercialTrade,
          single_identity_document_number: subscriptionData.singleIdentityDocumentNumber,
          registration_number: subscriptionData.registrationNumber,
          tax_id: subscriptionData.taxId,
          street1: subscriptionData.clientAddress,//dirección
          city_name: subscriptionData.clientCity,//municipio
          state_name: subscriptionData.clientState,//departamento
          country_id: empty(countryAutocomplete['id']) ? countryAutocomplete['id'] : null 
        },
        clientAddress: {
          firstname: subscriptionData.firstname,
          lastname: subscriptionData.lastname,
          address_type: subscriptionData.type,//R - Residencia, E - Empresa
          address: subscriptionData.address,//dirección
          city_name: subscriptionData.city,//municipio
          state_name: subscriptionData.state,//departamento
          reference_point: subscriptionData.reference
        }
      });
    }
    
  }, [activeStep, subscriptionData, setValue]);


  const subscriptionTypeClickHandler = (type) => {
    setSubscriptionType(type);
    console.log(type);
    handleNext();
  }

  // const documentTypeChangeHandler = (event) => {
  //   console.log(event);
  //   // console.log(event.target.value);
  // }

  const handleNext = (async () => {
    let formValues = getValues();
    const result = await triggerValidation();

    if(activeStep === 0 && result && isNaN((Date.parse(selectedDate))))
    {
      fireMessage({ text: 'Ingrese su fecha de nacimiento' });
      return;
    }
    
    if(activeStep === 0 && result && empty(countryAutocomplete))
    {
      fireMessage({ text: 'Seleccione su país' });
      return;
    }

    if (result)
    { 
      if(activeStep === 0)
      {
        setSubscriptionData(updateObject(subscriptionData, {
          firstname: formValues.firstname,
          lastname : formValues.lastname,
          phoneNumber : formValues.phoneNumber,
          email : formValues.email,
          password : formValues.password,
          passwordConfirmation : formValues.passwordConfirmation,
          address : formValues.address,
          city : formValues.city,
          state : formValues.state,
          type : formValues.type,
          reference : formValues.reference
        } ) );
      }

      if(activeStep === 2)
      {
        setSubscriptionData(updateObject(subscriptionData, {
          tradeName: formValues.tradeName,
          clientName: formValues.clientName,
          commercialTrade: formValues.commercialTrade,
          singleIdentityDocumentNumber : formValues.singleIdentityDocumentNumber,
          registrationNumber : formValues.registrationNumber,
          taxId : formValues.taxId,
          clientAddress : formValues.clientAddress,
          clientCity : formValues.clientCity,
          clientState : formValues.clientState,
          remark : formValues.remark
        } ) );
      }
      
      setActiveStep(activeStep + 1);
    }
  });

  const handleBack = () => {
    let formValues = getValues();
    if(activeStep === 2)
    {
      setSubscriptionData(updateObject(subscriptionData, {
        tradeName : formValues.tradeName,
        clientName : formValues.clientName,
        commercialTrade: formValues.commercialTrade,
        singleIdentityDocumentNumber : formValues.singleIdentityDocumentNumber,
        registrationNumber : formValues.registrationNumber,
        taxId : formValues.taxId,
        clientAddress : formValues.clientAddress,
        clientCity : formValues.clientCity,
        clientState : formValues.clientState,
        remark : formValues.remark
      } ) );
    }
    setActiveStep(activeStep - 1);
  };

  const autocompleteHandler = (event, newValue) => {
    if(!empty(newValue) && newValue['id'] !== 202) setDocumentType('E');
    setCountryAutocomplete(newValue);
  };

  let form = <p>Ups!</p>;

  if (activeStep === 0)
  {
    form = (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            fullWidth
            id="firstname"
            name="firstname"
            label="Nombre"
            type="text"
            error={!!errors.firstname}
            inputRef={ register({ required: true }) }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            fullWidth
            id="lastname"
            name="lastname"
            label="Apellido"
            type="text"
            error={!!errors.lastname}
            inputRef={ register({ required: true }) }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            fullWidth
            id="phoneNumber"
            name="phoneNumber"
            label="Número de teléfono"
            type="text"
            error={!!errors.phoneNumber}
            inputRef={ register({ required: true, pattern : /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/}) }
            // inputRef={ register({ required: true, pattern : /^[0-9-]*$/}) }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              required
              fullWidth
              name="dateBirth"
              label="Fecha de nacimiento"
              format="dd/MM/yyyy"
              maxDate={new Date()}
              // inputRef={ register({ required: true }) }
              value={selectedDate}
              onChange={date =>  setSelectedDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              invalidDateMessage="Formato de fecha no válido"
              InputLabelProps={{ shrink: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            required
            fullWidth
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            type="text"
            error={!!errors.email}
            inputRef={ register({ required: true, pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/ }) }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            error={!!errors.password}
            inputRef={ register({ required: true, minLength: 6}) }
            helperText={errors.password ? 'Las contraseña debe contener al menos 6 caracteres' : ''}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            required
            fullWidth
            name="passwordConfirmation"
            label="Vuelva a escribir la contraseña"
            type="password"
            error={!!errors.passwordConfirmation}
            inputRef={ register({
              validate: (value) => value === watch('password')
            }) }
            helperText={errors.passwordConfirmation ? 'Las contraseñas no coinciden' : ''}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address"
            name="address"
            label="Dirección para envío de factura o CCF"
            type="text"
            fullWidth
            error={!!errors.address}
            inputRef={ register({ required: true }) }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            name="city"
            label="Municipio / Ciudad"
            type="text"
            fullWidth
            error={!!errors.city}
            inputRef={ register({ required: true }) }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField 
            required
            name="state" 
            label="Departamento / Provincia" 
            fullWidth
            error={!!errors.state}
            inputRef={ register({ required: true }) } 
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            options={ countries }
            getOptionLabel={ (option) => option.name + ' (' + option.iso_code + ')' }
            // renderOption={(option) => (
            //   <React.Fragment>
            //     <span>{countryToFlag(option.iso_code)}</span>
            //     {option.name} ({option.iso_code})
            //   </React.Fragment>
            // )}
            name="countryAutocomplete"
            value={ countryAutocomplete }
            // onChange={ (event, newValue) => setCountryAutocomplete(newValue) }
            onChange={ autocompleteHandler }
            renderInput={ (params) => 
              <TextField {...params} 
                required
                name="country" 
                label="País" 
                fullWidth 
                error={empty(countryAutocomplete) ? true : false} 
                // error={false} 
                // inputRef={ register({ required: true }) } 
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              /> }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl 
            fullWidth 
            required
            error={Boolean(errors.type)}
          >
            <InputLabel shrink={ true }>Tipo</InputLabel>
            <Controller
              as={
                <Select>
                    <MenuItem value='R'>Residencia</MenuItem>
                    <MenuItem value='E'>Empresa</MenuItem>
                </Select>
              }
              name="type"
              rules={{ required: true }}
              control={control}
              defaultValue=""
            />
          </FormControl>
        </Grid>
        <Grid item xs={9}>
          <TextField
            id="reference"
            name="reference"
            label="Punto de referencia"
            fullWidth
            inputRef={ register() }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    )
  }
  else if (activeStep == 1)
  {
    form = <SubscriptionTypes selectedSubscription='' buttonText='Elegir' clickHandler={ subscriptionTypeClickHandler } />;
  }
  else if (activeStep == 2)
  {
    form = (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={5}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Forma de pago</FormLabel>
            <RadioGroup aria-label="position" name="position" value={paymentForm} onChange={(event) => setPaymentForm(event.target.value)}>
              { 
                Object.values(settingsPaymentForms)
                .filter(paymentForm => paymentForm.isEnabled)
                .map( (paymentForm) => (        
                  <FormControlLabel key={ 'formControlLabel' + paymentForm.id } value={ paymentForm.id } control={<Radio key={'radio' + paymentForm.id } color="primary" />} label={ paymentForm.label } />
                ) ) 
              }
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={7}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Indicaciones para realizar el pago</FormLabel>
            <Typography component="div" variant="body2" style={{marginTop: '5px'}}>
              { !empty(paymentForm) ? ReactHtmlParser( settingsPaymentForms[paymentForm]['instructions'] ) : null }
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography component="legend" className="MuiFormLabel-root">
            Datos de facturación
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="tradeName"
            name="tradeName"
            label="Nombre comercial"
            type="text"
            fullWidth
            error={!!errors.tradeName}
            inputRef={ register({ required: true }) }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="clientName"
            name="clientName"
            label="Facturar a nombre de"
            type="text"
            fullWidth
            error={!!errors.clientName}
            inputRef={ register({ required: true }) }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl 
            fullWidth 
            required
            error={Boolean(errors.documentType)}
          >
            <InputLabel shrink={true}>Tipo de documento</InputLabel>
            <Select
              disabled={ countryAutocomplete['id'] !== 202 ? true : false }
              value={ documentType }
              onChange={(event) => setDocumentType(event.target.value)}
            >
              { 
                Object.values(settingsDocumentTypes)
                .filter(documentType => documentType.isEnabled)
                .map( (documentType) => (        
                  <MenuItem key={ 'menuItem' + documentType.id } value={ documentType.id }>{ documentType.label }</MenuItem>
                ) ) 
              }
            </Select>
          </FormControl>
        </Grid>
        {
          documentType === 'C'
            ? <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="commercialTrade"
                    name="commercialTrade"
                    label="Giro"
                    type="text"
                    fullWidth
                    required
                    error={!!errors.commercialTrade}
                    inputRef={ register({ required: true }) }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="registrationNumber"
                    name="registrationNumber"
                    label="NRC"
                    type="text"
                    fullWidth
                    required
                    error={!!errors.registrationNumber}
                    inputRef={ register({ required: true }) }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="taxId"
                    name="taxId"
                    label="NIT"
                    type="text"
                    fullWidth
                    required
                    error={!!errors.taxId}
                    inputRef={ register({ required: true }) }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </React.Fragment> 
            : null
        }
        {
          documentType === 'F'
            ? <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="taxId"
                    name="taxId"
                    label="NIT"
                    type="text"
                    fullWidth
                    required
                    error={!!errors.taxId}
                    inputRef={ register({ required: true }) }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="singleIdentityDocumentNumber"
                    name="singleIdentityDocumentNumber"
                    label="DUI"
                    type="text"
                    fullWidth
                    required
                    error={!!errors.singleIdentityDocumentNumber}
                    inputRef={ register({ required: true }) }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </React.Fragment> 
            : null
        }
        <Grid item xs={12}>
          <TextField
            required
            id="clientAddress"
            name="clientAddress"
            label="Dirección"
            type="text"
            fullWidth
            error={!!errors.clientAddress}
            inputRef={ register({ required: true }) }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="clientCity"
            name="clientCity"
            label="Municipio / Ciudad"
            type="text"
            fullWidth
            error={!!errors.clientCity}
            inputRef={ register({ required: true }) }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField 
            required
            id="clientState" 
            name="clientState" 
            label="Departamento / Provincia / Estado" 
            fullWidth
            error={!!errors.clientState}
            inputRef={ register({ required: true }) } 
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="legend" className="MuiFormLabel-root">
            Resumen
          </Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <TextareaAutosize
            name="remark"
            style={{height:"100%", width:"100%"}}
            aria-label="maximum height"
            placeholder="Comentarios"
            ref={ register() } 
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TableContainer>
            <Table aria-label="spanning table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} align="center" style={{ fontWeight: 'bold', fontSize: '1.3rem' }}>Resumen</TableCell>
                  {/* <TableCell align="right" style={{ fontWeight: 'bold', fontSize: '1.3rem' }}>Ventas</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="right" style={{ fontWeight: 'bold', fontSize: '1.3rem' }}>Tipo:</TableCell>
                  <TableCell align="right" style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>{ subscriptionTypes[subscriptionType]['label'] }</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" style={{ fontWeight: 'bold', fontSize: '1.3rem' }}>Total $:</TableCell>
                  <TableCell align="right" style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>{ccyFormat(subscriptionTypes[subscriptionType]['price'])}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
  console.log('Subscription rendering!!')
  return (
    <Container maxWidth="md" className={materialClasses.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Paper className={materialClasses.paper}>
          <Typography variant="h4" align="center">
            Suscribirse a decima.la
          </Typography>
          <Stepper activeStep={activeStep} className={materialClasses.stepper} orientation={ isMobileOnly ? "vertical" : "horizontal" }>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                {
                  loading
                    ? <Spinner />
                    : <React.Fragment>
                        <Typography variant="h5" gutterBottom>
                          ¡Solicitud de suscripción enviada! 
                        </Typography>
                        <Typography variant="subtitle1">
                          Se ha creado su cuenta en <strong>Decima</strong> con los datos proporcionados, sin embargo esta se encuentra <strong>inactiva</strong> hasta que confirmemos su pago.
                          Le hemos enviado un correo de confirmación (revise la bandeja de correo no deseado en caso no le aparezca en su bandeja de entrada), cualquier consulta que tenga puede comunicarse con nosotros a través del correo <strong>facturacion@decima.la</strong>
                        </Typography>
                      </React.Fragment>
                }
              </React.Fragment>
            ) : (
              <React.Fragment>
                { form }
                <div className={materialClasses.buttons}>
                  {activeStep !== 0 && (
                    <Button variant="outlined" onClick={handleBack} className={materialClasses.button}>
                      Anterior
                    </Button>
                  )}
                  {activeStep !== 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={materialClasses.button}
                    >
                      {activeStep === steps.length - 1 ? 'Suscribirse' : 'Siguiente'}
                    </Button>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        </Grid>
        <Grid item xs={12}>
        <div className={materialClasses.footer}>
          <Typography variant="subtitle1" color="textSecondary"  gutterBottom>
            Al suscribirse a decima.la, usted acepta nuestros <strong><Link href="https://docs.google.com/document/d/13nIyPjQjYxDyLjDyXSo4XqnnS4bM8sQRXvUA7DOm3sw/" target="_blank">términos y condiciones</Link></strong>.
          </Typography>
        </div>
        </Grid>
        <Grid item xs={12}>
          <Copyright />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Subscriptions;
